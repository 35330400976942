import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { GetListingThunk, ResetGetListingData } from "../../redux/Listing/GetListingSlice";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import ReactPaginate from "react-paginate";
import { Spinner } from "react-bootstrap";
import ListingTable from "./ListingTable";

const Listings = () => {
	const { id, number } = useParams();

	var x = window.matchMedia("(max-width: 800px)");
	const [elements, setElements] = useState("");
	var gridsView = false;
	const [imageCSS, setImageCSS] = useState("");
	const [listingCSS, setListingCSS] = useState("");
	const [summaryCSS, setSummaryCSS] = useState({
		width: "",
		marginLeft: "",
		padding: ""
	});
	const [glanceCSS, setGlanceCSS] = useState({
		width: "",
		position: "",
		bottom: "",
		right: "",
		marginTop: ""
	});
	const [titleCSS, setTitleCSS] = useState("");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const listingsPerPage = 10;
	const [onSearchText, setSearchText] = useState("");
	const [allListing, setListing] = useState([]);
	const [pageNumber, setPageNumber] = useState(0);

	const handleTableChange = (e) => {
		if (e.matches) {
			setElements("100%");
			setListingCSS("block");
			setImageCSS("100%");
			setSummaryCSS({
				width: "100%",
				marginLeft: "0px",
				padding: "20px 30px"
			});
			setGlanceCSS({
				width: "100%",
				position: "relative",
				bottom: "revert",
				right: "0px",
				marginTop: "0px"
			});
			setTitleCSS("0px");
		} else {
			if (gridsView) gridsView();
			else listView();
		}
	};
	x.addListener(handleTableChange);

	// ********************************** Search and Pagination start ***********************************//
	const pagesVisited = pageNumber * listingsPerPage;

	const pageCount = Math.ceil(
		allListing.filter((listing) => {
			if (onSearchText === "") {
				return listing;
			} else if (listing.title.toLowerCase().includes(onSearchText.toLowerCase())) {
				return listing;
			}
			return false;
		}).length / listingsPerPage
	);

	const displayListing = allListing
		.filter((listing) => {
			const titleString = `${listing.year.toLowerCase()} ${listing.make.toLowerCase()} ${listing.model.toLowerCase()} ${listing.machine_type.toLowerCase()} `;
			if (onSearchText === "") {
				return listing;
			} else if (titleString.toLowerCase().includes(onSearchText.toLowerCase())) {
				return listing;
			}
			return false;
		})
		.slice(pagesVisited, pagesVisited + listingsPerPage);

	// ********************************** Pagination End ***********************************//

	const {
		loading: GetListingLoading,
		success: GetListingSuccess,
		error: GetListingError,
		GetListingData
	} = useSelector((state) => state.listing.GetListing);

	useEffect(() => {
		dispatch(GetListingThunk({ category: id, listing_type: "selling" }));
	}, [dispatch, id]);

	useEffect(() => {
		setPageNumber(number);
	}, [number]);

	useEffect(() => {
		if (x.matches) {
			// If media query matches
			setElements("100%");
			setListingCSS("block");
			setImageCSS("100%");
			setSummaryCSS({
				width: "100%",
				marginLeft: "0px",
				padding: "20px 30px"
			});
			setGlanceCSS({
				width: "100%",
				position: "relative",
				bottom: "revert",
				right: "0px",
				marginTop: "0px"
			});
			setTitleCSS("0px");
		} else {
		}
	}, [x.matches]);

	const onFilterChange = (e) => {
		setSearchText(e.target.value);
	};

	if (GetListingSuccess) {
		setListing(GetListingData?.data);
		dispatch(ResetGetListingData());
	}

	function listView() {
		gridsView = false;
		setElements("100%");
		setListingCSS("flex");
		setImageCSS("39%");
		setSummaryCSS({
			width: "58%",
			marginLeft: "40px",
			padding: "0px"
		});
		setGlanceCSS({
			width: "39%",
			position: "absolute",
			bottom: "62px",
			right: "224px",
			marginTop: "20px"
		});
		setTitleCSS("45px");
	}

	function gridView() {
		gridsView = true;
		setElements("31.333%");
		setListingCSS("block");
		setImageCSS("100%");
		setSummaryCSS({
			width: "100%",
			marginLeft: "0px",
			padding: "20px 30px"
		});
		setGlanceCSS({
			width: "100%",
			position: "relative",
			bottom: "revert",
			right: "0px",
			marginTop: "0px"
		});
		setTitleCSS("0px");
	}

	const handleListingDetail = (product_id) => {
		navigate(`/listing_detail/${product_id}`);
	};
	const paginate = ({ selected }) => {
		navigate(`/Listings/${id}/${selected}`);
		// setPageNumber(selected);
	};

	return (
		<React.Fragment>
			<Navbar activeBuying={true} />

			<section className="position-relative">
				<div className="bg-overlay overlay"></div>
				<div className="listing-detail-sec1">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 no-padding">
								<h1 className="listing-detail-heading color-yellow900">Our Listings </h1>
							</div>
							<div className="col-lg-12 no-padding">
								<div className="bottom-line bg-yellow900"></div>
								<p className="listing-detail-paragraph color-white">
									<span className="color-yellow900">Yellowish.io</span> is the quick and easy online
									marketplace for finding, buying, and selling used farm equipment, construction
									equipment, trucks, and trailers.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			{GetListingLoading ? (
				<div className="d-flex justify-content-center align-items-center" style={{ height: "90vh" }}>
					<Spinner />
				</div>
			) : (
				<section className="buying-section">
					<div className="container">
						<div className="row">
							<div className="col-lg-8 offset-lg-2">
								<div className="search">
									<input
										className="search-input"
										value={onSearchText}
										onChange={onFilterChange}
										placeholder="Quick Search"
									/>
									<button className="search-button">Find My Machine</button>
								</div>
							</div>
						</div>
					</div>

					{displayListing?.length === 0 || GetListingError ? (
						<div className="container">
							<p className="m-5">Sorry, no Listings were found</p>
						</div>
					) : (
						<>
							<div className="container mt-3">
								<div className="col-lg-4 flex-start">
									<div id="btnContainer">
										<button className="grid-button" onClick={listView}>
											<img src="/images/list-2.png" style={{ width: "16px" }} alt="list" />
										</button>
										<button className="grid-button" onClick={gridView}>
											<img src="/images/list.png" style={{ width: "16px" }} alt="list" />
										</button>
									</div>
								</div>
								<ListingTable
									handleListingDetail={handleListingDetail}
									displayListing={displayListing}
									elements={elements}
									listingCSS={listingCSS}
									imageCSS={imageCSS}
									summaryCSS={summaryCSS}
									glanceCSS={glanceCSS}
									titleCSS={titleCSS}
								/>
							</div>
						</>
					)}
					<div className="container">
						<div className="row">
							<div className="col-lg-12 pagination-flex-end">
								<div className="pagination">
									<ReactPaginate
										breakLabel="..."
										breakClassName="pagination-btns"
										onPageChange={(number) => paginate(number)}
										// onPageChange={handlePageClick}
										pageRangeDisplayed={3}
										pageCount={pageCount}
										previousLabel="< "
										renderOnZeroPageCount={null}
										nextLabel=">"
										previousClassName="pagination-next"
										nextClassName="pagination-next"
										// activeClassName="pagination-active"
										pageClassName="pagination mt-0"
										pageLinkClassName="pagination-btns"
										containerClassName="pagination justify-content-center"
									/>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
			<Footer />
		</React.Fragment>
	);
};

export default Listings;
