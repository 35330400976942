import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ResetSubscription, SubscriptionThunk } from "../../redux/SubscriptionSlice";

const SubscriptionModal = ({ openModal, setOpenModal }) => {
	const dispatch = useDispatch();

	const { loading, success, error, subscriptionData } = useSelector((state) => state.subscription);

	const [email, setEmail] = useState();

	const handleSubscription = (e) => {
		e.preventDefault();
		dispatch(SubscriptionThunk({ email: email }));
	};

	if (success) {
		toast.success("you have been subscribed successfully.");
		dispatch(ResetSubscription());
		setOpenModal(!openModal);
	}
	return (
		<div className="container">
			<Modal size="md" centered show={openModal} onHide={() => setOpenModal(!openModal)}>
				<ModalHeader className="border-0" closeButton title={null}></ModalHeader>
				{/* <ModalBody> */}
				<div className="d-flex justify-content-center">
					<img className="logo w-75 mx-75" src="/images/yellowish-logo-1024x473.png" alt="" />
				</div>

				{success ? (
					<div className="p-5 row mb-5">
						<span>
							Your information has been submitted. Thanks! A confirmation email is on its way to {email}{" "}
							and should arrive shortly.
						</span>
						<span>
							Note: To ensure proper delivery of our emails, take a moment now and add us -
							admin@yellowish.io - to your address book, trusted sender list, or company allowlist.
						</span>
					</div>
				) : (
					<div className="p-5 row mb-5">
						<span>
							To sign up to receive our emails, fill in the following fields and hit submit. Thanks, and
							welcome!{" "}
						</span>
						<div className="mt-3">
							<label className="col-12 mb-0 p-0 add-listing-label" htmlFor="email">
								Email*
							</label>
							<input
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								className="form-control col-12 mb-3 rounded-1"
								name="email"
								placeholder="Enter email"
							/>
							<button
								className="cont1-button bg-yellow900 color-black900 border-yellow900 d-flex "
								style={{ justifyContent: "start", marginLeft: "0px" }}
								type="submit"
								onClick={(e) => handleSubscription(e)}
								disabled={loading}
							>
								SUBMIT
								<span className={loading ? "spinner-border spinner-border-sm ml-3" : ""} />
							</button>
						</div>
					</div>
				)}
				{/* </ModalBody> */}
			</Modal>
		</div>
	);
};

export default SubscriptionModal;
