import Home from "./components/Home";
import ContactUs from "./components/ContactUs";
import { Route, Routes } from "react-router-dom";
import { UserContextProvider } from "./Context/userContext";
import Account from "./components/Account";
import Quote from "./components/Quote";
// import Rental from "./components/Rental";
import Selling from "./components/Selling";
import Buying from "./components/Buying";
import FinanceQuote from "./components/Quote/financeQuote";
import InsuranceQuote from "./components/Quote/insuranceQuote";
import TransportQuote from "./components/Quote/transportQuote";
import Advertisement from "./components/Advertisement";
import SubmitListing from "./components/SubmitListing";
import Listings from "./components/Buying/Listings";
import TermsPolicies from "./components/Policies&Terms";
import Packages from "./components/Packages";
import AcceptableUsePolicy from "./components/Policies&Terms/AcceptableUsePolicy";
import RefundPollicy from "./components/Policies&Terms/RefundPollicy";
import Cookiespolicy from "./components/Policies&Terms/Cookiespolicy";
import PrivacyPolicy from "./components/Policies&Terms/PrivacyPolicy";
import SecurityPolicy from "./components/Policies&Terms/SecurityPolicy";
import TermsOfService from "./components/Policies&Terms/TermsOfService";
import ListingDetail from "./components/Buying/ListingDetail";
import RentalProducts from "./components/Rental/RentalProducts";
import RentalProductDetail from "./components/Rental/RentalProductDetail";
import AddUserRentalProduct from "./components/SubmitListing/AddUserRentalProduct";
import EditUser from "./components/User/EditUser";

function App() {
	return (
		<UserContextProvider>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/my-account" element={<Account />} />
				<Route path="/Quote" element={<Quote />} />
				<Route path="/FinanceQuote" element={<FinanceQuote />} />
				<Route path="/InsuranceQuote" element={<InsuranceQuote />} />
				<Route path="/TransportQuote" element={<TransportQuote />} />
				{/* <Route path="/Rental" element={<Rental />} /> */}
				<Route path="/Selling" element={<Selling />} />
				<Route path="/Buying" element={<Buying />} />
				<Route path="/Listings/:id/:number" element={<Listings />} />
				<Route path="/Rental/:id/:number" element={<RentalProducts />} />
				<Route path="/listing_detail/:id" element={<ListingDetail />} />
				<Route path="/renting_detail/:id" element={<RentalProductDetail />} />
				<Route path="/ContactUs" element={<ContactUs />} />
				<Route path="/Advertise" element={<Advertisement />} />
				<Route path="/Packages" element={<Packages />} />
				<Route path="/acceptable-use-policy" element={<AcceptableUsePolicy />} />
				<Route path="/refund-policy" element={<RefundPollicy />} />
				<Route path="/cookies-policy" element={<Cookiespolicy />} />
				<Route path="/privacy-policy" element={<PrivacyPolicy />} />
				<Route path="/security-policy" element={<SecurityPolicy />} />
				<Route path="/terms-of-service" element={<TermsOfService />} />
				<Route path="/submit-listing" element={<SubmitListing />} />
				<Route path="/edit_profile" element={<EditUser />} />
				<Route path="/add_rental_product" element={<AddUserRentalProduct />} />
				<Route path="/policies-and-terms-conditions" element={<TermsPolicies />} />
			</Routes>
		</UserContextProvider>
	);
}

export default App;

