import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/userContext";
import { GetPackagesThunk } from "../../redux/GetPackagesSlice";
import { PaymentThunk, ResetPayment } from "../../redux/PaymentSlice";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";

const Packages = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [packageId, setPackageId] = useState();

	const { loading, success, error, packagesData } = useSelector((state) => state.packages);
	const {
		loading: PaymentLoading,
		success: PaymentSuccess,
		error: PaymentError,
		errorMessage: PaymentErrorMessage,
		PaymentData
	} = useSelector((state) => state.payment);
	const { isAuth } = useContext(UserContext);

	useEffect(() => {
		dispatch(GetPackagesThunk());
	}, [dispatch]);

	const handleOrder = () => {
		dispatch(PaymentThunk({ package_id: packageId }));
	};

	if (PaymentSuccess) {
		window.open(PaymentData?.data?.redirectUrl);
		// navigate(PaymentData?.data?.redirectUrl);
		dispatch(ResetPayment());
	}

	return (
		<>
			<Navbar activeAccount={true} />

			<div className="container">
				{loading ? (
					<Spinner
						className="d-flex justify-content-center align-items-center w-100"
						style={{ height: "70vh" }}
					/>
				) : isAuth ? (
					<div
						className="row"
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "column"
						}}
					>
						<div style={{ marginTop: "5rem", marginBottom: "2rem" }}>
							<h3 className="text-center mb-4">Select a Listings Package</h3>
							{packagesData?.data?.map((packageDetail) => {
								return (
									<div
										className="row d-flex align-items-center justify-content-center w-100"
										style={{ flex: "center" }}
									>
										<div
											className="col-lg-4 col-md-6"
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												flexDirection: "row"
											}}
										>
											<input
												className="w-10"
												type="radio"
												id={packageDetail?.name}
												name="age"
												onClick={() => setPackageId(packageDetail.id)}
												value={packageDetail?.price}
											/>
											<label className="w-90" for={packageDetail?.name}>
												{packageDetail?.name} -{" "}
												{packageDetail?.listings === 999999
													? "Unlimited"
													: Number(packageDetail?.listings)}{" "}
												Listings for {packageDetail?.validity}{" "}
												{packageDetail?.validity === 1 ? "day" : "days"} - R{" "}
												{packageDetail?.price}
											</label>
										</div>
										<br />
									</div>
								);
							})}
						</div>
						<button
							className=" cont1-button bg-yellow900 color-black900 border-yellow900"
							onClick={handleOrder}
							style={{ marginBottom: "2rem", marginLeft: "0px", opacity: packageId ? "03" : "01" }}
							disabled={packageId || loading ? false : true}
						>
							Place Order
							<span
								className={PaymentLoading ? "spinner-border spinner-border-sm" : ""}
								style={{ marginLeft: "5px" }}
							></span>
						</button>
					</div>
				) : (
					navigate("/my-account")
				)}
			</div>
			<Footer />
		</>
	);
};

export default Packages;
