import React from "react";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import PolicyHeader from "./PolicyHeader";

const Cookiespolicy = () => {
	return (
		<div>
			<Navbar />
			<PolicyHeader heading="Cookie Policy" />
			<section className="container ">
				<div className="m-3 ">
					<div class="elementor-widget-text-editor">
						<p>
							Our websites use cookies to enable certain functionality, and for user analytics. By using
							our site, you agree that we can place these cookies on your device.
						</p>
						<p>
							Most websites set cookies and browsers are typically set up to accept cookies, at least for
							the duration of a single browsing session. If you wish to change your cookie preferences,
							you can do so using your browser settings. Your browser’s ‘help’ function will show you how
							to do this.
						</p>
						<p>
							We have always used cookies on this website, and as part of a change in legislation, all
							websites are now required to provide additional information on what cookies are, how they
							are used, and what benefits they bring. These changes will not affect the way in which you
							currently use our website.
						</p>
						<p>
							When we include links to other websites, please bear in mind they will have their own
							privacy and cookie policies that will govern the use of any information you submit. We
							recommend you read their policies as we are not responsible or liable for their privacy
							practices.
						</p>
						<p>
							This cookie notice is part of our <a href="/privacy-policy/">Privacy Policy</a>. For more
							information about us and how we protect visitor information, please see our{" "}
							<a href="/privacy-policy/">privacy policy</a>.
						</p>
						<p>
							<strong>What are “cookies”?</strong>
						</p>
						<p>
							Simply, cookies help websites distinguish individual browsers from each other. Cookies are
							small pieces of data that are stored for a website by your browser. Every time it loads a
							page your browser will send that page back the cookies it had set previously. This allows a
							website to connect your interactions together.
						</p>
						<p>
							Cookies can’t be shared between websites, and each site can only read and update the cookies
							it set itself. However, when you visit a single website your browser may connect to others,
							and each of these may set cookies of their own. You can find out more about the cookies set
							when you visit our websites.
						</p>
						<p>
							A cookie cannot give anyone access to your computer and doesn’t mean we’re collecting new
							information from you. If you want more information about cookies, you can get further
							details at <a href="http://www.allaboutcookies.org/">allaboutcookies.org</a>.
						</p>
						<p>
							Some third parties we work with and link to via this website will have their own cookie
							policy and may ask you to set your cookie preferences with them.
						</p>
						<p>
							<strong>What cookies do we set?</strong>
						</p>
						<p>We generally use the following cookies:</p>
						<ol>
							<li>
								“Strictly Necessary” cookies are necessary for the Website to function and cannot be
								switched off in our systems. They are usually only set in response to actions made by
								you that amount to a request for services, such as setting your privacy preferences,
								logging in, or filling in forms.
							</li>
							<li>
								“Functional cookies” enable the website to provide enhanced functionality and
								personalization based on your interaction with the Website. They may be set by us or by
								third-party providers, such as Adobe, whose services we have added to our pages.
							</li>
							<li>
								“Analytics and performance” cookies:&nbsp;These help us improve the way our website
								works to give improved performance. They tell us how each page is used, which ones are
								most viewed, and whether any errors are reported.
							</li>
							<li>
								“Customisation cookies” These cookies remember basic information that you’ve entered the
								site, so next time you visit our website it’s all there for you. We do not store
								personal information in cookies.
							</li>
							<li>
								“Advertising and Targeting cookies”&nbsp;These cookies help make sure adverts you see on
								your browser are relevant and useful to you because the cookie has detected what you’re
								looking for and may be sent through our website. They may be used by those companies to
								build a profile of your interests and show you relevant advertisements on other
								websites.
							</li>
							<li>
								“Social Media” cookies are set by social media services (e.g., Twitter, LinkedIn,
								Facebook) that we have added to the Website to enable you to share our content with your
								friends and networks. These cookies can track your browser across other websites and
								build up a profile of your interests. This may impact the content and messages you see
								on other websites you visit.
							</li>
						</ol>
						<p>
							<strong>How long will cookies remain on my device?</strong>
						</p>
						<p>
							The length of time cookies will stay on your device will depend on whether it is a
							“persistent” or “session” cookie. A persistent cookie will be stored by a web browser and
							will remain valid until its set expiration date unless deleted by you before the expiration
							date. A session cookie, on the other hand, will expire at the end of your web session, when
							the web browser is closed.
						</p>
						<p>
							<strong>How can I control cookies?</strong>
						</p>
						<p>
							You can control and manage cookies in various ways. Please keep in mind that removing or
							disabling cookies may impact your user experience and parts of this Website may no longer be
							fully functional.
						</p>
						<p>
							Where cookies are not strictly necessary for the provision of our website, we will ask you
							to consent to our use of cookies when you first visit our website. Consent can be provided
							or revoked by clicking on “Manage cookie preferences” at the top of this page.
						</p>
						<p>
							You may also be able to change your browser settings to manage and control cookies. You can
							find detailed information on how to do so at&nbsp;
							<a href="http://www.aboutcookies.org/">http://www.aboutcookies.org/.</a>
						</p>
						<p>
							Any consent to accept or to decline cookies is limited to the site for which this Cookie
							Notice is posted and not to other sites or any other pages, which may be hyperlinked to our
							website. For more information on cookies used by those websites, please refer to the
							specific privacy or cookie notice on those websites.
						</p>
						<p>
							<strong>Email Communications</strong>
						</p>
						<p>
							We may also use tracking technologies to determine whether you have read, clicked on, or
							forwarded certain email communications we send to you so that we can make our communications
							more helpful and interesting. If you do not wish us to confirm whether you have opened,
							clicked on or forwarded our communications, you will need to unsubscribe, as it is not
							possible for us to send these emails without tracking enabled. Registered subscribers can
							update their communication preferences at any time by contacting us, or you can unsubscribe
							following the instructions in the individual email communications you receive from us.
						</p>
						<p>
							<strong>Changes to the Cookie Notice</strong>
						</p>
						<p>
							We may modify or amend this Cookie Notice from time to time at our discretion. When we make
							changes to this notice, we will amend the revision date on this page, and such modified or
							amended Cookie Notice shall be effective to you and your information as of that revision
							date.
						</p>
						<p>
							<em>UPDATED MAY 2022</em>
						</p>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default Cookiespolicy;
