import React, { useContext, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../Context/userContext";
import { GetProfileThunk } from "../../../redux/GetProfileSlice";
import MyListing from "./MyListing";
import MyPackage from "./MyPackage";

const Profile = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { logout, isAuth } = useContext(UserContext);
	const { loading: profileLoading } = useSelector((state) => state.getUserProfile);
	useEffect(() => {
		dispatch(GetProfileThunk());
	}, [dispatch]);

	const handleLogout = (e) => {
		e.preventDefault();
		logout();
	};

	return (
		<div className="container mt-5">
			<div className="row">
				<div>
					<div className="tabs-main d-flex justify-content-start">
						<div className="border-line">
						<button
								className="form-bt2 color-black900 ml-3"
								onClick={() => navigate("/Packages")}
								id="defaultOpen"
							>
								<p style={{ lineHeight: "1.5rem" }}>New Package</p>
							</button>
							<button
								className="form-bt2 color-black900 ml-3"
								onClick={() => navigate("/add_rental_product")}
								id="defaultOpen"
							>
								<p style={{ lineHeight: "1.5rem" }}>Add Rental</p>
							</button>
							<button
								className="form-bt2 color-black900 ml-3"
								onClick={() => navigate("/submit-listing")}
							>
								<p className="" style={{ lineHeight: "1.5rem" }}>
									New Listing
								</p>
							</button>
							<button
								className="form-bt2 color-black900 ml-3"
								onClick={() => navigate("/edit_profile")}
							>
								<p className="" style={{ lineHeight: "1.5rem" }}>
									Edit Profile
								</p>
							</button>
							<button className=" color-black900 ml-3" style={{ border: "0px", background: "none" }}>
								<p className="form-action" style={{ color: "#fbce0a" }} onClick={handleLogout}>
									Logout
								</p>
							</button>
						</div>
					</div>
					{profileLoading ? (
						<div className="d-flex justify-content-center align-items-center" style={{ height: "90vh" }}>
							<Spinner />
						</div>
					) : (
						<>
							<MyListing />
							<MyPackage />
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default Profile;
