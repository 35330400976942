import React from "react";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import PolicyHeader from "./PolicyHeader";

const AcceptableUsePolicy = () => {
	return (
		<div>
			<Navbar />
			<PolicyHeader heading="Acceptable Use Policy" />
			<section className="container ">
				<div className="m-3 ">
					<div className="elementor-widget-text-editor">
						<ol>
							<li>
								<strong> INTRODUCTION</strong>
							</li>
						</ol>
						<p>
							While we provide our services to you for certain legitimate purposes, there are certain
							purposes for which you should not use our services under any circumstances. This policy
							describes what you cannot do with our services. We trust you as our customer and person of
							integrity to abide by this policy, but if you breach the policy or help others to do so – we
							may suspend or terminate your use of the services.
						</p>
						<ol start="2">
							<li>
								<strong> DEFINITIONS</strong>
							</li>
						</ol>
						<p>In this policy, the following words have the following meanings:</p>
						<ul>
							<li>
								‘content’ means any submissions made to the website, whether public or private,
								including messages or comments on the content of other users.
							</li>
							<li>‘policy’ means this acceptable use policy.</li>
							<li>‘services’ means the web services offered by us on or through the website.</li>
							<li>‘website’ means the website located at&nbsp;https://staging.yellowish.io&nbsp;and</li>
							<li>
								‘system’ means any network, computer or communications system, software application, or
								network or computing device.
							</li>
						</ul>
						<ol start="3">
							<li>
								<strong> RESPONSIBILITY</strong>
							</li>
						</ol>
						<p>
							You are responsible for your conduct, profile, and content on the website. You understand
							that your actions could have consequences and may result in criminal or civil liability. We
							do not endorse, take any responsibility, or accept liability for your actions.
						</p>
						<ol start="4">
							<li>
								<strong> YOUR CONTENT</strong>
							</li>
						</ol>
						<p>
							You retain the intellectual property rights to your copyrighted content. By submitting
							Content to the website, you grant us a license to use, distribute, reproduce, or adapt the
							content for any purpose. This license is worldwide, irrevocable, and free from royalty.
						</p>
						<ol start="5">
							<li>
								<strong> PROHIBITED CONTENT</strong>
							</li>
						</ol>
						<p>
							You may not use, or help others to use, the website for any illegal or harmful purposes,
							including:
						</p>
						<ul>
							<li>
								illegal content – including sharing or advertising child pornography or content that
								infringes the intellectual property rights of others (such as their copyright or
								trademarks), inciting or encouraging harm against others, or conducting or advertising
								illegal transactions (such as selling drugs or promoting unlicensed gambling);
							</li>
							<li>
								fraudulent content – including offering, sharing, or promoting false information
								relating to goods, services, or schemes, or otherwise misleading others in a way that is
								harmful or illegal.
							</li>
							<li>
								abusive content – including content that is defamatory or vulgar, infringes another’s
								right to privacy (such as their personal information), or is otherwise unreasonably or
								objectionable; or
							</li>
							<li>
								destructive content – including content or software designed for the purpose of
								malicious damage to computers, systems, or data, or to control or otherwise access any
								of these things.
							</li>
						</ul>
						<ol start="6">
							<li>
								<strong> PROHIBITED CONDUCT</strong>
							</li>
						</ol>
						<p>
							You may not use, or help others to use, the website or its services to interfere with the
							connections or security of networks, systems, or other users including:
						</p>
						<ul>
							<li>
								unauthorized access – including accessing or attempting to access a network, system, or
								user’s profile without permission.
							</li>
							<li>
								surveillance – including the surveillance of any data on a network, system, or user’s
								profile without permission.
							</li>
							<li>
								intentional harm – including intentionally damaging the structure or efficient working
								of a network, system, or user’s profile through physical or electronic means (such as
								Denial of Service attacks).
							</li>
							<li>
								spam – including sending or promoting unsolicited mass electronic communications without
								permission.
							</li>
							<li>
								data manipulation – including the malicious and fraudulent altering of any data on or
								sent by a network, system, or user’s profile without permission; or
							</li>
							<li>
								system manipulation – including the malicious and fraudulent alteration of a network,
								system, or user’s profile without permission.
							</li>
						</ul>
						<p>
							You may not develop, support, or use software, devices, scripts, robots, or any other means
							or processes (including crawlers, browser plugins, add-ons, or any other technology) to
							scrape the content of this website or otherwise copy adverts and other data from this site
							without prior written consent from Yellowish.io.
						</p>
						<ol start="7">
							<li>
								<strong> INVESTIGATION AND ENFORCEMENT</strong>
							</li>
						</ol>
						<p>
							We will investigate any breach of this policy. During and after an investigation, we can
							alter or remove any content that breaches this policy or share this content or related
							information with any relevant third party, including other users or law enforcement
							agencies. This section applies to any agreements that you have with us.
						</p>
						<ol start="8">
							<li>
								<strong> DUTY TO REPORT</strong>
							</li>
						</ol>
						<p>
							You agree to notify us of any breach of this policy, via email or any other reporting
							process that we have set up.
						</p>
						<ol start="9">
							<li>
								<strong> CHANGES</strong>
							</li>
						</ol>
						<p>
							We may change the terms of this policy at any time. We will notify you of any changes by
							placing a notice in a prominent place on the website or by email. If you do not agree with
							the change, you must stop using the services. If you continue to use the services following
							notification of a change to the terms, the changed terms will apply to you, and you will be
							deemed to have accepted such terms.
						</p>
						<p>
							<em>UPDATED MAY 2022</em>
						</p>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default AcceptableUsePolicy;
