import { Formik, Form, Field } from "formik";
import React, { useContext, useState } from "react";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { ContactUsValidationSchema } from "../../utils/FormValidationSchema";
import { ContactUsThunk, ResetContactUs } from "../../redux/ContactUsSlice";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { UserContext } from "../../Context/userContext";
import { advertiseImage, facebookIcon, instagramIcon, linkedinIcon, newsletter } from "../../assets/images";
import SubscriptionModal from "../common/SubscriptionModal";

const ContactUs = () => {
	const [openModal, setOpenModal] = useState(false);

	const { loading, success, error, contactUsData, errorMessage } = useSelector((state) => state.contactUs);

	const { userInfo } = useContext(UserContext);
	const ContactUsInitialValues = {
		fullName: userInfo && userInfo.username ? userInfo.username : "",
		email: userInfo && userInfo.email ? userInfo.email : "",
		phoneNumber: "",
		message: ""
	};
	const dispatch = useDispatch();
	const handleSubmit = ({ fullName, email, phoneNumber, message }) => {
		dispatch(ContactUsThunk({ fullName, email, phoneNumber, message }));
	};
	if (success) {
		toast.success(contactUsData);
		dispatch(ResetContactUs());
	}
	if (error) {
		toast.success(errorMessage);
		dispatch(ResetContactUs());
	}

	return (
		<div>
			<Navbar activeContact={true} />
			<main>
				<section className="cont1 ">
					<div className="container">
						<div className="row cont1-nopadding">
							<div className="col-lg-6 bg-black900 cont1-padding">
								<h1 className="cont1-heading1 color-white">Contact us</h1>
								<div className="cont1-textalign">
									<span>
										<a className="cont1-anker color-white" href="tell:+27218139555">
											+27218139555
										</a>
									</span>
									<span>
										<a className="cont1-anker color-white" href="mailto:admin@yellowish.io">
											admin@yellowish.io
										</a>
									</span>
									<span>
										<a className="cont1-anker1 color-yellow900" href="http://www.yellowish.io/">
											www.yellowish.io
										</a>
									</span>
								</div>
								<div className="cont1-padd">
									<a href="https://www.facebook.com/Yellowish.io">
										<img className="cont1-image" src={facebookIcon} alt="facebook-icon" />
									</a>
									<a href="https://www.linkedin.com/company/yellowish-io/">
										<img className="cont1-image" src={linkedinIcon} alt="linkedin-icon" />
									</a>
									<a href="https://www.instagram.com/yellowish.io/">
										<img className="cont1-image" src={instagramIcon} alt="instagram-icon" />
									</a>
								</div>
								<img className="cont1-logo" src="/images/yellowish-logo-whitetext.png" alt="" />
							</div>
							<Formik
								enableReinitialize={true}
								initialValues={ContactUsInitialValues}
								validationSchema={ContactUsValidationSchema}
								onSubmit={handleSubmit}
							>
								{({ errors, touched }) => (
									<div className="col-lg-6 cont1-bgcontact no-padding">
										<div className="bg-overlay overlay-adjustment"></div>
										<div className="row">
											<div className="col-12 cont1-right">
												<div className="bg-black800 cont1-attach">
													<div className="cont1-flexst">
														<h1
															className="cont1-heading2 color-white"
															style={{ paddingRight: "40px" }}
														>
															Got a question? We'd love to hear from you. Send us a
															message and we'll respond as soon as possible
														</h1>
														<p className="cont1-para color-black700">
															"<span className="color-red">*</span>" indicates required
															fields
														</p>
													</div>
													<Form>
														<div className="text-align">
															<Field
																className="cont1-inputBox no-margin"
																type="text"
																name="fullName"
																placeholder="Full Name *"
															/>
															<Field
																className="cont1-inputBox no-margin"
																type="email"
																name="email"
																placeholder="Email *"
															/>
															<Field
																className="cont1-inputBox no-margin"
																type="text"
																name="phoneNumber"
																placeholder="Phone *"
															/>
															<Field
																as="textarea"
																className="cont1-inputBox no-margin"
																placeholder="Message"
																name="message"
																cols="50"
																rows="5"
															></Field>
														</div>
														<button
															className="cont1-button bg-yellow900 color-black900 border-yellow900"
															type="submit"
														>
															submit
															<span
																className={
																	loading ? "spinner-border spinner-border-sm" : ""
																}
																style={{ marginLeft: "5px" }}
															/>
														</button>
													</Form>
												</div>
											</div>
										</div>
									</div>
								)}
							</Formik>
						</div>
					</div>
				</section>

				<section className="section-4">
					<div className="container">
						<div className="row">
							<div className="col-lg-5 no-padding">
								<Link to="/Advertise">
									<img src={advertiseImage} className="image-ads last-one" alt="ad" />
								</Link>
							</div>
							<div className="col-lg-2"></div>

							<div className="col-lg-5 no-padding cursor-pointer">
								<div onClick={() => setOpenModal(!openModal)}>
									<img src={newsletter} className="image-ads" alt="ad" />
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			{openModal && <SubscriptionModal openModal={openModal} setOpenModal={setOpenModal} />}

			<Footer />
		</div>
	);
};

export default ContactUs;
