import { combineReducers } from "@reduxjs/toolkit";
import GetUserListingSlice from "./GetUserListingSlice";
import GetUserPackageSlice from "./GetUserPackageSlice";
import AddUserRentalProductSlice from "./AddUserRentalProductSlice";
import UpdateUserProfileSlice from "./UpdateUserProfileSlice";

export const userSlice = combineReducers({
	packages: GetUserPackageSlice,
	listings: GetUserListingSlice,
	addUserRentalProduct: AddUserRentalProductSlice,
	updateUserProfile: UpdateUserProfileSlice
});
