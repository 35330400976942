import React from "react";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import { advertiseImage, newsletter } from "../../assets/images";
import { Link } from "react-router-dom";
import SubscriptionModal from "../common/SubscriptionModal";
import { useState } from "react";
import { useContext } from "react";
import { UserContext } from "../../Context/userContext";
const Selling = () => {
	const [openModal, setOpenModal] = useState(false);

	const { isAuth } = useContext(UserContext);

	return (
		<div>
			<Navbar activeSelling={true} />
			<section className="selling-home-section">
				<div className="sell position-relative">
					<div className="overlay bg-overlay"></div>
					<div className="container">
						<div className="row">
							<div className="col-lg-12 no-padding">
								<h1 className="sell-heading color-yellow900">Selling</h1>
							</div>
							<div className="col-lg-12 no-padding">
								<div className="sell-line bg-yellow900"></div>
								<p className="sell-paragraph color-white">
									<span className="bold color-yellow900">Yellowish.io</span> is the quick and easy
									online marketplace for finding, buying, and selling used farm equipment,
									construction equipment, trucks, and trailers.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="sell-sec2">
				<div className="container">
					<div className="row align-all-center sell-sec2-padd">
						<div className="col-lg-12">
							<h1 className="align-all-center sell-sec2-align">Selling on Yellowish is as easy as...</h1>
						</div>
						<div className="col-lg-4 text-alig no-padding">
							<div className="box-shadow text-align padding-adjust">
								<h5 className="sell-sec2-heading1 color-yellow900">Step 1</h5>
								<h4 className="sell-sec2-heading2">Register</h4>
								<div className="sell-sec2-bottom-line bg-yellow900"></div>
							</div>
						</div>

						<div className="col-lg-4 text-alig no-padding">
							<div className="box-shadow text-align padding-adjust">
								<h5 className="sell-sec2-heading1 color-yellow900">Step 2</h5>
								<h4 className="sell-sec2-heading2">Access your Account</h4>
								<div className="sell-sec2-bottom-line bg-yellow900"></div>
							</div>
						</div>

						<div className="col-lg-4 text-alig no-padding">
							<div className="box-shadow text-align padding-adjust width-adjust">
								<h5 className="sell-sec2-heading1 color-yellow900">Step 3</h5>
								<h4 className="sell-sec2-heading2">Submit your listing</h4>
								<div className="sell-sec2-bottom-line bg-yellow900"></div>
							</div>
						</div>

						{!isAuth && (
							<div className="col-lg-12 text-align">
								<p className="sell-sec2-paragraph color-black800">
									If you haven’t registered already and want to get selling, click the button below.
								</p>
								<Link
									to="/my-account"
									className="sell-sec2-button color-black900 bg-yellow900 border-yellow900"
								>
									Register
								</Link>
							</div>
						)}
					</div>
				</div>
			</section>

			<section className="section-4">
				<div className="container">
					<div className="row">
						<div className="col-lg-5 no-padding">
							<div className="cursor-pointer" onClick={() => setOpenModal(!openModal)}>
								<img src={newsletter} className="image-ads" alt="ad" />
							</div>
						</div>
						<div className="col-lg-2"></div>
						<div className="col-lg-5 no-padding">
							<a href="/Advertise">
								<img src={advertiseImage} className="image-ads last-one" alt="ad" />
							</a>
						</div>
					</div>
				</div>
			</section>

			<section className="section-5">
				<div className="container-fluid no-padding">
					<div className="row no-margin">
						<div className="col-lg-4 no-padding">
							<div className="layout quotation-box">
								<div className="layout-1 overlay quotation-bg-size"></div>
								<div className="quotation-index">
									<h1 className="heading color-white">
										<span className="heading-span">GET AN</span>
										<br />
										INSURANCE QUOTE
									</h1>
									<div className="align-all-center">
										<p className="description color-white">
											Request a quotation to insure your commercial vehicles and equipment.
										</p>
									</div>
								</div>
								<div className="quotation-index">
									<Link
										to="/InsuranceQuote"
										className="finance-button bg-yellow900 color-black900 border-yellow900"
									>
										GET A QUOTE
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-4 no-padding">
							<div className="layout-yellow quotation-box">
								<div className="layout-2 overlay quotation-bg-size"></div>
								<div className="quotation-index">
									<h1 className="heading color-black900">
										<span className="heading-span">GET A</span>
										<br />
										Transport QUOTE
									</h1>
									<div className="align-all-center">
										<p className="description color-black900">
											At Yellowish we have a truckload of services, including transport solutions.
										</p>
									</div>
								</div>
								<div className="quotation-index">
									<Link
										to="/TransportQuote"
										className="finance-button1 bg-white color-black900 border-white"
									>
										GET A QUOTE
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-4 no-padding">
							<div className="layout-light-black quotation-box">
								<div className="layout-3 overlay quotation-bg-size"></div>
								<div className="quotation-index">
									<h1 className="heading color-white">
										<span className="heading-span">GET A</span>
										<br />
										Finance QUOTE
									</h1>
									<div className="align-all-center">
										<p className="description color-white">
											Finance solutions for commercial vehicles and equipment.
										</p>
									</div>
								</div>
								<div className="quotation-index">
									<Link
										to="/FinanceQuote"
										className="finance-button bg-yellow900 color-black900 border-yellow900"
									>
										GET A QUOTE
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />

			{openModal && <SubscriptionModal openModal={openModal} setOpenModal={setOpenModal} />}
		</div>
	);
};

export default Selling;
