import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import QuoteSection from "../common/QuoteSection";
import { Carousel } from "react-bootstrap";
import { homePageBanner_3 } from "../../assets/images";
import { useContext } from "react";
import { UserContext } from "../../Context/userContext";

const Home = () => {
	const { advertisementData } = useContext(UserContext);

	useEffect(() => {
		let slideIndex = 0;
		showSlides();

		function showSlides() {
			let i;
			let slides = document.getElementsByClassName("slides");
			for (i = 0; i < slides.length; i++) {
				slides[i].style.display = "none";
			}
			slideIndex++;
			if (slideIndex > slides.length) {
				slideIndex = 1;
			}
			slides[slideIndex - 1].style.display = "flex";

			setTimeout(showSlides, 10000);
		}
	});

	useEffect(() => {

	}, [advertisementData])

	return (
		<div>
			<Navbar activeHome={true} />
			<main>
				<section className="home-hero-section">
					<div className="slideshow-container">
						<div className="slides fade-img">
							<img
								style={{ width: "100%" }}
								src={`${process.env.PUBLIC_URL}/images/home_page_banner_1.jpg`}
								alt=""
							/>
						</div>
						<div className="slides fade-img">
							<img
								style={{ width: "100%" }}
								src={`${process.env.PUBLIC_URL}/images/home_page_banner_3.jpg`}
								alt=""
							/>
						</div>
						<div className="slides fade-img">
							<img
								style={{ width: "100%" }}
								src={`${process.env.PUBLIC_URL}/images/home_page_banner_2.jpg`}
								alt=""
							/>
						</div>
						<div className="slides fade-img">
							<img
								style={{ width: "100%" }}
								src={`${process.env.PUBLIC_URL}/images/home_page_banner_4.jpg`}
								alt=""
							/>
						</div>
						<div className="slides fade-img">
							<img
								style={{ width: "100%" }}
								src={`${process.env.PUBLIC_URL}/images/home_page_banner_5.jpg`}
								alt=""
							/>
						</div>
						{/* <div className="slides fade-img">
							<img
								style={{ width: "100%" }}
								src={`${process.env.PUBLIC_URL}/images/yellowish-comingsoon-banner2-1024x373.jpg`}
								alt=""
							/>
						</div>
						<div className="slides fade-img">
							<img
								style={{ width: "100%" }}
								src={`${process.env.PUBLIC_URL}/images/yellowish-comingsoon-banner1-1024x373.jpg`}
								alt=""
							/>
						</div> */}
					</div>
					<div className="home-hero-background-overlay overlay"></div>
					<div className="container">
						<div className="row">
							<div className="col-lg-10 offset-lg-1">
								<div className="logo-hero-section">
									<img className="hero-img" src="images/yellowish-logo-whitetext.png" alt="" />
								</div>
								<div className="align-all-center">
									<p className="heading">
										YELLOWISH IS A DIGITAL MARKETING PLATFORM
										<br /> FOR ALL THINGS COMMERCIALLY YELLOW.
									</p>
								</div>
                                
								<p className="description-1">
									We bring together buyers and sellers across all commercial vehicle <br /> types,
									with finance, insurance, and transport at your fingertips to help <br /> you take
									the load off!
								</p>
								<br />
								<div className="description-secondary-text">
									<p className="description-2">
										Whether you are looking to buy, sell or rent, or if you require transport,
										finance, or insurance – it is all happening at Yellowish.io! <br /> Connect with
										sellers free of charge, or list your pre-owned Construction/Mining, Farm
										Machinery, Trucks, Trailers, Lifting, Parts and Components on this easy-to-use
										platform.
									</p>
									<br />
									<p className="description-2">
										Shop, sell and compare from anywhere, anytime on our world-class website. With a
										wide range of heavy-duty vehicles and services <br /> on offer, you’ll be sure
										to find a solution to meet your industry-specific needs.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="advertisement">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<p className="heading">Advertisement</p>
								<Carousel interval={4000} indicators={false}>
									<Carousel.Item>
										<Link to="/Advertise">
											<img
												className="d-block w-100 banner-height"
												src="images/yellowish_ads_banner_1.jpg"
												alt="advertise"
											/>
										</Link>
									</Carousel.Item>
									{advertisementData?.length !== 0 && (
										advertisementData?.map((homePageAds, index) => (
											homePageAds.name === "Home Page" &&
											homePageAds.Advertisements.map((ads) => {
												return (
													<Carousel.Item>
														<a href={ads?.AdvertisementImages[0].ad_link} target="_blank">
															<img
																className="d-block w-100 banner-height"
																src={ads?.AdvertisementImages[0].image}
																alt="transco"
																width={450}
																height={450}
															/>
														</a>
													</Carousel.Item>
												)
											})
										))
									)}

									{/* <Carousel.Item>
										<a href="https://drivetraintts.co.za" target="_blank">
											<img
												className="d-block w-100"
												src="images/IDT_Banner_2_1500X527.png"
												alt="transco"
											/>
										</a>
									</Carousel.Item>
									<Carousel.Item>
										<a href="https://www.allsurplus.com/events/24941" target="_blank">
											<img
												className="d-block w-100"
												src={homePageBanner_3}
												alt="allsurplus"
											/>
										</a>
									</Carousel.Item>
									<Carousel.Item>
										<a href="https://truckworld.co.za" target="_blank">
											<img
												className="d-block w-100"
												src="images/yellowish_ads_banner_4.jpg"
												alt="allsurplus"
											/>
										</a>
									</Carousel.Item>
									<Carousel.Item>
										<a href="https://www.mhsequipment.com/" target="_blank">
											<img
												className="d-block w-100"
												src="images/yellowish_ads_banner_5.jpg"
												alt="Third slide"
											/>
										</a>
									</Carousel.Item> */}
								</Carousel>
							</div>
						</div>
					</div>
				</section>

				<section className="section-3">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="mb-10">
									<h1 className="heading text-center">What are you looking for?</h1>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="selling-box">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-4 right">
								<div className="selling selling-line align-all-center flex-column">
									<h1 className="selling-heading text-center">ARE YOU A BUYER?</h1>
									<p className="selling-description text-center">
										Search and connect with sellers free of charge on our easy-to-use platform
									</p>
									<Link to="/Buying" className="selling-button cursor-pointer text-center">
										Buy Here
									</Link>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="selling selling-line align-all-center flex-column">
									<h1 className="selling-heading text-center">ARE YOU A SELLER?</h1>
									<p className="selling-description text-center">
										List your commercial vehicle and equipment here.
									</p>
									<Link to="/Selling" className="selling-button cursor-pointer text-center">
										Sell Here
									</Link>
								</div>
							</div>
							<div className="col-lg-4 left">
								<div className="selling last-one align-all-center flex-column">
									<h1 className="selling-heading text-center">LOOKING TO RENT?</h1>
									<p className="selling-description text-center">List or find rental options here.</p>
									<Link to="/Rental" className="selling-button cursor-pointer text-center">
										Rent Here
									</Link>
								</div>
							</div>
						</div>
					</div>
				</section>
				<QuoteSection />
				{/* <section className="section-4">
					<div className="container">
						<div className="row">
							<div className="col-lg-5 no-padding">
								<Link to="#">
									<img
										src={`${process.env.PUBLIC_URL}/images/nationwide-trucks.png`}
										className="image-ads"
										alt="ad"
									/>
								</Link>
							</div>
							<div className="col-lg-2"></div>
							<div className="col-lg-5 no-padding">
								<div className="slideshows-2">
									<div className="ads-tracking fade-img-2">
										<img className="images-2" src="images/tracker.png" alt="" />
									</div>
									<div className="ads-tracking fade-img-2">
										<img className="images-2" src="images/tracker-2.png" alt="" />
									</div>
									<Link className="prev-2 cursor-pointer" onClick="plusSlides2(-1)">
										❮
									</Link>
									<Link className="next-2 cursor-pointer" onClick="plusSlides2(1)">
										❯
									</Link>
								</div>
							</div>
						</div>
					</div>
				</section> */}
			</main>
			<Footer />
		</div>
	);
};

export default Home;
