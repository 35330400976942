import React from "react";

const PolicyHeader = ({ heading, description }) => {
	return (
		<div>
			<section class="policy-header">
				<div class="policy position-relative">
					<div class="overlay bg-overlay"></div>
					<div class="container">
						<div class="row">
							<div class="col-lg-12 no-padding">
								<h1 class="policy-heading color-yellow900">{heading}</h1>
							</div>
							<div class="col-lg-12 no-padding">
								<div class="policy-line bg-yellow900"></div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default PolicyHeader;
