import React from "react";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import PolicyHeader from "./PolicyHeader";

const RefundPollicy = () => {
	return (
		<div>
			<Navbar />
			<PolicyHeader heading="Refund Policy" />

			<section className="container">
				<div class="m-3">
					<div class=" elementor-widget-text-editor">
						<ol>
							<li>
								<strong> OUR SERVICES</strong>
							</li>
						</ol>
						<p>We supply online classifieds advertising and other third-party services.</p>
						<ol start="2">
							<li>
								<strong> FAMILIARIZE YOURSELF</strong>
							</li>
						</ol>
						<p>
							We will not provide a refund, for any reason once the advertisement has been published. We
							may decide to accept your request for a refund on a case-by-case basis. Our decisions about
							refunds are final and depend on the facts and circumstances of the reason for the refund
							request. We’ll notify you if we decide to give a refund. All refunds are made at our sole
							discretion and without admission of liability.
						</p>
						<ol start="3">
							<li>
								<strong> COOLING OFF PERIOD</strong>
							</li>
						</ol>
						<p>
							Section 44 of the ECT Act may apply to your electronic transactions. If you qualify as a
							consumer under the Electronic Communications and Transactions Act, you may be entitled to
							cancel some electronic transactions within seven days, without reason or penalty. Section 44
							is only applicable if you are a natural person –- in other words, a human being. You must
							also be the end-user of the service. The transaction must be an electronic transaction –- a
							transaction concluded via (in whole or in part) a website, email, or SMS.
						</p>
						<ol start="4">
							<li>
								<strong> OUR PROMISE</strong>
							</li>
						</ol>
						<p>
							We promise that we will provide all our services as soon as reasonably possible after you
							have informed us that you would like us to advertise on our website and have completed an
							order, made the payment, and or fulfilled all other requirements that we may have, like
							supplied photos.
						</p>
						<ol start="5">
							<li>
								<strong> REFUNDS IN TERMS OF COOLING-OFF PERIOD</strong>
							</li>
						</ol>
						<p>
							The right to cancel does not apply to any services where you have given us your permission
							to provide them to you within seven days of accepting your order.
						</p>
						<ol start="6">
							<li>
								<strong> PREPAYMENT</strong>
							</li>
						</ol>
						<p>
							If the right to cancel does apply and you have prepaid the price of the services, we will
							refund you the price of the services within 30 days of the date on which you cancelled the
							order.
						</p>
						<ol start="7">
							<li>
								<strong> SERVICES</strong>
							</li>
						</ol>
						<p>
							An unsatisfactory service is one that was not provided in a way or was not of a level of
							quality that an average person would expect of that type of service.
						</p>
						<ol start="8">
							<li>
								<strong> STATUTORY COMPENSATION</strong>
							</li>
						</ol>
						<p>
							We will refund the price of any unsatisfactory service that you submit a successful claim.
							Refund claims must follow our refund procedure below.
						</p>
						<ol start="9">
							<li>
								<strong> CHOICE OF COMPENSATION</strong>
							</li>
						</ol>
						<p>Any customer that is also a consumer under the CPA may decide whether we should either:</p>
						<ul>
							<li>provide them with additional services to remedy the unsatisfactory service</li>
							<li>or refund them for unsatisfactory service</li>
						</ul>
						<p>
							We reserve the right to decide how to compensate any of our customers within the frameworks
							of our policies and the protection the law provides you.
						</p>
						<ol start="10">
							<li>
								<strong> WARRANTY ON REMEDIES</strong>
							</li>
						</ol>
						<p>
							We warrant that we will provide all additional services to remedy unsatisfactory services as
							soon as reasonably possible after you choose for us to, or we decide to remedy any
							unsatisfactory service.
						</p>
						<ol start="11">
							<li>
								<strong> PROCEDURE FOR REFUNDS FROM OTHER SERVICE PROVIDERS</strong>
							</li>
						</ol>
						<p>
							Customers of other service providers (such as downstream service providers who pass on our
							services to other customers) must submit claims for unsatisfactory services directly to that
							service provider. Customers must use the correct refund procedure. Please call the customer
							services department of the relevant service provider for details of their refund procedures.
						</p>
						<ol start="12">
							<li>
								<strong> DELAYS IN PROVIDING SERVICES</strong>
							</li>
						</ol>
						<p>
							We will diligently try to provide the services as agreed. However, there may be delays that
							are not our fault that prevent us from providing the services. We will notify you of any
							delays caused by reasons beyond our control.
						</p>
						<ol start="13">
							<li>
								<strong> PLACE, DATE, AND TIME FOR PROVIDING SERVICES</strong>
							</li>
						</ol>
						<p>
							Except for when the services are provided over the Internet, we will only provide you with
							the services at the place and at the date and time agreed to between us. If we are unable to
							provide the services to you at the place, date, and time as agreed for any reason, we will
							contact you and agree on a new place, date, and time to provide the services to you.
						</p>
						<ol start="14">
							<li>
								<strong> ASSIGNMENT OF THE SERVICES</strong>
							</li>
						</ol>
						<p>
							We may assign our rights and duties under the agreement, including our duty to provide the
							services to you, to any successor or purchaser of our business, or to some of our assets.
							Any successor or purchaser will provide alternative services like ours that are reasonably
							accessible to you.
						</p>
						<ol start="15">
							<li>
								<strong> OUR REFUNDS PROCEDURE</strong>
							</li>
						</ol>
						<p>
							You must use our refund procedure for claiming unsatisfactory services, or else we may
							refuse to process the claim. Our refunds procedure is as follows:
						</p>
						<p>
							Download a refund claim form from our website at https://staging.yellowish.io/ or ask us to
							fax, post, or email the form to you.
						</p>
						<p>
							Complete the form with your contact details, date, and place of purchase, and how and why
							you believe the services were unsatisfactory
						</p>
						<p>
							Email, fax, post or deliver the refund claim form to us (we should contact you with a refund
							claim number within 24 hours of receipt, otherwise you must contact us to get the number)
						</p>
						<p>
							We may tell you that we do not believe you have a claim (in this case, we may refuse to pay
							a refund)
						</p>
						<p>
							We will notify you once we have received the refund claim form associated with your claim
							and we will then investigate as soon as reasonably possible whether the services we provided
							you were unsatisfactory (we will create a report on whether we believe the services were of
							a level of quality that an average person would expect of that type of service or not)
						</p>
						<p>
							If the services were cancelled too late or our report says that we believe the services were
							of a level of quality that an average person would expect of that type of service, then we
							will inform you that we are unfortunately unable to remedy or refund the service (we will
							send you the written report)
						</p>
						<p>
							If, however, our report says that we believe the services were not of a level of quality
							that an average person would expect of that type of service, then we will use the payment
							method on the account where possible. Where this isn’t possible, we may issue refunds in the
							form of ad credits. If you use Monthly Invoicing to pay for your ads, you can expect to be
							issued with credit memos which can be applied against open invoices and used towards future
							spending.
						</p>
						<p>
							If you do not accept that we provided an unsatisfactory service, and our customer services
							department has not been able to help, any customer may still take the matter up with a
							suitable ombudsman or other dispute resolution body or take legal action. The dispute
							resolution procedures under the CPA do not necessarily apply to all transactions with us.
							This policy does not exclude any other rights customers may have.
						</p>
						<ol start="18">
							<li>
								<strong> CUSTOMER FEEDBACK</strong>
							</li>
						</ol>
						<p>
							We aim for complete customer satisfaction. We respect our customers’ rights and always try
							to comply with best practices and all relevant laws. If you are not satisfied with any of
							our services or have any questions, please contact us, and have your invoice ready. We will
							try our best to solve your problem. We are proud of the reputation of our services.
						</p>
						<p>
							<em>UPDATED MAY 2022</em>
						</p>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default RefundPollicy;
