import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CategoryThunk, ResetCategory } from "../../redux/CategorySlice";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";

const Buying = () => {
	const dispatch = useDispatch();

	const {
		loading: CategoryLoading,
		error: CategoryError,
		errorMessage: CategoryErrorMessage,
		CategoryData
	} = useSelector((state) => state.categories);

	useEffect(() => {
		dispatch(CategoryThunk());
	}, [dispatch]);

	if (CategoryError) {
		toast.error(CategoryErrorMessage);
		dispatch(ResetCategory());
	}

	return (
		<div>
			<Navbar activeBuying={true} />

			<section className="position-relative">
				<div className="bg-overlay overlay"></div>
				<div className="listing-detail-sec1">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 no-padding">
								<h1 className="listing-detail-heading color-yellow900">Categories </h1>
							</div>
							<div className="col-lg-12 no-padding">
								<div className="bottom-line bg-yellow900"></div>
								<p className="listing-detail-paragraph color-white">
									<span className="color-yellow900">Yellowish.io</span> is the quick and easy online
									marketplace for finding, buying, and selling used farm equipment, construction
									equipment, trucks, and trailers.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="buying-section">
				{CategoryLoading ? (
					<div className="d-flex justify-content-center align-items-center" style={{ height: "90vh" }}>
						<Spinner />
					</div>
				) : (
					<div className="container">
						<div className="row">
							{CategoryData?.data?.map((category, index) => {
								return (
									<div className="col-lg-4 mb-4">
										<div className="category-border">
											<div className="category">
												<div className="category-circle">
													<img
														width="58.274"
														height="58.274"
														src={category?.image}
														alt="category"
													/>
												</div>
												<h6 className="category-heading">{category?.name}</h6>
												<div className="align-all-center">
													<p className="category-description">{category?.description}</p>
												</div>
												<Link
													to={`/Listings/${category?.id}/0`}
													className="category-button cursor-pointer"
												>
													Learn More
												</Link>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</section>
			<Footer />
		</div>
	);
};

export default Buying;
