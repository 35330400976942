import { configureStore } from "@reduxjs/toolkit";
import AdvertisementSlice from "./AdvertisementSlice";
import ContactUsSlice from "./ContactUsSlice";
import AccountSlice from "./AccountSlice";
import { quotes } from "./Quote";
import SubscriptionSlice from "./SubscriptionSlice";
import GetProfileSlice from "./GetProfileSlice";
import { AccountReducers } from "./Account";
import CategorySlice from "./CategorySlice";
import GetPackagesSlice from "./GetPackagesSlice";
import { userSlice } from "./User";
import { ListingReducers } from "./Listing";
import PaymentSlice from "./PaymentSlice";
import GetAllAdvertisementSlice from "./GetAllAdvertisementSlice";
import { RentalsReducers } from "./Rental";
export const store = configureStore({
	reducer: {
		quote: quotes,
		contactUs: ContactUsSlice,
		advertise: AdvertisementSlice,
		account: AccountSlice,
		subscription: SubscriptionSlice,
		getUserProfile: GetProfileSlice,
		AccountState: AccountReducers,
		categories: CategorySlice,
		packages: GetPackagesSlice,
		user: userSlice,
		listing: ListingReducers,
		payment: PaymentSlice,
		getAdvertisement: GetAllAdvertisementSlice,
		rentals: RentalsReducers
	}
	// middleware: ""
});
