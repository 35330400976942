import React, { useContext, useState } from "react";
import { UserContext } from "../../Context/userContext";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import ForgetForm from "./ForgetForm";
import LoginForm from "./LoginForm";
import Profile from "./Profile";
import RegisterForm from "./RegisterForm";

const Account = () => {
	const [openTab, setOpenTab] = useState("Login");
	const { isAuth } = useContext(UserContext);

	const handleTab = (evt, tab) => {
		var i, tabcontent, tablinks;
		tabcontent = document.getElementsByClassName("tabcontent");
		for (i = 0; i < tabcontent.length; i++) {
			tabcontent[i].style.display = "none";
		}
		tablinks = document.getElementsByClassName("tablinks");
		for (i = 0; i < tablinks.length; i++) {
			tablinks[i].className = tablinks[i].className.replace(" active-class-2", "");
		}
		evt.currentTarget.className += " active-class-2";
		setOpenTab(tab);
	};

	return (
		<>
			<Navbar activeAccount={true} />
			{isAuth ? (
				<Profile />
			) : (
				<div className="container form">
					<div className="row">
						<div className="col-lg-12 no-padding">
							<p className="form-para">Please login to view your dashboard</p>
						</div>
						<div className="tabs-main">
							<div className="border-line">
								<button
									className="form-bt1 tablinks position-relative active-class-2"
									onClick={(e) => handleTab(e, "Login")}
									id="defaultOpen"
								>
									<p className="form-action">Login</p>
								</button>
								<button
									className="form-bt1 tablinks position-relative"
									onClick={(e) => handleTab(e, "Register")}
								>
									<p className="form-action">Register</p>
								</button>
								<button
									className="form-bt1 tablinks position-relative"
									onClick={(e) => handleTab(e, "Forget")}
								>
									<p className="form-action">Forgot</p>
								</button>
							</div>
						</div>
						{openTab === "Login" && <LoginForm />}
						{openTab === "Register" && <RegisterForm setOpenTab={handleTab} />}
						{openTab === "Forget" && <ForgetForm setOpenTab={handleTab} />}
					</div>
				</div>
			)}
			<Footer />
		</>
	);
};

export default Account;
