import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row, Spinner } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch, useSelector } from "react-redux";
import { AddListingValidationSchema } from "../../utils/FormValidationSchema";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import ImageUploading from "react-images-uploading";
import { AddListingThunk, ResetAddListingData } from "../../redux/Listing/SubmitListingSlice";
import { UserContext } from "../../Context/userContext";
import toast from "react-hot-toast";
import { CategoryThunk } from "../../redux/CategorySlice";
import { useNavigate } from "react-router-dom";

const SubmitListing = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [images, setImages] = useState([]);
	const [dealerImage, setDealerImage] = useState("");
	const [openImagesModal, setOpenImagesModal] = useState(false);
	const [editor, setEditor] = useState("");
	const [price, setPrice] = useState("");
	const maxNumber = 20;

	const {
		loading: UserlistingLoading,
		success: UserlistingSuccess,
		error: UserlistingError,
		errorMessage: UserlistingErrorMessage
	} = useSelector((state) => state.listing.AddListing);

	const {
		loading: CategoryLoading,
		success: CategorySuccess,
		CategoryData
	} = useSelector((state) => state.categories);

	const { userInfo, isAuth } = useContext(UserContext);

	const AddListingInitialValues = {
		category: CategorySuccess ? CategoryData?.data[0]?.id : "",
		year: "",
		make: "",
		model: "",
		machine_type: "",
		listing_package: userInfo?.user_packages ? userInfo?.user_packages[0]?.id : "",
		title: "",
		price: "",
		hours: "",
		mileage: "",
		service_history: "",
		serial_number: "",
		fuel_type: "",
		engine_type: "",
		engine_max_power: "",
		engine_max_torque: ""
	};

	useEffect(() => {
		dispatch(CategoryThunk());
	}, [dispatch]);

	const onChange = (imageList) => {
		setImages(imageList);
	};

	const onDealerImageChange = (e) => {
		setDealerImage(e.target.files[0]);
	}
	const loading = false;

	const handleSubmit = (formValues) => {
		if (images.length !== 0)
			dispatch(
				AddListingThunk({ formValues, editor: editor ? editor.getCurrentContent().getPlainText() : "", images, dealerImage, price })
			);
		else toast.error("Please upload product image.");
	};

	if (UserlistingSuccess) {
		toast.success("Listing has been added successfully.");
		dispatch(ResetAddListingData());
		navigate("/my-account");
	}
	if (UserlistingError) {
		toast.error(UserlistingErrorMessage);
		dispatch(ResetAddListingData());
		navigate("/my-account");
	}

	return (
		<div>
			<Navbar />
			<div className="container">
				{loading || CategoryLoading ? (
					<div className="d-flex justify-content-center align-items-center" style={{ height: "90vh" }}>
						<Spinner />
					</div>
				) : isAuth ? (
					<Formik
						initialValues={AddListingInitialValues}
						validationSchema={AddListingValidationSchema}
						enableReinitialize={true}
						onSubmit={handleSubmit}
					>
						{({ errors, touched, values }) => (
							<Form className="mt-5 m-">
								<div className="row mb-3 no-padding">
									<Col xl="4">
										<label className="pl-0 col-lg-3 color-black800  add-listing-label" for="title">
											Title 
										</label>
									</Col>
									<Col xl="8">
										<Field className="col-lg-8 form-control rounded-1 mw-100 form-input-border" type="text" name="title" />
									</Col>
									<Col xl="4" />
									<Col xl="8">
										<p className="col-lg-12 input-info-text error-text-color">
											{errors.title && touched.title ? errors.title : ""}
										</p>
									</Col>
								</div>

								<div className="row mb-3 no-padding">
									<Col xl="4">
										<label className="w-25 add-listing-label color-black800" for="content">
											Content 
										</label>
									</Col>
									<Col xl="8">
										<div className="mw-100  p-0">
											<Editor
												className="form-control"
												editorState={editor}
												editorStyle={{
													border: "2px solid #F1F1F1",
													height: "500px",
													padding: "10px"
												}}
												toolbarClassName="toolbarClassName"
												wrapperClassName="wrapperClassName"
												editorClassName="editorClassName"
												onEditorStateChange={(e) => {
													setEditor(e);
												}}
											></Editor>
										</div>
									</Col>
									{/* <Field className="form-control rounded-1 form-input" type="text" name="content" required /> */}
								</div>

								<div className="row mb-3 no-padding">
									<Col xl="4">
										<label className="color-black800 add-listing-label" for="price">
											Price 
										</label>
									</Col>
									<Col xl="8">
										<input className="form-control mw-100 rounded-1 form-input-border" type="text" placeholder="Price" name="price" value={price} onChange={(e) => setPrice(e.target.value)} />
									</Col>
								</div>

								<div className="row mb-3 no-padding">
									<Col xl="4">
										<label className="pl-0 col-lg-3 color-black800  add-listing-label" for="category">
											Category <span className='color-red'>*</span>
										</label>
									</Col>
									<Col xl="8">
										<Field
											style={{ cursor: "pointer" }}
											as="select"
											className="col-lg-8 form-control rounded-1 mw-100 form-input-border"
											type="text"
											name="category"
											defaultValue="Construction & Mining"
											placeholder="Choose Category"
										>
											{CategoryData?.data?.map((category, index) => {
												return (
													<option defaultValue={CategoryData?.data[0]?.id} value={category.id}>
														{category.name}
													</option>
												);
											})}
										</Field>
									</Col>
								</div>

								<div className="row mb-3 no-padding">
									<Col xl="4">
										<label className="pl-0 col-lg-3 color-black800 add-listing-label" style={{}} for="listing_package">
											Listing Package <span className='color-red'>*</span>
										</label>
									</Col>
									<Col xl="8">
										<Field style={{ cursor: "pointer" }} as="select" className="col-lg-8 form-control rounded-1 mw-100 form-input-border" type="text" name="listing_package" placeholder="Choose Package">
											{userInfo?.user_packages?.map((user_package, index) => (
												<option defaultValue={userInfo?.user_packages[0]?.id} value={user_package.id}>
													{user_package.name}
												</option>
											))}
										</Field>
									</Col>
								</div>

								<div className="row mb-3 no-padding">
									<Col xl="4">
										<label className="color-black800 add-listing-label" for="dealerLogo">
											Dealer Logo <span className='color-red'>*</span>
										</label>
									</Col>
									<Col xl="8">
										<input className="form-control mw-100 rounded-1 form-input-border" type="file" onChange={onDealerImageChange} placeholder="Enter Dealer Logo" name="dealerLogo"
										/>
										{/* <ImageUploading className="form-control" multiple value={images} onChange={onChange} maxNumber={1} dataURLKey="data_url">
											{({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
												<div className="upload__image-wrapper">
													<button style={isDragging ? { color: "red" } : undefined} onClick={onImageUpload} className="form-bt2 color-black900" {...dragProps}>
														Click or Drop here
													</button>
													&nbsp;
													<div className="row image-item">
														<Row>
															{imageList.map((image, index) => (
																<Col className="m-2" md="3">
																	<img src={image["data_url"]} alt="" width="120" height="100" />
																	<div className="image-item__btn-wrapper">
																		<button className="form-bt2 color-black900 mt-1" onClick={() => onImageRemove(index)}>
																			Remove
																		</button>
																	</div>
																</Col>
															))}
														</Row>
													</div>
												</div>
											)}
										</ImageUploading> */}
									</Col>
								</div>

								<div className="row mb-3 no-padding">
									<Col xl="4">
										<label className="color-black800 add-listing-label" for="vehicle_locations">
											Image Gallery <span className='color-red'>*</span>
										</label>
									</Col>
									<Col xl="8">
										<div
											className="form-bt2 color-black900 rounded-2"
											onClick={() => setOpenImagesModal(!openImagesModal)}
										>
											Upload Images
										</div>
									</Col>
								</div>

								<div className="row mb-3 no-padding">
									<Col xl="4">
										<label className="color-black800 d-block add-listing-label " for="make">
											Make <span className='color-red'>*</span>
										</label>
										<Field className="form-control rounded-1 form-input-border w-100" type="text" name="make" />
										<p className="input-info-text error-text-color">
											{errors.make && touched.make ? errors.make : ""}
										</p>
									</Col>
									<Col xl="4">
										<label className="color-black800 d-block add-listing-label " for="model">
											Model <span className='color-red'>*</span>
										</label>
										<Field
											className="form-control rounded-1 form-input-border w-100"
											type="text"
											name="model"
										/>
										<p className="input-info-text error-text-color">
											{errors.model && touched.model ? errors.model : ""}
										</p>
									</Col>
									<Col xl="4">
										<label className="color-black800 d-block add-listing-label " for="machine_type">
											Machine Type <span className='color-red'>*</span>
										</label>
										<Field
											className="form-control rounded-1 form-input-border w-100"
											type="text"
											name="machine_type"
										/>
										<p className="input-info-text error-text-color">
											{errors.machine_type && touched.machine_type ? errors.machine_type : ""}
										</p>
									</Col>
									<Col xl="4">
										<label className="color-black800 d-block add-listing-label " htmlFor="year">
											Year
										</label>
										<Field className="form-control rounded-1 form-input-border w-100" type="text" name="year" id="year" />
										<p className="input-info-text error-text-color">
											{errors.year && touched.year ? errors.year : ""}
										</p>
									</Col>
									<Col xl="4">
										<label className="color-black800 d-block add-listing-label" for="hours">
											Hours
										</label>
										<Field
											className="form-control rounded-1 form-input-border w-100"
											type="text"
											name="hours"
										/>
										<p className="input-info-text error-text-color">
											{errors.hours && touched.hours ? errors.hours : ""}
										</p>
									</Col>
									<Col xl="4">
										<label className="color-black800 d-block add-listing-label " for="mileage">
											Mileage
										</label>
										<Field
											className="form-control rounded-1 form-input-border w-100"
											type="text"
											name="mileage"
										/>
										<p className="input-info-text error-text-color">
											{errors.mileage && touched.mileage ? errors.mileage : ""}
										</p>
									</Col>
									<Col xl="4">
										<label
											className="color-black800 d-block add-listing-label "
											for="service_history"
										>
											Service History
										</label>
										<Field
											className="form-control rounded-1 form-input-border w-100"
											type="text"
											name="service_history"
										/>
										<p className="input-info-text error-text-color">
											{errors.service_history && touched.service_history
												? errors.service_history
												: ""}
										</p>
									</Col>
									<Col xl="4">
										<label
											className="color-black800 d-block add-listing-label "
											for="serial_number"
										>
											Serial Number
										</label>
										<Field
											className="form-control rounded-1 form-input-border w-100"
											type="text"
											name="serial_number"
										/>
										<p className="input-info-text error-text-color">
											{errors.serial_number && touched.serial_number ? errors.serial_number : ""}
										</p>
									</Col>
								</div>
								<div className="row mb-3 no-padding">
								</div>
								{/* <div className="row mb-3 no-padding">
									<Col xl="3">
										<label className="color-black800 d-block add-listing-label" for="fuel_type">
											Fuel Type
										</label>
										<Field
											className="form-control rounded-1 form-input-border w-100"
											type="text"
											name="fuel_type"
										/>
										<p className="input-info-text error-text-color">
											{errors.fuel_type && touched.fuel_type ? errors.fuel_type : ""}
										</p>
									</Col>
									<Col xl="3">
										<label className="color-black800 d-block add-listing-label " for="engine_type">
											Engine Type
										</label>
										<Field
											className="form-control rounded-1 form-input-border w-100"
											type="text"
											name="engine_type"
										/>
										<p className="input-info-text error-text-color">
											{errors.engine_type && touched.engine_type ? errors.engine_type : ""}
										</p>
									</Col>
									<Col xl="3">
										<label
											className="color-black800 d-block add-listing-label "
											for="engine_max_power"
										>
											Engine Max Power (kw)
										</label>
										<Field
											className="form-control rounded-1 form-input-border w-100"
											type="text"
											name="engine_max_power"
										/>
										<p className="input-info-text error-text-color">
											{errors.engine_max_power && touched.engine_max_power
												? errors.engine_max_power
												: ""}
										</p>
									</Col>
									<Col xl="3" style={{ display: "block" }}>
										<label
											className="color-black800 d-block add-listing-label"
											for="engine_max_torque"
										>
											Engine Max Torgue (NM)
										</label>
										<Field
											className="form-control rounded-1 form-input-border w-100"
											type="text"
											name="engine_max_torque"
										/>
										<p className="input-info-text error-text-color">
											{errors.engine_max_torque && touched.engine_max_torque
												? errors.engine_max_torque
												: ""}
										</p>
									</Col>
								</div> */}

								<button className="form-bt2 color-black900" type="submit">
									Submit
									<span
										className={UserlistingLoading ? "spinner-border spinner-border-sm ml-3" : ""}
									/>
								</button>
							</Form>
						)}
					</Formik>
				) : (
					navigate("/my-account")
				)}
			</div>
			<Footer />

			{openImagesModal && (
				<div className="container m-5">
					<Modal
						dialogClassName="h-80"
						style={{ height: "100vh !important" }}
						centered
						show={openImagesModal}
						onHide={() => setOpenImagesModal(!openImagesModal)}
					>
						<ModalHeader className="border-0" closeButton title="Upload Images">
							<div className="">Upload Images</div>
						</ModalHeader>
						<ModalBody>
							<p className="listing-images-subheading">
								Images are automatically uploaded. There is no need to click a button to upload. If you
								click the remove button they are removed.
							</p>
							<ImageUploading
								className="form-control"
								multiple
								value={images}
								onChange={onChange}
								maxNumber={maxNumber}
								dataURLKey="data_url"
							>
								{({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
									<div className="upload__image-wrapper">
										<button
											style={isDragging ? { color: "red" } : undefined}
											onClick={onImageUpload}
											className="form-bt2 color-black900"
											{...dragProps}
										>
											Click or Drop here
										</button>
										&nbsp;
										<div className="row image-item">
											<Row>
												{imageList.map((image, index) => (
													<Col className="m-2" md="3">
														<img src={image["data_url"]} alt="" width="120" height="100" />
														<div className="image-item__btn-wrapper">
															<button
																className="form-bt2 color-black900 mt-1"
																onClick={() => onImageRemove(index)}
															>
																Remove
															</button>
														</div>
													</Col>
												))}
											</Row>
										</div>
									</div>
								)}
							</ImageUploading>
						</ModalBody>
					</Modal>
				</div>
			)}
		</div>
	);
};

export default SubmitListing;
