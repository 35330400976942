import React, { useContext, useState } from "react";
import { Col, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import { UserContext } from "../../Context/userContext";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ResetUpdateUserProfileData, UpdateUserProfileThunk } from "../../redux/User/UpdateUserProfileSlice";

const EditUser = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
    const { userInfo, isAuth } = useContext(UserContext);

    const [formData, setFormData] = useState({
        username: userInfo?.username ? userInfo?.username : "",
        phoneNumber: userInfo?.phone_number ? userInfo?.phone_number : "",
        email: userInfo?.email ? userInfo?.email : ""
    })

	const { loading, success, error, errorMessage } = useSelector((state) => state.user.updateUserProfile);

	const onInputChange = (e) => { 
        setFormData({ ...formData, [e.target.name] : e.target.value });
    }

	const handleSubmit = () => {
        dispatch(UpdateUserProfileThunk({
            username: formData?.username,
            email: formData?.email,
            phone_number: formData?.phoneNumber,
        }))
	};

	if (success) {
		toast.success("Profile has been updated successfully.");
		dispatch(ResetUpdateUserProfileData());
		navigate("/my-account");
	}

	if (error) {
		toast.error(errorMessage);
		dispatch(ResetUpdateUserProfileData());
		// navigate("/my-account");
	}

	return (
		<div>
			<Navbar />
			<div className="container">
				{loading ? (
					<div className="d-flex justify-content-center align-items-center" style={{ height: "90vh" }}>
						<Spinner />
					</div>
				) : isAuth ? (
							<div className="mt-5 m-">
								<div className="row mb-3 no-padding">
									<Col xl="4">
										<label className="color-black800 add-listing-label" for="username">
											Username 
										</label>
									</Col>
									<Col xl="8">
										<input className="form-control mw-100 rounded-1 form-input-border" type="text" placeholder="Enter your username" name="username" value={formData?.username} onChange={onInputChange} />
									</Col>
								</div>

								<div className="row mb-3 no-padding">
									<Col xl="4">
										<label className="color-black800 add-listing-label" for="email">
											Email 
										</label>
									</Col>
									<Col xl="8">
										<input className="form-control mw-100 rounded-1 form-input-border" type="text" placeholder="Enter your email" name="email" value={formData?.email} onChange={onInputChange} />
									</Col>
								</div>

								<div className="row mb-3 no-padding">
									<Col xl="4">
										<label className="color-black800 add-listing-label" for="phoneNumber">
											Phone Number 
										</label>
									</Col>
									<Col xl="8">
										<input className="form-control mw-100 rounded-1 form-input-border" type="text" placeholder="Enter your phone number" name="phoneNumber" value={formData?.phoneNumber} onChange={onInputChange} />
									</Col>
								</div>

								<button disabled={loading} onClick={handleSubmit} className="form-bt2 color-black900" type="submit">
									Edit User
									<span
										className={loading ? "spinner-border spinner-border-sm ml-3" : ""}
									/>
								</button>
							</div>
				) : (
					navigate("/my-account")
				)}
			</div>
			<Footer />

		</div>
	);
};

export default EditUser;
