import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetUserListingThunk = createAsyncThunk("GetUserListing/GetUserListingThunk", async (_, { rejectWithValue }) => {
	try {
		const response = await axios.get(ApiEndpoints.GetUserListing, {
			headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` }
		});
		return response.data.message;
	} catch (error) {
		console.log("error", error);
		return rejectWithValue(error.response.data);
	}
});

const GetUserListingSlice = createSlice({
	name: "GetUserListing",
	initialState: {
		GetUserListingData: {},
		loading: false,
		success: false,
		error: false,
		errorMessage: ""
	},
	reducers: {
		ResetGetUserListing: (state) => {
			state.GetUserListingData = {};
			state.error = false;
			state.success = false;
			state.loading = false;
		}
	},
	extraReducers: {
		[GetUserListingThunk.pending]: (state, action) => {
			state.loading = true;
			state.error = false;
			state.success = false;
		},
		[GetUserListingThunk.fulfilled]: (state, action) => {
			state.GetUserListingData = action.payload;
			state.loading = false;
			state.success = true;
		},
		[GetUserListingThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload;
		}
	}
});

export default GetUserListingSlice.reducer;

export const { ResetGetUserListing } = GetUserListingSlice.actions;

export { GetUserListingThunk };
