import React from "react";

const QuoteHeader = ({ heading, description }) => {
	return (
		<div>
			<section id="rental-sec1">
				<div class="container">
					<div class="row">
						<div class="col-lg-12 no-padding">
							<h1 class="rental-heading color-white">
								{heading}
								<span class="color-yellow900"> Quote </span>
							</h1>
						</div>
						<div class="col-lg-12 no-padding">
							<div class="bottom-line bg-yellow900"></div>
							<p class="rental-paragraph color-white">
								At <span class="bold color-yellow900">Yellowish.io </span>
								we can help you through our added value {description} options.
								<br />
								We can help take your business to the next level with our tailor-made solutions.
							</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default QuoteHeader;
