import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import QuoteSection from "../common/QuoteSection";

const TermsPolicies = () => {
	return (
		<React.Fragment>
			<Navbar />
			<section id="rental-sec1">
				<div class="container">
					<div class="row">
						<div class="col-lg-12 no-padding">
							<h1 class="rental-heading color-white">
								<span class="color-yellow900"> POLICIES AND T&C'S </span>
							</h1>
						</div>
						<div class="col-lg-12 no-padding">
							<div class="bottom-line bg-yellow900"></div>
							<p class="rental-paragraph color-white">
								<span class="bold color-yellow900">Yellowish.io </span>
								makes it easy to do business. We’ve carefully crafted our Terms and
								<br />
								Policies to ensure smooth and transparent interactions on our platform.
							</p>
						</div>
					</div>
				</div>
			</section>
			<div className="container terms-and-policy">
				<div className="row mb-5 ">
					<Col md="6" className="mb-5">
						<div>
							<Link className="terms-and-policy-column" to="/acceptable-use-policy">
								Acceptable Use Policy
							</Link>
						</div>
						<hr className="terms-and-policy-column-hr w-25" />
					</Col>
					<Col md="6" className="mb-5">
						<div>
							<Link className="terms-and-policy-column" to="/refund-policy">
								Refund Policy
							</Link>
						</div>
						<hr className="terms-and-policy-column-hr w-25" />
					</Col>
					<Col md="6" className="mb-5">
						<div>
							<Link className="terms-and-policy-column" to="/cookies-policy">
								Cookie Policy
							</Link>
						</div>
						<hr className="terms-and-policy-column-hr w-25" />
					</Col>
					<Col md="6" className="mb-5">
						<div>
							<Link className="terms-and-policy-column" to="/security-policy">
								Security Policy
							</Link>
						</div>
						<hr className="terms-and-policy-column-hr w-25" />
					</Col>
					<Col md="6" className="mb-5">
						<div>
							<Link className="terms-and-policy-column" to="/privacy-policy">
								Privacy Policy
							</Link>
						</div>
						<hr className="terms-and-policy-column-hr w-25" />
					</Col>
					<Col md="6" className="mb-5">
						<div>
							<Link className="terms-and-policy-column" to="/terms-of-service">
								Terms of Service
							</Link>
						</div>
						<hr className="terms-and-policy-column-hr w-25" />
					</Col>
				</div>
			</div>
			<QuoteSection />
			<Footer />
		</React.Fragment>
	);
};

export default TermsPolicies;
