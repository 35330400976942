import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../utils/ApiEndpoints";

const GetPackagesThunk = createAsyncThunk("GetPackages/GetPackagesThunk", async (_, { rejectWithValue }) => {
	try {
		const response = await axios.get(ApiEndpoints.GetPackages, {
			headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` }
		});
		return response.data;
	} catch (error) {
		console.log("error", error);
		return rejectWithValue(error.response.data);
	}
});

const GetPackagesSlice = createSlice({
	name: "GetPackages",
	initialState: {
		packagesData: {},
		loading: false,
		success: false,
		error: false,
		errorMessage: ""
	},
	reducers: {
		ResetPackagesData: (state) => {
			state.packagesData = {};
			state.error = false;
			state.success = false;
			state.loading = false;
		}
	},
	extraReducers: {
		[GetPackagesThunk.pending]: (state, action) => {
			state.loading = true;
			state.error = false;
			state.success = false;
		},
		[GetPackagesThunk.fulfilled]: (state, action) => {
			state.packagesData = action.payload;
			state.loading = false;
			state.success = true;
		},
		[GetPackagesThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload;
		}
	}
});

export default GetPackagesSlice.reducer;

export const { ResetPackagesData } = GetPackagesSlice.actions;

export { GetPackagesThunk };
