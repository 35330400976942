import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetListingThunk = createAsyncThunk(
	"GetListing/GetListingThunk",
	async ({ category, listing_type }, { rejectWithValue }) => {
		try {
			const response = await axios.post(
				ApiEndpoints.GetListing,
				{
					category,
					listing_type
				},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("api_token")}`
					}
				}
			);
			return response.data;
		} catch (error) {
			console.log("error", error);
			return rejectWithValue(error.response.data);
		}
	}
);

const GetListingSlice = createSlice({
	name: "GetListing",
	initialState: {
		GetListingData: {},
		loading: false,
		success: false,
		error: false,
		errorMessage: ""
	},
	reducers: {
		ResetGetListingData: (state) => {
			state.GetListingData = {};
			state.error = false;
			state.success = false;
			state.loading = false;
		}
	},
	extraReducers: {
		[GetListingThunk.pending]: (state, action) => {
			state.loading = true;
			state.error = false;
			state.success = false;
		},
		[GetListingThunk.fulfilled]: (state, action) => {
			state.GetListingData = action.payload;
			state.loading = false;
			state.success = true;
		},
		[GetListingThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload;
		}
	}
});

export default GetListingSlice.reducer;

export const { ResetGetListingData } = GetListingSlice.actions;

export { GetListingThunk };
