import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../utils/ApiEndpoints";

const PaymentThunk = createAsyncThunk("Payment/PaymentThunk", async ({ package_id }, { rejectWithValue }) => {
	try {
		const response = await axios.post(
			ApiEndpoints.Payment,
			{
				package_id: package_id
			},
			{
				headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` }
			}
		);
		return response.data;
	} catch (error) {
		console.log("error", error);
		return rejectWithValue(error.response.data);
	}
});

const PaymentSlice = createSlice({
	name: "Payment",
	initialState: {
		PaymentData: {},
		loading: false,
		success: false,
		error: false,
		errorMessage: ""
	},
	reducers: {
		ResetPayment: (state) => {
			state.PaymentData = {};
			state.error = false;
			state.success = false;
			state.loading = false;
		}
	},
	extraReducers: {
		[PaymentThunk.pending]: (state, action) => {
			state.loading = true;
			state.error = false;
			state.success = false;
		},
		[PaymentThunk.fulfilled]: (state, action) => {
			state.PaymentData = action.payload;
			state.loading = false;
			state.success = true;
		},
		[PaymentThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload;
		}
	}
});

export default PaymentSlice.reducer;

export const { ResetPayment } = PaymentSlice.actions;

export { PaymentThunk };
