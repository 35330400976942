// const baseUrl = "http://192.168.100.12:3000/api/v1";
const baseUrl = "https://api.yellowish.co.za/api/v1";

export const ApiEndpoints = {
	Login: `${baseUrl}/auth/login`,
	Logout: `${baseUrl}/auth/logout`,
	Register: `${baseUrl}/auth/register`,
	ForgetPassword: `${baseUrl}/auth/reset-password`,
	ContactUs: `${baseUrl}/contact-us`,
	Advertisement: `${baseUrl}/user-query`,
	InsuranceQuote: `${baseUrl}/quote/insurance`,
	TransportQuote: `${baseUrl}/quote/transportation`,
	FinanceQuote: `${baseUrl}/quote/finance`,
	Subscription: `${baseUrl}/subscribe-news-letter`,
	GetProfile: `${baseUrl}/get-profile`,
	Category: `${baseUrl}/category/list`,
	GetPackages: `${baseUrl}/packages/list`,
	GetUserPackage: `${baseUrl}/packages/user-list`,
	AddListing: `${baseUrl}/product/selling/add`,
	GetListing: `${baseUrl}/product/list`,
	GetListingDetail: `${baseUrl}/product/details`,
	ListingEnquiry: `${baseUrl}/product/enquiry`,
	Payment: `${baseUrl}/payment`,
	DeleteListing: `${baseUrl}/product/delete`,
	GetAllAdvertisement: `${baseUrl}/advertisement/list`,
	RentalCategory: `${baseUrl}/category/list`,
	GetAllRentalProducts: `${baseUrl}/product/renting/list`,
	GetRentalProductDetail: `${baseUrl}/product/renting/details`,
	AddUserRentalProduct: `${baseUrl}/product/renting/add`,
	UpdateUserProfile: `${baseUrl}/update-profile`
};
