import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../utils/ApiEndpoints";

const GetAllAdvertisementThunk = createAsyncThunk(
    "GetAllAdvertisement/GetAllAdvertisementThunk",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(ApiEndpoints.GetAllAdvertisement);
            return response.data;
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error.response.data);
        }
    }
);

const GetAllAdvertisementSlice = createSlice({
    name: "GetAllAdvertisement",
    initialState: {
        allAdvertisementData: [],
        loading: false,
        success: false,
        error: false,
        errorMessage: ""
    },
    reducers: {
        ResetGetAllAdvertisementData: (state) => {
            state.allAdvertisementData = [];
            state.error = false;
            state.success = false;
            state.loading = false;
        }
    },
    extraReducers: {
        [GetAllAdvertisementThunk.pending]: (state, action) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        },
        [GetAllAdvertisementThunk.fulfilled]: (state, action) => {
            state.allAdvertisementData = action.payload;
            state.loading = false;
            state.success = true;
        },
        [GetAllAdvertisementThunk.rejected]: (state, action) => {
            state.loading = false;
            state.error = true;
            state.errorMessage = action.payload;
            state.allAdvertisementData = [];
        }
    }
});

export default GetAllAdvertisementSlice.reducer;

export const { ResetGetAllAdvertisementData } = GetAllAdvertisementSlice.actions;

export { GetAllAdvertisementThunk };
