import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../utils/ApiEndpoints";

const LoginThunk = createAsyncThunk("account/LoginThunk", async ({ email, password, type }, { rejectWithValue }) => {
	try {
		const response = await axios.post(ApiEndpoints.Login, {
			email,
			password
		});
		localStorage.setItem("api_token", response?.data?.data?.api_token);
		localStorage.setItem("refresh_token", response?.data?.data?.refresh_token);
		return response.data;
	} catch (error) {
		console.log("error", error);
		return rejectWithValue(error.response.data);
	}
});

const RegisterThunk = createAsyncThunk(
	"account/RegisterThunk",
	async ({ email, password, userName, phoneNumber }, { rejectWithValue }) => {
		try {
			const response = await axios.post(ApiEndpoints.Register, {
				email,
				password,
				username: userName,
				phone_number: phoneNumber
			});
			return response.data;
		} catch (error) {
			console.log("error", error);
			return rejectWithValue(error.response.data);
		}
	}
);
const ForgetPasswordThunk = createAsyncThunk("account/ForgetPasswordThunk", async ({ email }, { rejectWithValue }) => {
	try {
		const response = await axios.post(ApiEndpoints.ForgetPassword, {
			email
		});
		return response.data;
	} catch (error) {
		console.log("error", error);
		return rejectWithValue(error.response.data);
	}
});

const accountSlice = createSlice({
	name: "account",
	initialState: {
		accountData: [],
		success: false,
		error: false,
		loading: false,
		errorMessage: ""
	},
	reducers: {
		ResetAccountData: (state) => {
			state.accountData = [];
			state.success = false;
			state.error = false;
			state.loading = false;
			state.errorMessage = "";
		}
	},
	extraReducers: {
		[LoginThunk.pending]: (state, action) => {
			state.loading = true;
			state.success = false;
			state.error = false;
		},
		[LoginThunk.fulfilled]: (state, action) => {
			state.loading = false;
			state.success = true;
			state.accountData = action.payload;
		},
		[LoginThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload.message;
		},
		[RegisterThunk.pending]: (state, action) => {
			state.loading = true;
			state.success = false;
			state.error = false;
		},
		[RegisterThunk.fulfilled]: (state, action) => {
			state.loading = false;
			state.success = true;
			state.accountData = action.payload;
		},
		[RegisterThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload.message;
		},
		[ForgetPasswordThunk.pending]: (state, action) => {
			state.loading = true;
			state.success = false;
			state.error = false;
		},
		[ForgetPasswordThunk.fulfilled]: (state, action) => {
			state.loading = false;
			state.success = true;
			state.accountData = action.payload;
		},
		[ForgetPasswordThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload.message;
		}
	}
});

export default accountSlice.reducer;
export const { ResetAccountData } = accountSlice.actions;
export { LoginThunk, RegisterThunk, ForgetPasswordThunk };
