import { Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/userContext";
import { ResetTransportQuote, TransportQuoteThunk } from "../../redux/Quote/TransportQuoteSlice";
import { TransportQuoteValidationSchema } from "../../utils/FormValidationSchema";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import QuoteHeader from "../common/QuoteHeader";

const TransportQuote = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { loading, success, error, errorMessage } = useSelector((state) => state.quote.transportQuote);

	const { userInfo, isAuth, advertisementData } = useContext(UserContext);

	const TransportQuoteInitialValues = {
		name: userInfo?.username ? userInfo?.username : "",
		email: userInfo?.email ? userInfo?.email : "",
		deliveryPoint: "",
		pickupPoint: "",
		description: "",
		isMachinceRunning: false,
		addititonalDetail: ""
	};

	const handleLogin = () => {
		sessionStorage.setItem('previousPage', window.location.href);
		navigate("/my-account");
	}

	const handleSubmit = ({
		deliveryPoint,
		description,
		isMachineRunning,
		additionalDetail,
		name,
		email,
		pickupPoint
	}) => {
		dispatch(
			TransportQuoteThunk({
				name,
				email,
				description,
				machine_running_status: isMachineRunning === "Yes" ? true : false,
				delivery_point: deliveryPoint,
				additional_detail: additionalDetail,
				pickup_point: pickupPoint
			})
		);
	};

	if (success) {
		toast.success("Transport quote has been submitted successfully.");
		dispatch(ResetTransportQuote());
	}

	if (error) {
		toast.error(errorMessage);
		dispatch(ResetTransportQuote());
	}

	return (
		<div>
			<Navbar activeTransportQuote={true} />
			<QuoteHeader heading="TRANSPORT" description="transport" />
			<section class="finq1">
				<div class="finq1-ads">
					<div class="ads-container">
						<Link to="/Advertise">
							<div class="ads-box-2">
								<img class="w-100" src="/images/yellowish-ad-square.jpg" alt="" />
							</div>
						</Link>
						{advertisementData?.length !== 0 && (
							advertisementData?.map((homePageAds, index) => (
								homePageAds.name === "Transport Quote" &&
								homePageAds.Advertisements.map((ads) => {
									return (
										<a href={ads?.AdvertisementImages[0].ad_link} target="_blank">
											<div class="ads-box-2">
												<img class="w-100" src={ads?.AdvertisementImages[0].image} alt="" />
											</div>
										</a>
									)
								})
							))
						)}
						<div class="ads-box-2">
							<img class="w-100" src="/images/yellowish-Newsletter-Ad-Spot-3-600x600-1.jpg" alt="" />
						</div>
					</div>
				</div>
				<div class="finq1-text-area">
					<div class="text-box mb-20">
						<div class="text-box-box">
							<h3 class="text-box-heading">
								We will help you find the best price in your area. Complete the form below.
							</h3>
						</div>
					</div>
					<span style={{ textAlign: "start", justifyContent: "start", width: "100%" }}>
						{" "}
						"*" indicates required fields
					</span>
					<br />

					<Formik
						initialValues={TransportQuoteInitialValues}
						validationSchema={TransportQuoteValidationSchema}
						enableReinitialize={true}
						onSubmit={handleSubmit}
					>
						{({ errors, touched }) => (
							<Form className="row">
								<div>
									<label className="pl-0 mt-2 add-listing-label">Name *</label>
									<Field
										name="name"
										className="col-12 form-control"
										placeholder="Your First Name"
										height={65}
									/>
									<p className="input-info-text error-text-color">
										{errors.name && touched.name ? errors.name : ""}
									</p>
								</div>
								<div>
									<label className="pl-0 mt-2 add-listing-label">Email *</label>
									<Field
										name="email"
										className="col-12 form-control"
										placeholder="Your Best Email"
										height={65}
									/>
									<p className="input-info-text error-text-color">
										{errors.email && touched.email ? errors.email : ""}
									</p>
								</div>
								<div>
									<label className="pl-0 add-listing-label" htmlFor="pickupPoint">
										Pickup Point *
									</label>
									<Field
										id="pickupPoint"
										name="pickupPoint"
										className="col-12 form-control"
										placeholder="what is your Pickup Point?"
										height={65}
									/>
									<p className="input-info-text error-text-color">
										{errors.pickupPoint && touched.pickupPoint ? errors.pickupPoint : ""}
									</p>
								</div>
								<div>
									<label className="pl-0 add-listing-label" htmlFor="deliveryPoint">
										Delivery Point *
									</label>
									<Field
										id="deliveryPoint"
										name="deliveryPoint"
										className="col-12 form-control"
										placeholder="Where does it need to go?"
										height={65}
									/>
									<p className="input-info-text error-text-color">
										{errors.deliveryPoint && touched.deliveryPoint ? errors.deliveryPoint : ""}
									</p>
								</div>
								<div>
									<label className="pl-0 mt-2 add-listing-label">Description *</label>
									<Field
										name="description"
										className="col-12 form-control"
										placeholder="What is it? E.G. Grader etc"
										height={65}
									/>
									<p className="input-info-text error-text-color">
										{errors.description && touched.description ? errors.description : ""}
									</p>
								</div>
								<div>
									<label className="pl-0 mt-2 add-listing-label">
										Is your machine in running order? *
									</label>
									<div className="col-lg-3 pl-0 mt-2">
										<Field
											id="Yes"
											type="radio"
											name="isMachineRunning"
											className="justify-content-start"
											value={"Yes"}
										/>
										<label htmlFor="Yes" className="ml-2" style={{ textAlign: "end" }}>
											Yes
										</label>
									</div>
									<div className="row">
										<div className="col-lg-3 ">
											<Field type="radio" name="isMachineRunning" value={"No"} id="No" />
											<label htmlFor="No" className="ml-2">
												No
											</label>
										</div>
									</div>
								</div>
								<div className="mt-2 mb-">
									<label className=" pl-0  add-listing-label">Additional Details</label>
									<Field
										name="additionalDetail"
										as="textarea"
										rows="6"
										className="col-12 form-control"
										placeholder="Anything else we need to know?"
										height={65}
									/>
								</div>
								<div>
									<button
										className="cont1-button bg-yellow900 color-black900 border-yellow900 d-flex "
										style={{ justifyContent: "start", marginLeft: "0px" }}
										type="submit"
										disabled={loading}
									>
										SUBMIT
										<span
											className={loading ? "spinner-border spinner-border-sm" : ""}
											style={{ marginLeft: "5px" }}
										/>
									</button>
								</div>
							</Form>
						)}
					</Formik>

					{!isAuth && (
						<>
							<div class="text-box mb-20 mt-5">
								<div class="text-box-box">
									<h3 class="text-box-heading">
										Register for free and get a quick quote for finance, transport, and insurance.
									</h3>
								</div>
							</div>
							<p class="info">If you already have a Yellowish account, please login.</p>
							<button
								onClick={handleLogin}
								className="cont1-button bg-yellow900 color-black900 border-yellow900"
								type="submit"
							>
								CLICK HERE TO LOGIN OR REGISTER
							</button>
						</>
					)}
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default TransportQuote;
