import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetAllRentalProductsThunk = createAsyncThunk(
	"GetAllRentalProducts/GetAllRentalProductsThunk",
	async ( _ , { rejectWithValue }) => {
		try {
			const response = await axios.get(
				ApiEndpoints.GetAllRentalProducts, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("api_token")}`
					}
				}
			);
			return response.data;
		} catch (error) {
            console.log('error', error);
            const errorMessage = error?.response?.data?.message || 'Something went wrong';
            return rejectWithValue(errorMessage);
            }
	}
);

const GetAllRentalProductsSlice = createSlice({
	name: "GetAllRentalProducts",
	initialState: {
		GetAllRentalProductsData: {},
		loading: false,
		success: false,
		error: false,
		errorMessage: ""
	},
	reducers: {
		ResetGetAllRentalProductsData: (state) => {
			state.GetAllRentalProductsData = {};
			state.error = false;
			state.success = false;
			state.loading = false;
            state.errorMessage = "";
		}
	},
	extraReducers: {
		[GetAllRentalProductsThunk.pending]: (state, action) => {
			state.loading = true;
			state.error = false;
			state.success = false;
		},
		[GetAllRentalProductsThunk.fulfilled]: (state, action) => {
			state.GetAllRentalProductsData = action.payload;
			state.loading = false;
			state.success = true;
		},
		[GetAllRentalProductsThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload;
		}
	}
});

export default GetAllRentalProductsSlice.reducer;

export const { ResetGetAllRentalProductsData } = GetAllRentalProductsSlice.actions;

export { GetAllRentalProductsThunk };
