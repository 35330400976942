import { combineReducers } from "@reduxjs/toolkit";
import RentalCategorySlice from "./RentalCategorySlice";
import GetAllRentalProductsSlice from "./GetAllRentalProductsSlice";
import AddRentalProductSlice from "./AddRentalProductSlice";
import GetRentalProductDetailSlice from "./GetRentalProductDetailSlice";

export const RentalsReducers = combineReducers({
	RentalCategory: RentalCategorySlice,
	GetAllRentalProduct: GetAllRentalProductsSlice,
	AddRentalProduct: AddRentalProductSlice,
	RentalProductDetail: GetRentalProductDetailSlice
});
