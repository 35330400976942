import React from "react";
import { Link } from "react-router-dom";

const Navbar = ({
	activeHome = false,
	activeBuying = false,
	activeSelling = false,
	activateRentals = false,
	activeContact = false,
	activeAccount = false,
	activeTransportQuote = false,
	activeFinanceQuote = false,
	activeInsuranceQuote = false
}) => {
	function myFunction() {
		var x = document.getElementById("topNav");
		if (x.className === "header container-fluid") {
			x.className += " responsive";
		} else {
			x.className = "header container-fluid";
		}
	}
	return (
		<header className="header container-fluid" id="topNav">
			<div className="container-width">
				<div className="navbar hide-on-mobile">
					<div className="logo-width">
						<div className="header-logo-container">
							<Link to="/">
								<img className="logo" src="/images/yellowish-logo-whitetext.png" alt="" />
							</Link>
						</div>
					</div>
					<div className="links-width">
						<div className="">
							<div className="header-padding">
								<Link to="/" className={`link ${activeHome ? "active-link" : ""}`}>
									Home
								</Link>
								<Link to="/Buying" className={`link ${activeBuying ? "active-link" : ""}`}>
									Buying
								</Link>
								<Link to="/Selling" className={`link ${activeSelling ? "active-link" : ""}`}>
									Selling
								</Link>
								<Link to="/Rental/2/0" className={`link ${activateRentals ? "active-link" : ""}`}>
									Rentals
								</Link>
								<Link className={`link ${activeFinanceQuote ? "active-link" : ""}`} to="/FinanceQuote">
									Finance Quote
								</Link>
								<Link
									className={`link ${activeInsuranceQuote ? "active-link" : ""}`}
									to="/InsuranceQuote"
								>
									Insurance Quote
								</Link>
								<Link
									className={`link ${activeTransportQuote ? "active-link" : ""}`}
									to="/TransportQuote"
								>
									Transport Quote
								</Link>

								{/* <div className="dropdown position-relative">
										<Link to="/Quote" className={`link ${activeQuote ? "active-link" : ""}`}>
											Quote
										</Link>
										<div className="content">
											<Link className="other" to="/FinanceQuote">
												Finance Quote
											</Link>
											<Link className="other" to="/InsuranceQuote">
												Insurance Quote
											</Link>
											<Link className="other" to="/TransportQuote">
												Transport Quote
											</Link>
										</div>
									</div> */}
								<Link to="/ContactUs" className={`link ${activeContact ? "active-link" : ""}`}>
									Contact
								</Link>
								<div className="login">
									<Link to="/my-account" className={`link ${activeAccount ? "active-link" : ""}`}>
										Login / Register
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col-3"></div>
				</div>
			</div>
			<div className="row show-on-mobile">
				<div className="navbar">
					<div className="col-8 show-on-mobile">
						<div className="header-logo-container">
							<Link to="/">
								<img className="logo" src="/images/yellowish-logo-whitetext.png" alt="" />
							</Link>
						</div>
					</div>
					<div className="col-12 special-class no-padding">
						<div className="header-links">
							<div className="header-padding">
								<Link to="/" className={`link-class ${activeHome && "active-link"}`}>
									Home
								</Link>
								<Link to="/Buying" className={`link-class ${activeBuying ? "active-link" : ""}`}>
									Buying
								</Link>
								<Link to="/Selling" className={`link-class ${activeSelling && "active-link"}`}>
									Selling
								</Link>
								<Link to="/Rental/2/0" className={`link-class ${activateRentals && "active-link"}`}>
									Rentals
								</Link>
								<Link
									to="/FinanceQuote"
									className={`link-class ${activeFinanceQuote && "active-link"}`}
								>
									Finance Quote
								</Link>
								<Link
									to="/InsuranceQuote"
									className={`link-class ${activeInsuranceQuote && "active-link"}`}
								>
									Insurance Quote
								</Link>
								<Link
									to="/TransportQuote"
									className={`link-class ${activeTransportQuote && "active-link"}`}
								>
									Transport Quote
								</Link>
								<Link to="/ContactUs" className={`link-class ${activeContact && "active-link"}`}>
									Contact
								</Link>
								<Link to="/my-account" className={`link-class ${activeAccount && "active-link"}`}>
									Login / Register
								</Link>
							</div>
						</div>
					</div>
					<div className="col-4 flex-end">
						<Link to="" className="icon">
							<img onClick={myFunction} style={{ width: "20px" }} src="/images/menu.png" alt="menu" />
						</Link>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Navbar;
