import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const AddRentalProductThunk = createAsyncThunk(
	"AddRentalProduct/AddRentalProductThunk",
	async ({ formValues, editor, images, dealerImage, price }, { rejectWithValue }) => {
		const formData = new FormData();
		formData.append("title", formValues.title);
		formData.append("description", editor !== "" ? editor : "");
		formData.append("price", price);
		formData.append("year", formValues.year);
		formData.append("make", formValues.make);
		formData.append("model", formValues.model);
		formData.append("machine_type", formValues.machine_type);
		formData.append("hours", formValues.hours);
		formData.append("mileage", formValues.mileage);
		formData.append("service_history", formValues.service_history);
		formData.append("serial_number", formValues.serial_number);
		// formData.append("fuel_type", formValues.fuel_type);
		// formData.append("engine_type", formValues.engine_type);
		// formData.append("engine_max_power", formValues.engine_max_power);
		// formData.append("engine_max_torque", formValues.engine_max_torque);
		formData.append("seller_logo", dealerImage);
		formData.append("package_id", Number(formValues.RentalProduct_package));

		for (let i = 0; i < images.length; i++) {
			formData.append("products", images[i].file);
		}

		try {
			const response = await axios.post(ApiEndpoints.AddUserRentalProduct, formData, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("api_token")}`,
					"Content-Type": "multipart/form-data"
				}
			});
			return response.data;
		} catch (error) {
			console.log("error", error);
			return rejectWithValue(error.response.data);
		}
	}
);

const AddRentalProductSlice = createSlice({
	name: "AddRentalProduct",
	initialState: {
		AddRentalProductData: {},
		loading: false,
		success: false,
		error: false,
		errorMessage: ""
	},
	reducers: {
		ResetAddRentalProductData: (state) => {
			state.AddRentalProductData = {};
			state.error = false;
			state.success = false;
			state.loading = false;
		}
	},
	extraReducers: {
		[AddRentalProductThunk.pending]: (state, action) => {
			state.loading = true;
			state.error = false;
			state.success = false;
		},
		[AddRentalProductThunk.fulfilled]: (state, action) => {
			state.AddRentalProductData = action.payload;
			state.loading = false;
			state.success = true;
		},
		[AddRentalProductThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload.message;
		}
	}
});

export default AddRentalProductSlice.reducer;

export const { ResetAddRentalProductData } = AddRentalProductSlice.actions;

export { AddRentalProductThunk };
