import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const ListingEnquiryThunk = createAsyncThunk(
	"ListingEnquiry/ListingEnquiryThunk",
	async ({ name, email, phone, description, product_id }, { rejectWithValue }) => {
		try {
			const response = await axios.post(
				ApiEndpoints.ListingEnquiry,
				{
					name,
					email,
					phone,
					description: description === "" ? "-" : description,
					product_id
				},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("api_token")}`
					}
				}
			);
			return response.data;
		} catch (error) {
			console.log("error", error);
			return rejectWithValue(error.response.data);
		}
	}
);

const ListingEnquirySlice = createSlice({
	name: "ListingEnquiry",
	initialState: {
		LisitngEnquiryData: {},
		loading: false,
		success: false,
		error: false,
		errorMessage: ""
	},
	reducers: {
		ResetListingEnquiryData: (state) => {
			state.LisitngEnquiryData = {};
			state.error = false;
			state.success = false;
			state.loading = false;
		}
	},
	extraReducers: {
		[ListingEnquiryThunk.pending]: (state, action) => {
			state.loading = true;
			state.error = false;
			state.success = false;
		},
		[ListingEnquiryThunk.fulfilled]: (state, action) => {
			state.LisitngEnquiryData = action.payload;
			state.loading = false;
			state.success = true;
		},
		[ListingEnquiryThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload.message;
		}
	}
});

export default ListingEnquirySlice.reducer;

export const { ResetListingEnquiryData } = ListingEnquirySlice.actions;

export { ListingEnquiryThunk };
