import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const DeleteListingThunk = createAsyncThunk(
    "DeleteListing/DeleteListingThunk",
    async ({ product_id }, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${ApiEndpoints.DeleteListing}?product_id=${product_id}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("api_token")}`
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error.response.data);
        }
    }
);

const DeleteListingSlice = createSlice({
    name: "DeleteListing",
    initialState: {
        deleteListingData: {},
        loading: false,
        success: false,
        error: false,
        errorMessage: ""
    },
    reducers: {
        ResetDeleteListingData: (state) => {
            state.deleteListingData = {};
            state.error = false;
            state.success = false;
            state.loading = false;
        }
    },
    extraReducers: {
        [DeleteListingThunk.pending]: (state, action) => {
            state.loading = true;
            state.error = false;
            state.success = false;
        },
        [DeleteListingThunk.fulfilled]: (state, action) => {
            state.deleteListingData = action.payload;
            state.loading = false;
            state.success = true;
        },
        [DeleteListingThunk.rejected]: (state, action) => {
            state.loading = false;
            state.error = true;
            state.errorMessage = action.payload.message;
        }
    }
});

export default DeleteListingSlice.reducer;

export const { ResetDeleteListingData } = DeleteListingSlice.actions;

export { DeleteListingThunk };
