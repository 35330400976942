import React from "react";

function Specifications({ data }) {
	return (
		<div className="ds-tab">
			<h4 className="ds-heading">Details</h4>

			<table className="table table-striped">
				{Object.keys(data).length !== 0}
				<tbody>

					<tr>
						<th>Make</th>
						<td>{data?.make}</td>
					</tr>

					<tr>
						<th>Model</th>
						<td>{data?.model}</td>
					</tr>
					<tr>
						<th>Year</th>
						<td>{data?.year}</td>
					</tr>

					<tr>
						<th>Machine Type</th>
						<td>{data?.machine_type}</td>
					</tr>

					<tr>
						<th>Machine Location</th>
						<td>{data?.machine_location}</td>
					</tr>

					<tr>
						<th>Hours</th>
						<td>{data?.hours}</td>
					</tr>

					<tr>
						<th>Mileage</th>
						<td>{data?.mileage}</td>
					</tr>

					<tr>
						<th>Service History</th>
						<td>{data?.service_history}</td>
					</tr>

					{/* <tr>
						<th>Fuel Type</th>
						<td>{data?.fuel_type}</td>
					</tr> */}
					<tr>
						<th>Additional Details</th>
						<td>{data?.description}</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}
export default Specifications;
