import { Field, Form, Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { ForgetPasswordThunk, ResetAccountData } from "../../redux/AccountSlice";
import { ForgetValidationSchema } from "../../utils/FormValidationSchema";
import { ForgetInitialValues } from "../../utils/FormValues";

const ForgetForm = ({ setOpenTab }) => {
	const dispatch = useDispatch();
	const { loading, success, error } = useSelector((state) => state.account);

	const handleResetPassword = ({ email }) => {
		dispatch(ForgetPasswordThunk({ email }));
	};

	if (success) {
		toast.success("New Password has been to your email.");
		dispatch(ResetAccountData());
	}

	if (error) {
		dispatch(ResetAccountData());
		toast.error("Invalid email");
	}
	return (
		<div>
			<div id="Forget" className="tabcontent">
				<div className="col-lg-12 no-padding">
					<h1 className="form-head color-black900">Forgot Your Password?</h1>
					<p className="form-para color-black800">
						Please enter your Username or Email address to teset your password
					</p>
					<Formik
						enableReinitialize={true}
						initialValues={ForgetInitialValues}
						validationSchema={ForgetValidationSchema}
						onSubmit={handleResetPassword}
					>
						{({ errors, touched }) => (
							<Form>
								<div className="col-lg-12 no-padding">
									<label className="form-para form-inline color-black800" for="email">
										Username or Email
									</label>
									<Field className="form-input" type="text and email" name="email" required />
									<br />
								</div>

								<button className="form-bt2 color-black900" type="submit">
									Reset Password
									<span
										className={loading ? "spinner-border spinner-border-sm" : ""}
										style={{ marginLeft: "5px" }}
									/>
								</button>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default ForgetForm;
