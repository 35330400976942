import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiEndpoints } from "../../utils/ApiEndpoints";
import axios from "axios";

const InsuranceQuoteThunk = createAsyncThunk(
	"InsuranceQuote/InsuranceQuoteThunk",
	async ({ name, email, phone, description }, { rejectWithValue }) => {
		try {
			const response = await axios.post(
				ApiEndpoints.InsuranceQuote,
				{
					name,
					email,
					phone,
					description
				},
				{
					headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` }
				}
			);
			return response.data;
		} catch (error) {
			console.log("error", error);
			return rejectWithValue(error.response.data);
		}
	}
);

const InsuranceQuoteSlice = createSlice({
	name: "InsuranceQuote",
	initialState: {
		insuranceQuoteData: [],
		success: false,
		error: false,
		loading: false,
		errorMessage: ""
	},
	reducers: {
		ResetInsuranceQuote: (state) => {
			state.insuranceQuoteData = [];
			state.success = false;
			state.error = false;
			state.loading = false;
			state.errorMessage = "";
		}
	},
	extraReducers: {
		[InsuranceQuoteThunk.pending]: (state, action) => {
			state.loading = true;
			state.success = false;
			state.error = false;
		},
		[InsuranceQuoteThunk.fulfilled]: (state, action) => {
			state.loading = false;
			state.success = true;
			state.insuranceQuoteData = action.payload;
		},
		[InsuranceQuoteThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload.message;
		}
	}
});

export default InsuranceQuoteSlice.reducer;
export const { ResetInsuranceQuote } = InsuranceQuoteSlice.actions;
export { InsuranceQuoteThunk };
