import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetUserPackageThunk = createAsyncThunk("GetUserPackage/GetUserPackageThunk", async (_, { rejectWithValue }) => {
	try {
		const response = await axios.get(ApiEndpoints.GetUserPackage, {
			headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` }
		});
		return response.data.message;
	} catch (error) {
		console.log("error", error);
		return rejectWithValue(error.response.data);
	}
});

const GetUserPackageSlice = createSlice({
	name: "GetUserPackage",
	initialState: {
		UserPackageData: {},
		loading: false,
		success: false,
		error: false,
		errorMessage: ""
	},
	reducers: {
		ResetGetUserPackage: (state) => {
			state.UserPackageData = {};
			state.error = false;
			state.success = false;
			state.loading = false;
		}
	},
	extraReducers: {
		[GetUserPackageThunk.pending]: (state, action) => {
			state.loading = true;
			state.error = false;
			state.success = false;
		},
		[GetUserPackageThunk.fulfilled]: (state, action) => {
			state.UserPackageData = action.payload;
			state.loading = false;
			state.success = true;
		},
		[GetUserPackageThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload;
		}
	}
});

export default GetUserPackageSlice.reducer;

export const { ResetGetUserPackage } = GetUserPackageSlice.actions;

export { GetUserPackageThunk };
