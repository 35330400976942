import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LogoutThunk, ResetLogout } from "../redux/Account/LogoutSlice";
import { LoginThunk, ResetAccountData } from "../redux/AccountSlice";
import { GetAllAdvertisementThunk, ResetGetAllAdvertisementData } from "../redux/GetAllAdvertisementSlice";
import { GetProfileThunk, ResetProfileData } from "../redux/GetProfileSlice";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
	const dispatch = useDispatch();
	const [userInfo, setUserInfo] = useState(null);
	const [advertisementData, setAdvertisementData] = useState([]);
	const [isAuth, setIsAuth] = useState(false);

	const {
		success: userProfileDataSuccess,
		error: userProfileDataError,
		userProfileData
	} = useSelector((state) => state.getUserProfile);
	const { success: logoutSuccess } = useSelector((state) => state.AccountState.Logout);
	const { success: loginSuccess } = useSelector((state) => state.account);
	const { success: advertisementSuccess, allAdvertisementData } = useSelector((state) => state.getAdvertisement);

	useEffect(() => {
		if (userProfileDataSuccess) {
			setUserInfo(userProfileData?.data);
			setIsAuth(true);
			dispatch(ResetAccountData());
		}
	}, [userProfileDataSuccess, userProfileData, dispatch]);

	useEffect(() => {
		setUserInfo({});
		setIsAuth(false);
		dispatch(ResetLogout());
		dispatch(ResetProfileData());
	}, [userProfileDataError, dispatch]);

	useEffect(() => {
		setUserInfo({});
		setIsAuth(false);
		dispatch(ResetLogout());
		dispatch(ResetProfileData());
	}, [logoutSuccess, dispatch]);

	useEffect(() => {
		dispatch(GetProfileThunk());
	}, [dispatch, loginSuccess]);

	useEffect(() => {
		dispatch(GetProfileThunk());
	}, [dispatch, loginSuccess]);

	useEffect(() => {
		if (advertisementSuccess)
			setAdvertisementData(allAdvertisementData?.data);
		dispatch(ResetGetAllAdvertisementData());
	}, [dispatch, advertisementSuccess]);

	useEffect(() => {
		dispatch(GetAllAdvertisementThunk());
	}, [dispatch])

	const login = ({ email, password }) => {
		dispatch(LoginThunk({ email, password }));
	};

	const logout = () => {
		dispatch(LogoutThunk());
	};

	const value = { userInfo, setUserInfo, isAuth, setIsAuth, login, logout, advertisementData };

	return <UserContext.Provider value={value}> {children} </UserContext.Provider>;
};
