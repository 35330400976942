import React, { useState } from "react";
import { Link } from "react-router-dom";
import { facebookIcon, instagramIcon, linkedinIcon, rightcheck } from "../../assets/images";
import SubscriptionModal from "./SubscriptionModal";

const Footer = () => {
	const [openModal, setOpenModal] = useState(false);
	return (
		<footer className="footer-main">
			<div className="container">
				<div className="row">
					<div className="col-lg-3 no-padding">
						<Link to="/">
							<img
								className="d-flex footer-logo"
								src={`${process.env.PUBLIC_URL}/images/yellowish-logo-whitetext.png`}
								alt=""
							/>
						</Link>
						<h1 className="footer-heading">
							BUY, SELL, RENT, GET A QUOTE <br />
							AND ADVERTISE
						</h1>
						<div className="hide-on-mobile">
							<a href="https://www.facebook.com/Yellowish.io" target="_blank">
								<img className="footer-img" src={facebookIcon} alt="facebook-icon" />
							</a>
							<a href="https://www.linkedin.com/company/yellowish-io/" target="_blank">
								<img className="footer-img" src={linkedinIcon} alt="linkedin-icon" />
							</a>
							<a href="https://www.instagram.com/yellowish.io/" target="_blank">
								<img className="footer-img" src={instagramIcon} alt="instagram-icon" />
							</a>
						</div>
					</div>
					<div className="col-lg-1"></div>
					<div className="col-lg-2 special-width">
						<div className="links-main">
							<div className="mb-20">
								<h1 className="d-flex links-heading color-yellow900">Navigation</h1>
							</div>
							<div className="links-box">
								<img className="chevron-right" src={rightcheck} alt="" />
								<Link to="/Buying" className="links-tab">
									<h2 className="links color-white">Buying</h2>
								</Link>
							</div>
							<div className="links-box">
								<img className="chevron-right" src={rightcheck} alt="" />
								<Link to="/Selling" className="links-tab">
									<h2 className="links color-white">Selling</h2>
								</Link>
							</div>
							<div className="links-box">
								<img className="chevron-right" src={rightcheck} alt="" />
								<Link to="/Rental" className="links-tab">
									<h2 className="links color-white">Rentals</h2>
								</Link>
							</div>
							{/* <div className="links-box">
								<img className="chevron-right" src={rightcheck} alt="" />
								<Link to="/" className="links-tab">
									<h2 className="links color-white">Dealers</h2>
								</Link>
							</div> */}
							<div className="links-box">
								<img className="chevron-right" src={rightcheck} alt="" />
								<Link to="/ContactUs" className="links-tab">
									<h2 className="links color-white">Contact Us</h2>
								</Link>
							</div>
						</div>
					</div>
					<div className="col-lg-2 special-width">
						<div className="links-main">
							<div className="mb-20">
								<h1 className="d-flex links-heading color-yellow900">Quick Links</h1>
							</div>
							<div className="links-box">
								<img className="chevron-right" src={rightcheck} alt="" />
								<Link to="/InsuranceQuote" className="links-tab">
									<h2 className="links color-white">Insurance</h2>
								</Link>
							</div>
							<div className="links-box">
								<img className="chevron-right" src={rightcheck} alt="" />
								<Link to="/TransportQuote" className="links-tab">
									<h2 className="links color-white">Transport</h2>
								</Link>
							</div>
							<div className="links-box">
								<img className="chevron-right" src={rightcheck} alt="" />
								<Link to="/FinanceQuote" className="links-tab">
									<h2 className="links color-white">Finance</h2>
								</Link>
							</div>
							<div className="links-box">
								<img className="chevron-right" src={rightcheck} alt="" />
								<Link to="/policies-and-terms-conditions" className="links-tab">
									<h2 className="links color-white">Policies and T&Cs</h2>
								</Link>
							</div>
						</div>
					</div>
					<div className="col-lg-1"></div>
					<div className="col-lg-3">
						<div className="links-main">
							<div className="mb-20">
								<h1 className=" links-heading color-yellow900 newsletter">
									SUBSCRIBE TO OUR NEWSLETTER
								</h1>
								<button
									className="subscribe-btn bg-black900 color-yellow900 border-yellow900"
									onClick={() => setOpenModal(!openModal)}
								>
									Subscribe Now
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<div className="copyright-box">
							<p className="copyright-text color-black700">
								Copyright © Yellowish Group (Pty) Ltd | 2022, all rights reserved.
							</p>
						</div>
					</div>
				</div>
			</div>
			{openModal && <SubscriptionModal openModal={openModal} setOpenModal={setOpenModal} />}
		</footer>
	);
};

export default Footer;
