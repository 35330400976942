import React from "react";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import PolicyHeader from "./PolicyHeader";

const TermsOfService = () => {
	return (
		<div>
			<Navbar />
			<PolicyHeader heading="Terms Of Service" />

			<section className="container ">
				<div className="m-3 ">
					<div class="elementor-widget-text-editor">
						<p>
							Welcome to the website operated by The Yellowish Group (Pty) Ltd – Company Registration
							Number 2017/131639/07. Our website is Yellowish.io and from now on referred to as
							(“Yellowish.io,” “we,” “us,” or “our”). This Website (the “Yellowish.io “Website”) provides
							services, and you use our services when you (as the “customer” also referred to as “user”)
							visit and use any of the services we provide (whether you are an individual or a business),
							and irrespective of whether it is on our website, applications, instant messaging services,
							administrative tools to order our goods and services, no matter how you accessed them.&nbsp;
							All users of the Yellowish.io Website are subject to the following terms and conditions of
							service (these “Terms of Service”).
						</p>
						<p>
							Please read these Terms of Service carefully before accessing or using any part of this
							Website. By accessing or using this Website, you agree that you have read, understand, and
							agree to be bound by these Terms of Service, as amended from time to time, and the
							Yellowish.io Privacy Policy, which is a result of this incorporated into these Terms of
							Service. If you do not wish to agree to these Terms of Service, do not access or use any
							part of this Website.
						</p>
						<p>
							We may revise and update these Terms of Service by posting the amended terms on this
							Website. Your continued use of the Yellowish.io Website means that you accept and agree to
							the revised Terms of Service. If you disagree with the Terms of Service (as amended from
							time to time) or are dissatisfied with this Website, your sole and exclusive remedy is to
							discontinue using this Website. The most current version of these Terms of Service, which
							supersedes all previous understandings, can be reviewed by clicking on the “Terms of
							Service” hyperlink.
						</p>
						<p>
							<strong>USE OF THE WEBSITE </strong>
						</p>
						<p>
							The Yellowish.io website and services are entirely voluntary, and you indemnify and hold us,
							our successors and assigns, and our respective officers, directors, employees, servants, and
							agents harmless from all claims. Losses, liabilities, suits, judgments, expenses, damages
							including consequential damages, penalties, fines, or indemnity payments of whatsoever kind
							and nature arising from or in any way connected with the use of the website and services and
							/or info and contained therein.
						</p>
						<p>
							Furthermore, we do not take responsibility for the content, products, and services
							advertised by the advertisers on the Website or Services.
						</p>
						<p>
							<strong>THE AGREEMENT</strong>
						</p>
						<p>The agreement consists of the following terms of service.</p>
						<ul>
							<li>
								<strong>DEFINITIONS</strong>
							</li>
						</ul>
						<p>In the agreement:</p>
						<p>
							<strong>‘advertiser’</strong> means any person, whether an individual or a business, which
							pays the Service Provider for the display of any marketing material, regardless of whether
							such marketing material is related to the services of this website or not, on this website.
						</p>
						<p>
							<strong>‘agreement’ </strong>means these terms and conditions, the privacy policy, the
							advertising policy, and any annexures hereto.
						</p>
						<p>
							<strong>‘buyer’ </strong>means any person, whether an individual or a business, making an
							offer for and purchasing an item listed for sale by any seller on this website.
						</p>
						<p>
							‘<strong>business day’</strong> means any day other than a Saturday, Sunday, or holiday
							(including a public or bank holiday) in the Republic Of South Africa.
						</p>
						<p>
							‘<strong>business hours’</strong> <strong>or ‘office hours’</strong> means our regular
							business hours on a business days
						</p>
						<p>
							<strong>‘day’</strong> means a day counted from midnight to midnight, including all days of
							the month, Saturdays, Sundays, and public holidays.
						</p>
						<p>
							<strong>‘fees’ </strong>means a charge, mark-up, and commission charged by the Service
							Provider to purchase and sell any item listed on this website.
						</p>
						<p>
							<strong>‘sign’</strong> means the handwritten signature or an electronic signature that the
							parties agree to use of each of the parties’ duly authorized representatives
						</p>
						<p>
							<strong>‘we,’, ‘us,’ or ‘our’</strong> means the service provider
						</p>
						<p>
							<strong>‘writing’</strong> means the reproduction of information or data in physical form or
							any mode of reproducing information or data in electronic form that the parties agree to use
							but excludes information or data in the form of email
						</p>
						<ul>
							<li>
								<strong>INTERPRETATION</strong>
							</li>
						</ul>
						<p>The following rules apply to the interpretation of the agreement:</p>
						<ul>
							<li>
								All words and phrases contained in this Agreement shall, as far as possible and with the
								exceptions specifically defined herein, have the usual dictionary definition assigned to
								them, unless such definition would be inconsistent with the context within which the
								word and/or phrase is used, or would result in absurdity or ambiguity.
							</li>
							<li>
								Where a word and phrase are used in a context that requires a technical definition to be
								ascribed to such a word and/or phrase, and the same is not specifically defined in this
								Agreement, such technical definition shall be assigned to the word and/or phrase as far
								as possible.
							</li>
							<li>
								Any interpretation of this Agreement must promote the validity, rather than the
								invalidity, thereof
							</li>
							<li>
								Where any term, condition, and clause of this Agreement has more than one possible
								interpretation, the interpretation favouring the Service Provider must be used.
							</li>
							<li>
								The <em>contra proferentem</em> rule does not find application in terms of this
								Agreement.
							</li>
							<li>
								Where any part of this Agreement becomes illegal, unenforceable, and{" "}
								<em>contra bonos</em> <em>mores</em> such part must as far as possible be severed from
								this Agreement to promote the validity and continuity of the remainder of this
								Agreement.
							</li>
							<li>
								Should any part of this Agreement be severed as per above, that portion of the Agreement
								will be governed by the prescripts of the law, or the <em>naturalia</em>, as to ensure
								the validity and the continuity of the remained of this Agreement.
							</li>
							<li>
								Unless the context indicates otherwise, this Agreement encapsulates and incorporates all
								other Agreements between the Third Party and the Service Provider.
							</li>
							<li>
								In the event of any conflicting provisions between this Agreement and any other
								agreement between the Third Party and the Service Provider, the provisions of this
								Agreement shall take preference.
							</li>
							<li>
								The provisions of the documents comprising this Agreement, as defined in Clause 1 above,
								must as far as possible be construed and interpreted to promote the validity of all such
								records.
							</li>
							<li>
								Where either the Privacy Policy and the Advertising Policy contains a provision that
								conflicts with another provision contained in these Terms and Conditions, preference
								must be given first to the provision in the Privacy Policy and the Advertising Policy.
							</li>
							<li>
								No interpretation against the draftsman – the rule of construction that an agreement
								must be interpreted against the party responsible for its drafting or preparation does
								not apply
								<br />
							</li>
							<li>
								Number of days – when any number of days is prescribed, the first day will be excluded
								and the last day included
							</li>
						</ul>
						<p>Other: –</p>
						<ul>
							<li>
								Non-exhaustive lists – whenever a clause lists specific examples or items following a
								listing word, such as ‘including,’ ‘includes,’ ‘excluding,’ or ‘excludes,’ it will not
								limit its scope
							</li>
							<li>
								References to enactments – references to any legislation include it as re-enacted,
								amended, or extended
							</li>
							<li>References to people – references to a person include a natural and juristic person</li>
							<li>
								References to parties – references to a party include their successors or permitted
								assigns
							</li>
						</ul>
						<p>
							<strong>1.3 DEPARTURE</strong>
						</p>
						<p>
							These terms apply to all our customers and are not generally open to negotiation for
							consistency reasons. Should the parties negotiate the nature of these terms, they will
							record that departure in the relevant order or other specific terms.
						</p>
						<ol start="2">
							<li>
								<strong> DURATION</strong>
							</li>
						</ol>
						<p>
							<strong>2.1 COMMENCEMENT</strong>
						</p>
						<p>These terms start whenever you accept them by:</p>
						<ul>
							<li>
								Explicitly – such as by checking a checkbox saying that you do or are agreeing to an
								order that incorporates them by reference
							</li>
							<li>
								Using the Website or Services in any way – such as by accessing them or exercising any
								rights granted to you under any agreements, we have between us
							</li>
						</ul>
						<p>
							<strong>2.2 AUTOMATIC RENEWAL</strong>
						</p>
						<p>
							If an order involves a subscription, the agreement will continue automatically from the end
							of the initial term or subsequent automatic renewal period for an automatic renewal period
							equivalent to the initial term.
						</p>
						<p>
							<strong>2.3 RENEWAL TERMINATION</strong>
						</p>
						<p>
							Either party may terminate the agreement before the end of the initial term or subsequent
							automatic renewal period by giving the other party at least 30 calendar days prior written
							notice.
						</p>
						<ol start="3">
							<li>
								<strong> ORDERS</strong>
							</li>
						</ol>
						<p>
							<strong>3.1 PLACING ORDERS</strong>
						</p>
						<p>
							You place orders with us whenever you order or start using the Website or Services. These
							orders are offered to us to buy access to our Website and Services.
						</p>
						<p>
							<strong>3.2 CAPACITY AND AUTHORITY</strong>
						</p>
						<p>
							You promise that you have the legal capacity and authority to agree to our terms of
							conducting business.
						</p>
						<p>
							<strong>3.3 INVITATION TO DO BUSINESS</strong>
						</p>
						<p>
							Marketing is merely an invitation to do business, and we only agree when we provide the
							services to you. This happens when we accept your offer.
						</p>
						<p>
							<strong>3.3.1 INSURANCE QUOTES</strong>
						</p>
						<p>
							All quotes generated on this Website are based on the information you provided and, in some
							cases, the information we obtain from third parties. Quotations do not constitute a contract
							or an invitation to contract or a binder or agreement to extend, continue or renew insurance
							coverage. The coverage descriptions provided on this Website are general descriptions of
							potentially available insurance coverage products and services and are not a statement of
							contract or an invitation to contract. To obtain the insurance coverage, you must complete
							all the steps on this Website through the final application through this Website, a mobile
							app, or otherwise. Applications are subject to underwriting review and approval.
						</p>
						<p>
							<strong>3.4 CANCELLATIONS</strong>
						</p>
						<p>
							We may cancel any order, but we will refund any money you have paid for that order if we do.
						</p>
						<p>
							<strong>3.5 TIME AND PLACE</strong>
						</p>
						<p>We agree when we accept the order and where we are domiciled when we do.</p>
						<p>
							<strong>3.6 AGREEMENTS</strong>
						</p>
						<p>
							Each order is a separate agreement, but you are deemed to have breached all of them if you
							breach one.
						</p>
						<p>
							<strong>3.7 CREDIT CARD SECURITY</strong>
						</p>
						<p>
							Credit card transactions will be acquired for the Service Provider via a payment gateway,
							which uses the strictest form of encryption, namely Secure Socket Layer 3 (SSL3). No credit
							card details are stored on the website. Users may go to&nbsp;the payment gateway’s website
							to view their security certificate, and security policy decided upon request.
						</p>
						<p>
							<strong>3.8 TRANSACTION CURRENCY</strong>
						</p>
						<p>
							The payment gateway at the time of presenting payment options to the cardholder is the
							Republic of South Africa. Transaction Currency is South African Rand (ZAR).
						</p>
						<ol start="4">
							<li>
								<strong> SERVICES</strong>
							</li>
						</ol>
						<p>
							<strong>4.1 RIGHTS</strong>
						</p>
						<p>We grant you a right to use the services subject to the following limitations:</p>
						<ul>
							<li>
								<strong>duration of the agreement</strong> – you may only use the services for the time
								of the agreement
							</li>
							<li>
								<strong>limited to terms</strong> – you may only use the services according to these
								terms
							</li>
							<li>
								<strong>non-exclusive</strong> – we may allow anyone else to use the services
							</li>
							<li>
								<strong>non-transferable</strong> – you may not transfer the right to anyone else
							</li>
							<li>
								<strong>specified purposes</strong> – you may only use the services for the specified
								purposes&nbsp;&nbsp; that we’ve communicated to you in writing from time to time
							</li>
							<li>
								<strong>any other limitations</strong> agreed between the parties in writing
							</li>
						</ul>
						<p>
							<strong>4.2 BREACH</strong>
						</p>
						<p>We may cancel your rights if you breach the agreement.</p>
						<ol start="5">
							<li>
								<strong> ONLINE SERVICES</strong>
							</li>
						</ol>
						<p>
							<strong>5.1 BASIS</strong>
						</p>
						<p>We provide the online services to you on the following basis:</p>
						<p>-you permit us to monitor how you use them for security and stability purposes</p>
						<p>-you agree that our records are undisputed evidence of the services provided to you</p>
						<p>
							<strong>5.2 ACCESS CONDITIONS</strong>
							<strong>&nbsp;</strong>
						</p>
						<p>
							We will only provide online service access to you or your authorized users (where you are a
							business) on the conditions that you or each one of them will:
						</p>
						<ul>
							<li>
								accurately provide us with any information that we ask for on registration or account
								creation
							</li>
							<li>
								create or have the necessary credentials (such as a username and password) assigned to
								them on registration or account creation
							</li>
							<li>look after their credentials and not give them to anyone else</li>
							<li>
								not interfere with or introduce any malicious software into the online services or
								otherwise misuse them
							</li>
							<li>
								be responsible for any activity that happens under their account, even if someone else
								was acting under their credentials
							</li>
							<li>
								have the necessary infrastructure, equipment, and software to access the online services
							</li>
							<li>abide by the agreement and any policies that we communicate to you in writing</li>
							<li>and any other conditions agreed between us in writing</li>
						</ul>
						<p>
							<strong>5.3 AVAILABILITY</strong>
						</p>
						<p>
							We will do our best always to make the online services available. However, we cannot
							guarantee that they will always be available. We may make them unavailable for scheduled and
							emergency maintenance.
						</p>
						<ol start="6">
							<li>
								<strong> YOUR DATA</strong>
							</li>
						</ol>
						<p>
							<strong>6.1 DEFINITION</strong>
						</p>
						<p>
							The agreement consists of these terms of service and any orders or any other specific terms
							applicable to the services.
						</p>
						<p>Your data is any data belonging to you or your customer that:</p>
						<ul>
							<li>you or your customer (or any third party on your behalf) that you provide to us</li>
							<li>we generate, process, or supply to you or your customer in providing the services</li>
							<li>
								but excludes any derived data that we create for our purposes, or which is proprietary
								or confidential to us or our third-party contractors.
							</li>
						</ul>
						<p>
							You own all your data but give us a right to use it to provide the services when you give us
							access to it.
						</p>
						<p>
							<strong>6.3 WHAT WE OWN</strong>
						</p>
						<p>
							We do not own any of your data. However, we do acknowledge our derived data. Your data does
							not include any derived data we create for our internal purposes. Derived data is any of the
							data that we create from your data, such as through aggregation, de-identification, or
							anonymization.
						</p>
						<p>
							<strong>6.4 RESPONSIBILITY</strong>
						</p>
						<p>
							We take the protection of your data very seriously and will always do everything in our
							power to protect it. To that effect, we will:
						</p>
						<ul>
							<li>
								comply with all relevant laws that affect your data, including the Protection of
								Personal Information Act 4 of 2013 (POPIA), data protection, retention, and destruction
								laws.
							</li>
							<li>
								implement and maintain adequate security safeguards that include, but are not limited
								to, administrative, technical, and physical safeguards, and appropriate technical and
								organizational measures, in each case, adequate to ensure the security and
								confidentiality of personal information,
							</li>
							<li>
								protect against any anticipated risks to the security or integrity of personal
								information,
							</li>
							<li>protect against unauthorized access to or use of personal information,</li>
							<li>
								protect personal information against unlawful processing or processing otherwise than by
								these terms of service,
							</li>
							<li>
								protect against accidental loss, destruction, damage, alteration, or disclosure of
								personal information have due regard to leading industry information security
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; management codes of
								practice, where appropriate
							</li>
							<li>have an individual oversee compliance with relevant data protection laws.</li>
							<li>not sell, dispose of, or encumber any of your data or try to do any of those things</li>
							<li>
								be able to identify any of your data separately from any other data under our control
							</li>
							<li>
								not disclose any personal information from your data other than in terms of the
								agreement.
							</li>
						</ul>
						<p>
							In the event of any actual, suspected, or alleged security breach, including, but not
							limited to, loss, damage, destruction, theft, unauthorized use, access to, or disclosure of
							any personal information, we undertake to:
						</p>
						<ul>
							<li>notify you as soon as practicable after becoming aware of such an event.</li>
							<li>
								provide the information regarding the breach that we acknowledge and have in our
								possession to allow you to ascertain what has occurred and which personal information
								has been affected.
							</li>
							<li>
								promptly take whatever action is necessary to minimize the impact of such an event and
								prevent such an event from recurring.
							</li>
						</ul>
						<p>
							As a result of this, you consent to Yellowish.io sharing personal information for
							cross-border exchanges. Should the user’s personal information be shared cross border, the
							personal information will not be subject to less protection than it enjoys in terms of South
							Africa’s data privacy laws.
						</p>
						<p>
							You consent to the company processing any special personal information provided herein in
							section 26 of POPI.
						</p>
						<p>
							We have data operator agreements in place to ensure that the operator maintains the security
							measures referred to in&nbsp;
							<a href="https://popia.co.za/section-19-security-measures-on-integrity-and-confidentiality-of-personal-information/">
								section 19 of POPIA
							</a>
							, which requires the operator to establish and maintain confidentiality and security
							measures to ensure the integrity of the personal information.
						</p>
						<p>
							<strong>6.5 SUBCONTRACTING</strong>
						</p>
						<p>
							Subcontracting involves engaging a subcontractor outside our organization to do work as part
							of providing the services. We may subcontract work involving your data, provided that:
						</p>
						<ul>
							<li>
								where we have already subcontracted or are in the process of subcontracting work
								involving your data before the conclusion of this agreement, we inform you in writing of
								any pre-existing subcontractors
							</li>
							<li>
								where we wish to engage a subcontractor after the conclusion of this agreement, we get
								your written permission to subcontract work involving your data beforehand
							</li>
							<li>
								we notify you in writing of (i) the purpose of sharing your data with the subcontractor;
								and (ii) how we have carried out due diligence on them
							</li>
							<li>
								we do so only through a written agreement with the subcontractor, which imposes the same
								obligations on them as are imposed on us
							</li>
							<li>
								we remain fully liable for any processing of your data under the agreement by our
								subcontractor
							</li>
						</ul>
						<p>
							<strong>6.6 LOCATION</strong>
						</p>
						<p>
							Your data will remain wherever we place it initially unless we must transfer it to another
							country to comply with your obligations. We will ask for and get your consent before OR You
							consent to us transferring it to our group of companies, associated companies, service
							providers, or agents in other countries to provide the services
						</p>
						<p>
							<strong>6.7 ACCESS AND DELETION REQUESTS</strong>
						</p>
						<p>
							The right to be forgotten in POPIA allows for the deletion of personal information that is
							“inaccurate, irrelevant, excessive, out of date, incomplete, misleading or obtained
							unlawfully.”
						</p>
						<p>
							You can <a href="#remove-personal-info">complete our form here</a> to request a record of
							your data or the deletion of all personal information on our systems.
						</p>
						<ol start="7">
							<li>
								<strong> CONFIDENTIAL INFORMATION</strong>
							</li>
						</ol>
						<p>
							<strong>7.1 DEFINITION</strong>
						</p>
						<p>
							Confidential information is any information that the parties share in this agreement. The
							other party should keep its secrets, such as personal information, business records, or
							customer details.
						</p>
						<p>
							<strong>7.2 RESPONSIBILITIES</strong>
						</p>
						<p>
							Each party will keep any confidential information it receives from the other party under the
							agreement confidential, and the receiving party will:
						</p>
						<ul>
							<li>protect the other party’s interests</li>
							<li>only use it to comply with their responsibilities under the agreement</li>
							<li>
								only give it to their employees or agents that need it (and only as much as they need)
							</li>
							<li>
								use reasonable security procedures to make sure their employees or agents keep it
								confidential
							</li>
							<li>
								get promises of confidentiality from those employees or agents who need access to the
								information
							</li>
							<li>not reveal the information to anyone else</li>
							<li>not use it for any purpose other than under this agreement</li>
						</ul>
						<p>
							<strong>7.3 END OF AGREEMENT</strong>
						</p>
						<p>
							The parties will give back to the other all confidential information of the other that they
							have at the end of the agreement, unless:
						</p>
						<ul>
							<li>the other party agrees that they may destroy or retain it instead</li>
							<li>it is lawfully in the public domain</li>
							<li>someone else (a third party) who is allowed to reveal it gives it to them</li>
							<li>someone gives it to them to comply with a court order or other legal duty</li>
						</ul>
						<p>
							<strong>7.4 INDEMNITY</strong>
						</p>
						<p>
							Each party indemnifies the other against any loss or damage that the other may suffer
							because of a breach of this clause by a party or its employees or agents.
						</p>
						<p>
							<strong>7.5 SURVIVAL</strong>
						</p>
						<p>
							This clause about confidential information is separate from the rest of this agreement and
							remains valid for five years after the end of this agreement.
						</p>
						<ol start="8">
							<li>
								<strong> MARKETING</strong>
							</li>
						</ol>
						<p>
							<strong>8.1 SUBSCRIPTION AND OPT-IN</strong>
						</p>
						<p>
							By using our website or Services (we collect your data as described in section 5 of our
							Privacy Policy) you become our customer and can thus be marketed to on an opt-out basis. We
							may contact you via email, telephone, SMS/text messages, postal mail, social media
							platforms, instant messaging systems, and via push notifications (collectively “Marketing
							Channels”) with marketing material until you opt out.
						</p>
						<p>
							&nbsp;&nbsp;{" "}
							<strong>8.2 DIRECT MARKETING OBLIGATIONS AS PRESCRIBED BY S 69 OF POPIA</strong>
						</p>
						<p>
							<strong>&nbsp;&nbsp; 8.2.1 </strong>The Company may only approach a data subject considering
							the following:
						</p>
						<ul>
							<li>Their consent is required.</li>
							<li>If they have not previously withheld consent; and</li>
							<li>The Company may only approach a data subject once.</li>
						</ul>
						<p>
							<strong>8.3 HOW DOES THE COMPANY OBTAIN CONSENT</strong>
						</p>
						<p>The subject’s consent must be requested in the following ways:</p>
						<ul>
							<li>Manual opt-in form.</li>
							<li>In The Company client contracts; and</li>
							<li>In written consent either via a signed document or in email.</li>
						</ul>
						<p>
							<strong>8.3.3 OPT-IN ON THE COMPANY WEBSITE</strong>
						</p>
						<p>
							When is the processing of personal information for direct marketing purposes allowed?
							Processing the personal information of a customer or data subject is acceptable if:
						</p>
						<ul>
							<li>
								The client’s contact details were obtained when a sale of a product or service is made.
							</li>
							<li>For similar products that are in the client’s best interest.</li>
							<li>For the primary purpose of marketing only; and</li>
							<li>
								The client or data subject has not refused to receive communication or has previously
								opted out.
							</li>
						</ul>
						<p>
							<strong>8.4 UNSUBSCRIBE&nbsp; / OPT-OUT</strong>
						</p>
						<p>
							<strong>8.4.1</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You can opt-out from marketing for a
							specific email address by clicking the unsubscribe link on marketing-related emails you
							received (not including emails generated by our website or Services about your direct use of
							our website, i.e., responses to adverts placed).
						</p>
						<p>
							<strong>8.4.2</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You can opt-out from SMS marketing
							(free of charge) by sending STOP.
						</p>
						<p>
							<strong>8.4.3</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You can opt-out from Push
							Notifications by removing the website you give permissions to in the Notification settings
							of your browser.
						</p>
						<p>
							<strong>8.4.4</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You can manage your cookie preferences
							by clicking here:
						</p>
						<a
							role="button"
							tabindex="0"
							class="medium cli-plugin-button cli-plugin-main-button cli_settings_button"
							// style="color: rgb(51, 51, 51); background-color: rgb(222, 223, 224);"
						>
							Cookie Settings
						</a>
						<p>
							<strong>8.4.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong> Please note that it could
							take up to three workdays for opt-out requests to fully take effect.
						</p>
						<p>
							<strong>8.4.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong>Manage Preferences – Update your
							interests and subscriptions by sending an email{" "}
							<a href="mailto:webmaster@staging.yellowish.io">webmaster@staging.yellowish.io</a>
						</p>
						<p>
							<strong>8.5 COMPLAINTS PROCEDURE</strong>
						</p>
						<p>
							If you feel that you received communication from us that you did not consent to, or if you
							suspect that your information might have been accessed without your permission, we ask that
							you follow our simple complaints procedure on the following link.&nbsp;
						</p>
						<ol start="9">
							<li>
								<strong> ADVERTISING</strong>
							</li>
						</ol>
						<p>
							We have created advertising rules to comply with various legislation, ensure quality
							advertising, improve safety, and protect animals.
						</p>
						<ol start="10">
							<li>
								<strong> CONTACTING ADVERTISERS</strong>
							</li>
						</ol>
						<p>
							<strong>10.1 &nbsp;&nbsp;&nbsp;&nbsp; </strong>We provide a facility on the Website and
							Service/s whereby users can respond to advertisers through SMS, Calls, Emails, and WhatsApp
							(collectively: Communication Services). These Communication Services are provided solely to
							facilitate a means whereby customers can contact advertisers. This facility may not be used
							to send spam or any commercial marketing message to the advertiser. These messages are
							monitored for any such activity to ensure quality, improve safety, and stop spam.
						</p>
						<p>
							<strong>10.1.2</strong>&nbsp;&nbsp; By way of example, and not as a limitation, the User
							agrees that when using a &nbsp;&nbsp; Communication Service, they will not:
						</p>
						<ul>
							<li>
								Defame, abuse, embarrass, harass, stalk, slander, threaten or otherwise violate the
								legal rights (such as rights of privacy and publicity) of others.
							</li>
							<li>
								Publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory,
								infringing, obscene, indecent, or unlawful topic, name, material, or information.
							</li>
							<li>
								Upload files that contain software or other material protected by intellectual property
								laws (or by rights of privacy of publicity) unless owned by or control the rights to it
								or have received all necessary consents.
							</li>
							<li>
								Upload files that contain viruses, corrupted files, or any other similar software or
								programs that may damage the operation of another’s a computer.
							</li>
							<li>
								Advertise or offer to sell or buy any goods or services for any business purpose unless
								such Communication Service specifically allows such messages.
							</li>
							<li>Conduct or forward surveys, contests, pyramid schemes, or chain letters.</li>
						</ul>
						<ul>
							<li>
								Download any file posted by another user of a Communication Service that the User knows
								or reasonably should know.
							</li>
							<li>
								Falsify or delete any author attributions, legal or other proper notices, proprietary
								designations, or labels of the origin or source of software or other material in an
								uploaded file.
							</li>
							<li>
								Restrict or inhibit any other user from using and enjoying the Communication Services.
							</li>
							<li>
								Violate any code of conduct or other guidelines applicable to any Communication Service.
							</li>
							<li>
								Harvest or otherwise collect information about others, including email addresses,
								without their consent.
							</li>
							<li>Violate any applicable laws or regulations.</li>
							<li>Create a false identity or;</li>
							<li>
								Use or attempt to use another’s account, password, service, or system without
								authorization from the Company.
							</li>
							<li>
								Access or attempt to access any service or content that the User is not authorized to
								access.
							</li>
						</ul>
						<p>
							The Company has no obligation to monitor the Communication Services. However, the Company
							reserves the right to review materials posted to a Communication Service and remove any
							materials at its sole discretion. The Company reserves the right to terminate the User’s
							access to any or all the Communication Services at short notice for any reason whatsoever.
						</p>
						<p>
							The Company always reserves the right to disclose any information as necessary to satisfy
							any applicable law, regulation, legal process, or governmental request, or to edit, refuse
							to post, or remove any information or materials, in whole or in part, at the Company’s sole
							discretion.
						</p>
						<p>
							The User should always use caution when giving out any personally identifying information to
							any Communication Service. The Company does not control or endorse the content, messages, or
							information found in any Communication Service. Therefore, the Company expressly disclaims
							any liability about the Communication Services and any actions resulting from the User’s
							participation in any Communication Service. Managers and hosts have not authorized Company
							spokespersons, and their views do not necessarily reflect those of The Company
						</p>
						<p>
							Materials uploaded to a Communication Service may be subject to posted limitations on usage,
							reproduction, and dissemination. The User is responsible for adhering to such limitations
							when and if downloading such materials.
						</p>
						<ol start="11">
							<li>
								<strong> AMENDMENTS</strong>
							</li>
						</ol>
						<p>
							<strong>11.1 OWNERSHIP</strong>
						</p>
						<p>
							We or our third-party licensors own all proprietary rights in our services, and they or we
							may prosecute you for any violations of those rights.
						</p>
						<p>
							<strong>11.2 OUR TECHNOLOGY</strong>
						</p>
						<p>
							Our technology is anything that we have or acquire rights to and may use to perform our
							obligations under the agreement.
						</p>
						<p>
							<strong>11.3 RETENTION OF RIGHTS</strong>
						</p>
						<p>
							We own all intellectual property rights in our technology, and you may not use those rights
							without our permission. You do not acquire any rights to our technology if we use it to
							provide services to you.
						</p>
						<p>
							<strong>11.4 TRADEMARKS</strong>
						</p>
						<p>
							Our trademarks are our property, and you may not use them without our permission. All other
							trademarks are their respective owners’ property.
						</p>
						<p>
							<strong>11.5 RESTRICTIONS</strong>
						</p>
						<p>
							You may not change, hire out, reverse engineer, or copy the services without our permission.
						</p>
						<p>
							<strong>11.6</strong> <strong>YOUR INTELLECTUAL PROPERTY</strong>
						</p>
						<p>
							You grant us a non-exclusive and royalty-free license to use any of your trademarks and
							copyright works that you deliver to us to provide the services. We may not use them for any
							other purpose without your prior written permission. The license expires automatically when
							the agreement ends. You retain all rights in your trademarks and copyright works despite
							this license.
						</p>
						<ol start="12">
							<li>
								<strong> NON-SOLICITATION</strong>
							</li>
						</ol>
						<p>
							You will not contract with any of our personnel, other than through us, who were involved in
							providing services under an order for the duration of that order or 12 calendar months after
							its termination.
						</p>
						<ol start="13">
							<li>
								<strong> FEES AND PAYMENT</strong>
							</li>
						</ol>
						<p>
							<strong>13.1 PAYMENT</strong>
						</p>
						<p>
							You will pay us the fees on the due date in the manner agreed between the parties in
							writing. You may not withhold payment of any amount due to us for any reason.
						</p>
						<p>
							<strong>13.2 LATE PAYMENT</strong>
						</p>
						<p>
							Additional charges agreed between the parties in writing apply to any payment we receive
							after the due date, and you must pay them to us on demand. We may stop providing any
							services until you have paid all amounts owing.
						</p>
						<p>
							<strong>13.3 INTEREST</strong>
						</p>
						<p>
							Overdue amounts on any outstanding invoice will bear interest for our benefit from its due
							date until you pay it at whichever rate is higher between:
						</p>
						<p>2% above the prime (or prime lending) rate</p>
						<p>or 15%</p>
						<p>Interest will be payable on a claim for damages from when the damages were suffered.</p>
						<p>
							<strong>13.4</strong> <strong>APPROPRIATION</strong>
						</p>
						<p>
							We may use any money you pay us to settle your indebtedness under the agreement, despite any
							reason you have delivered it to us.
						</p>
						<p>
							<strong>13.5 CERTIFICATE</strong>
						</p>
						<p>
							We may appoint an accountant to sign a certificate that will prove the amount due by you and
							the date on which it is payable.
						</p>
						<p>
							<strong>13.6 TAX</strong>
						</p>
						<p>
							All fees exclude any tax (unless indicated otherwise), which you will pay where applicable
							in addition to the costs.
						</p>
						<p>
							<strong>13.7 PAYMENT PROFILE</strong>
						</p>
						<p>
							We may provide any registered credit bureau with information about your payment of amounts.
						</p>
						<ol start="14">
							<li>
								<strong> WARRANTIES</strong>
							</li>
						</ol>
						<p>
							<strong>14.1 SERVICE WARRANTIES</strong>
						</p>
						<p>We warrant that we will:</p>
						<ul>
							<li>
								employ enough trained personnel with the knowledge and expertise to provide the services
							</li>
							<li>
								use reasonable efforts consistent with prevailing industry standards to maintain the
								services
							</li>
							<li>provide the services by all applicable laws</li>
						</ul>
						<p>
							<strong>14.2 GENERAL WARRANTIES</strong>
						</p>
						<p>We warrant further that we:</p>
						<ul>
							<li>have the legal right and authority to perform our obligations under the agreement</li>
							<li>will not knowingly introduce any malicious software into your systems</li>
						</ul>
						<ol start="15">
							<li>
								<strong> DISCLAIMER OF WARRANTIES</strong>
							</li>
						</ol>
						<p>
							<strong>15.1 DISCLAIMER</strong>
						</p>
						<p>
							You use the services at your own risk, and we disclaim all other warranties to the extent
							allowed by applicable law. We are not liable for any defect that you cause.
						</p>
						<p>
							<strong>15.2 EXCLUSION OF LIABILITY</strong>
						</p>
						<p>
							Despite our warranties, we are not liable for any defects that your negligence, failure to
							follow our instructions, or misuse causes.
						</p>
						<ol start="16">
							<li>
								<strong> YOUR WARRANTIES</strong>
							</li>
						</ol>
						<p>
							<strong>16.1 AGREEMENT WARRANTIES</strong>
						</p>
						<p>You warrant that:</p>
						<ul>
							<li>
								no one has induced you to agree by any prior representations, warranties, or guarantees
							</li>
							<li>you are not breaching any other agreement by entering into the agreement</li>
						</ul>
						<p>
							<strong>16.2 INDEMNITY</strong>
						</p>
						<p>
							You indemnify us against any claim for damages by any third party resulting from a breach of
							your warranties, including all legal costs. Legal costs mean the costs that a lawyer may
							recover from their client for their disbursements and professional services if permissible
							under applicable law.
						</p>
						<ol start="17">
							<li>
								<strong> LIMITATIONS OF LIABILITY</strong>
							</li>
						</ol>
						<p>
							<strong>17.1 DIRECT DAMAGES LIMITED</strong>
						</p>
						<p>
							We are only liable to you for any direct damages that the services may cause up to a maximum
							amount of R1000.00.
						</p>
						<p>
							<strong>17.2 INDIRECT DAMAGES EXCLUDED</strong>
						</p>
						<p>We are not liable for any other damages or losses that the services may cause you.</p>
						<p>
							<strong>17.3 YOUR DEFAULT</strong>
						</p>
						<p>
							We are not liable for any damage or loss that your breach, misrepresentation, or mistake
							causes.
						</p>
						<ol start="18">
							<li>
								<strong> BREACH AND TERMINATION</strong>
							</li>
						</ol>
						<p>
							<strong>18.1 BREACH</strong>
						</p>
						<p>If either party:</p>
						<ul>
							<li>
								does not fix a breach within seven days of receiving written notice from the other party
							</li>
							<li>breaches the agreement materially twice or more in six months</li>
							<li>is bankrupt or has some legal disability</li>
							<li>
								takes steps to close or is closed (such as becoming insolvent or entering sequestration)
							</li>
							<li>makes any settlement or arrangement with their creditors</li>
							<li>
								or fails to pay a court order against themselves for a significant amount within 21 days
							</li>
						</ul>
						<p>then the other party may:</p>
						<ul>
							<li>make the party comply with the agreement</li>
							<li>
								or immediately cancel the agreement in writing and claim damages from the other party,
								including fees already due
							</li>
						</ul>
						<p>
							<strong>18.2 SUSPENSION</strong>
						</p>
						<p>We may immediately suspend your right to use the services if:</p>
						<ul>
							<li>you try to gain unauthorized access to them</li>
							<li>
								we decide that your use poses a security threat to us or another user other than you
							</li>
							<li>there is evidence of fraud on your account</li>
							<li>
								or we believe you are using them for an illegal purpose or in ways that infringe a third
								party’s rights
							</li>
						</ul>
						<p>
							<strong>18.3 TERMINATION</strong>
						</p>
						<p>
							<strong>18.3.1 TERMINATION FOR GOOD CAUSE</strong>
						</p>
						<p>We may need to terminate the agreement immediately if we:</p>
						<ul>
							<li>discontinue or stop providing the services</li>
							<li>believe providing the services could burden or pose a risk to us</li>
							<li>must terminate to comply with a law</li>
							<li>or determine that providing the services has become impractical</li>
							<li>
								If we need to terminate, we will give you as much notice as reasonably possible in
								writing.
							</li>
						</ul>
						<p>
							<strong>18.3.2 TERMINATION FOR CONVENIENCE</strong>
						</p>
						<p>
							You may terminate the agreement or a specific order on at least 30 days written notice to
							us.
						</p>
						<p>
							<strong>18.3.3 TERMINATION BEYOND HUMAN CONTROL</strong>
						</p>
						<p>
							Neither party is responsible for a breach of the agreement caused by circumstances beyond
							human control. Still, the other party may cancel the contract on written notice to the other
							if the circumstances persist for more than 60 calendar days.
						</p>
						<p>
							<strong>18.3.4 DUTIES ON TERMINATION</strong>
						</p>
						<p>
							We will stop providing the services, you will no longer be able to access them, and we may
							erase your data on termination, cancellation, or expiry of the agreement.
						</p>
						<ol start="19">
							<li>
								<strong> EFFECT OF TERMINATION</strong>
							</li>
						</ol>
						<p>
							<strong>19.1 ACCELERATION</strong>
						</p>
						<p>
							All amounts due to us for the services become due and payable on termination, cancellation,
							or expiration of the agreement.
						</p>
						<p>
							<strong>19.2 ASSISTANCE</strong>
						</p>
						<p>
							We may provide you with post-termination assistance (such as data retrieval) subject to
							additional fees and conditions but are not obliged to.
						</p>
						<p>
							<strong>19.3 NO EXPECTATION</strong>
						</p>
						<p>
							The agreement does not create any expectation of continued service, renewal, or other
							agreement between the parties.
						</p>
						<ol start="20">
							<li>
								<strong> GENERAL</strong>
							</li>
						</ol>
						<p>
							<strong>20.1 GOVERNING LAW</strong>
						</p>
						<p>
							Upon registering for the use of the services offered on the website, you agree and
							acknowledge that these Terms Of Service will be governed by the Republic of South Africa
							laws.
						</p>
						<p>
							<strong>20.2 RESOLVING DISPUTES</strong>
						</p>
						<p>
							Either party may inform the other in writing if there is a dispute. The parties must first
							try to negotiate to end the conflict, enter mediation if negotiation fails, and finally, go
							to arbitration if mediation fails. If they go to arbitration, they will agree in writing on
							a recognized and appropriate forum for arbitration accessible to both parties.
						</p>
						<p>
							<strong>20.3 MEDIATION</strong>
						</p>
						<p>
							If negotiation fails, the parties must refer the dispute to mediation under AFSA’s rules.
							AFSA means the Arbitration Foundation of Southern Africa (or its successor or body nominated
							in writing by it in its stead).
						</p>
						<p>
							<strong>20.4 ARBITRATION</strong>
						</p>
						<p>
							If mediation fails, the parties must refer the dispute within 15 business days to
							arbitration (including any appeal against the arbitrator’s decision) under AFSA’s latest
							rules for expedited arbitrations. The arbitration will be held in English in Cape Town. The
							parties will agree and appoint one arbitrator. If the parties cannot agree on the arbitrator
							within ten business days after the referral, the Secretariat of AFSA will appoint the
							arbitrator.
						</p>
						<ul>
							<li>
								The Third Party and Service Provider may apply to have any settlement, mediation, and
								arbitration reached between them made an order of Court.
							</li>
							<li>
								Should the Third Party approach any Court to settle a dispute arising due to this
								Agreement or to appeal and review any compensation or mediation. Arbitration reached in
								terms of Clause 20.4.1 shall provide security, to the satisfaction of the Court, for any
								cost order which may arise because of an order of the Court, provided that such
								protection shall not be less than ZAR100&nbsp;000.00 (One Hundred Thousand South African
								Rand).
							</li>
						</ul>
						<p>
							<strong>20.4.3&nbsp; &nbsp;&nbsp; </strong>Upon registering for the use of the services
							offered on the website, the Third Party agrees and acknowledges that it shall be liable for
							any costs arising and incurred by the Service Provider as a result of the enforcement of any
							rights which the Service Provider has in terms of this Agreement, or the recovery of any
							money due and owing to the Service Provider, including any collection charges, tracing
							costs, legal fees on an Attorney-own Client scale, and cost of counsel, regardless of the
							costs being incurred prior and/or during the institution of legal proceedings, or if a
							judgment has been granted in connection with the satisfaction and/or enforcement of any
							judgment against the Third Party.
						</p>
						<p>
							<strong>20.5 JURISDICTION</strong>
						</p>
						<p>
							You consent to the jurisdiction of the Magistrate’s Court in respect of any action or
							proceedings that we may bring against you in connection with this agreement, even if the
							action or proceedings would otherwise be beyond its jurisdiction without prejudice to our
							right to institute any action in any other court having jurisdiction.
						</p>
						<p>
							<strong>20.6 NOTICES AND DOMICILE</strong>
						</p>
						<p>
							The parties will send all notices to each other’s email addresses and choose their
							respective street addresses as their service addresses for all legal documents. Our email
							and street addresses are available on our website, while you provide your email and street
							addresses to us when agreeing. The parties may change either address on 14 calendar days’
							written notice.
						</p>
						<p>
							<strong>20.7 ASSIGNMENT</strong>
						</p>
						<p>
							You may not assign the agreement to anyone. We may transfer it to any successor or purchaser
							of our business or some of our assets.
						</p>
						<p>
							<strong>20.8 RELATIONSHIP</strong>
						</p>
						<p>
							The agreement does not create any agency, partnership, or employment relationship between
							the parties.
						</p>
						<p>
							<strong>20.9 ENTIRE AGREEMENT</strong>
						</p>
						<p>The agreement is the entire agreement between the parties on the subject.</p>
						<p>
							<strong>20.10 CHANGES</strong>
						</p>
						<p>
							We will notify you of any changes to the agreement by email. Those changes will only apply
							to future service orders. If you disagree with the changes, you must stop using the
							services. If you continue to use the services following notification of a difference, the
							changed terms will apply to you, and you will be deemed to have accepted them.
						</p>
						<p>
							<strong>20.11 WAIVER</strong>
						</p>
						<p>Any favour we may allow you will not affect our rights against you.</p>
						<p>
							<strong>20.12 SEVERABILITY</strong>
						</p>
						<p>
							Any invalid, unenforceable, or illegal term may be removed from the agreement without
							affecting the rest of it.
						</p>
						<p>
							<strong>20.13 LINKS TO OTHER SITES</strong>
						</p>
						<p>
							Our website may contain links to other websites on the Internet. We are not responsible for
							and do not endorse the content, products, or services of any third-party Websites,
							including, without limitation, sites framed within our website or third-party
							advertisements. We do not make any representations regarding their quality, content, or
							accuracy. Your use of third-party websites is at your own risk and subject to the terms and
							conditions for such websites.
						</p>
						<p>
							<strong>20.14 COMMENTS AND SUGGESTIONS</strong>
						</p>
						<p>
							We welcome your comments and feedback. All comments, suggestions, or other information you
							send to us will become our property, and you agree that all intellectual property rights
							therein are transferred to us. You understand that any postings to our publicly available
							portions of the Yellowish.io Website are non-confidential for all purposes.
						</p>
						<p>
							<em>UPDATED MAY 2022</em>
						</p>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default TermsOfService;
