import React, { useContext } from "react";
import { UserContext } from "../../../Context/userContext";

const MyPackage = () => {
	const { userInfo } = useContext(UserContext);
	return (
		<div className="tabs-main d-flex justify-content-start ">
			<div className="row ml-3 mb-5 w-100">
				<h4 className="mb-5 mt-3 pl-0">My Packages</h4>
				{userInfo?.user_packages?.length === 0 ? (
					<p className="mb-5 pl-0 ">you don't have any packages</p>
				) : (
					<div class="user_listing pl-0 w-100">
						<table class="user_table user_table-bordered">
							<tbody>
								<tr>
									<th>Payment ID</th>
									<th>Payment Key</th>
									<th>Package</th>
									<th>Status</th>
									<th>Listings Used</th>
									<th>Price</th>
								</tr>

								{userInfo?.user_packages?.map((user_package, index) => {
									return (
										<tr>
											<td>#{user_package?.payment_id}</td>
											<td>{user_package?.package_id}</td>
											<td>{user_package?.name}</td>
											<td>{user_package?.active === true ? "Active" : "Not Active"}</td>
											<td>{user_package?.remaining_listings}</td>
											<td>
												<span class="price-amount">
													<span class="currency-symbol">
														{user_package?.price === 0 ? "POA" : user_package?.price}
													</span>
												</span>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				)}
			</div>
		</div>
	);
};

export default MyPackage;
