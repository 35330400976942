import { combineReducers } from "@reduxjs/toolkit";
import FinanceQuoteSlice from "./FinanceQuoteSlice";
import InsuranceQuoteSlice from "./InsuranceQuoteSlice";
import TransportQuoteSlice from "./TransportQuoteSlice";

export const quotes = combineReducers({
	insuranceQuote: InsuranceQuoteSlice,
	transportQuote: TransportQuoteSlice,
	financeQuote: FinanceQuoteSlice
});
