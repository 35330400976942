import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../utils/ApiEndpoints";

const CategoryThunk = createAsyncThunk("Category/CategoryThunk", async (_, { rejectWithValue }) => {
	try {
		const response = await axios.get(ApiEndpoints.Category);
		return response.data;
	} catch (error) {
		console.log("error", error);
		return rejectWithValue(error.response.data);
	}
});

const CategorySlice = createSlice({
	name: "Category",
	initialState: {
		CategoryData: {},
		loading: false,
		success: false,
		error: false,
		errorMessage: ""
	},
	reducers: {
		ResetCategory: (state) => {
			state.CategoryData = {};
			state.error = false;
			state.success = false;
			state.loading = false;
		}
	},
	extraReducers: {
		[CategoryThunk.pending]: (state, action) => {
			state.loading = true;
			state.error = false;
			state.success = false;
		},
		[CategoryThunk.fulfilled]: (state, action) => {
			state.CategoryData = action.payload;
			state.loading = false;
			state.success = true;
		},
		[CategoryThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload.message;
		}
	}
});

export default CategorySlice.reducer;

export const { ResetCategory } = CategorySlice.actions;

export { CategoryThunk };
