import { Formik, Form, Field } from "formik";
import React, { useContext } from "react";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { AdvertisementValidationSchema } from "../../utils/FormValidationSchema";
import { AdvertisementThunk, ResetAdvertisementData } from "../../redux/AdvertisementSlice";
import { Spinner } from "react-bootstrap";
import { UserContext } from "../../Context/userContext";
import toast from "react-hot-toast";
import { advertiseImage, newsletter } from "../../assets/images";
import { Link } from "react-router-dom";
import SubscriptionModal from "../common/SubscriptionModal";
import { useState } from "react";

const Advertisement = () => {
	const dispatch = useDispatch();
	const [openModal, setOpenModal] = useState(false);

	const { loading, success, error, errorMessage } = useSelector((state) => state.advertise);

	const { userInfo } = useContext(UserContext);

	const AdvertisementInitialValues = {
		fullName: userInfo && userInfo?.username ? userInfo?.username : "",
		email: userInfo && userInfo?.email ? userInfo?.email : "",
		phoneNumber: "",
		message: ""
	};

	const handleSubmit = ({ fullName, email, phoneNumber, message }) => {
		dispatch(AdvertisementThunk({ fullName, email, phoneNumber, message }));
	};

	if (success) {
		toast.success("Form has been submitted successfully.");
		dispatch(ResetAdvertisementData());
	}

	if (error) {
		toast.error(errorMessage);
		dispatch(ResetAdvertisementData());
	}

	return (
		<div>
			<Navbar />
			<main>
				<section className="cont1">
					<div className="container">
						<div className="row cont1-nopadding">
							<div className="col-lg-6 bg-black900 cont1-padding">
								<h1 className="cont1-heading1 color-white">ADVERTISE ON YELLOWISH.IO</h1>
								<div className="cont1-textalign">
									<span>
										<a className="cont1-anker color-white" href="tell:+27218139555">
											+27 21 813 9555
										</a>
									</span>
									<span>
										<a className="cont1-anker color-white" href="mailto:admin@yellowish.io">
											admin@yellowish.io
										</a>
									</span>
									<span>
										<a className="cont1-anker1 color-yellow900" href="http://www.yellowish.io/">
											www.yellowish.io
										</a>
									</span>
								</div>
								<div className="cont1-padd">
									<a href="https://www.facebook.com/Yellowish.io">
										<img
											className="cont1-image"
											src="/images/facebook-icon.png"
											alt="facebook-icon"
										/>
									</a>
									<a href="https://www.linkedin.com/company/yellowish-io/">
										<img
											className="cont1-image"
											src="/images/linkedin-icon.png"
											alt="linkedin-icon"
										/>
									</a>
									<a href="https://www.instagram.com/yellowish.io/">
										<img
											className="cont1-image"
											src="/images/instagram-icon.png"
											alt="instagram-icon"
										/>
									</a>
								</div>
								<img className="cont1-logo" src="/images/yellowish-logo-whitetext.png" alt="" />
							</div>
							<Formik
								enableReinitialize={true}
								initialValues={AdvertisementInitialValues}
								validationSchema={AdvertisementValidationSchema}
								onSubmit={handleSubmit}
							>
								{({ errors, touched }) => (
									<div className="col-lg-6 cont1-bgcontact no-padding">
										<div className="row">
											{console.log("errors", errors)}
											<div className="col-12 cont1-right">
												<div className="bg-black800 cont1-attach">
													<div className="cont1-flexst">
														<h1
															className="cont1-heading2 color-white"
															style={{ paddingRight: "40px" }}
														>
															SEND US YOUR ADVERTISING ENQUIRY AND WE’LL BE IN TOUCH
														</h1>
														<p className="cont1-para color-black700">
															"<span className="color-red">*</span>" indicates required
															fields
														</p>
													</div>
													<Form>
														<div className="text-align">
															<Field
																className="cont1-inputBox no-margin"
																type="text"
																name="fullName"
																placeholder="Full Name *"
															/>
															<Field
																className="cont1-inputBox no-margin"
																type="email"
																name="email"
																placeholder="Email *"
															/>
															<Field
																className="cont1-inputBox no-margin"
																type="text"
																name="phoneNumber"
																placeholder="Phone *"
															/>
															<Field
																as="textarea"
																className="cont1-inputBox no-margin"
																placeholder="Message"
																name="message"
																cols="50"
																rows="5"
															></Field>
														</div>
														<button
															className="cont1-button bg-yellow900 color-black900 border-yellow900"
															type="submit"
														>
															{!loading ? "submit" : <Spinner size="sm"> </Spinner>}
														</button>
													</Form>
												</div>
											</div>
										</div>
									</div>
								)}
							</Formik>
						</div>
					</div>
				</section>

				<section className="section-4">
					<div className="container">
						<div className="row">
							<div className="col-lg-5 no-padding">
								<Link to="/Advertise">
									<img src={advertiseImage} className="image-ads last-one" alt="ad" />
								</Link>
							</div>
							<div className="col-lg-2"></div>

							<div className="col-lg-5 no-padding">
								<div className="cursor-pointer" onClick={() => setOpenModal(!openModal)}>
									<img src={newsletter} className="image-ads" alt="ad" />
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			{openModal && <SubscriptionModal openModal={openModal} setOpenModal={setOpenModal} />}

			<Footer />
		</div>
	);
};

export default Advertisement;
