import React, { useContext, useState } from "react";
import { UserContext } from "../../../Context/userContext";
import DeleteListingModal from "./DeleteListingModal";

const MyListing = () => {

	const [selectedProductId, setSelectedProductId] = useState('');
	const [openModal, setOpenModal] = useState({
		delete: false
	});
	const { userInfo } = useContext(UserContext);

	return (
		<div className="tabs-main d-flex justify-content-start">
			<div className="row ml-3 mb-5 w-100">
				<h4 className="ml-3 mb-5 mt-3">My Listings</h4>
				{userInfo?.listings?.length === 0 ? (
					<p className="ml-3 mb-5">you don't have any listings</p>
				) : (
					<div class="user_listing pl-0 w-100">
						<table class="user_table user_table-bordered">
							<tbody>
								<tr>
									<th>Listing ID</th>
									<th>Listing Title</th>
									<th>Listing Status</th>
									<th>Package</th>
									<th>Edit</th>
									<th>View</th>
									<th>Delete</th>
								</tr>
								{userInfo?.listings?.map((listing, index) => {
									return (
										<tr>
											<td>{listing?.id} </td>
											<td>{listing?.title}</td>
											<td>{listing?.approved === true ? "Approved" : "Pending"}</td>
											<td>
												{listing?.package_id} - {listing?.package_name}
											</td>
											<td></td>
											<td></td>
											<td>
												<button className="listing-table-button" onClick={() => { setOpenModal({ delete: true }); setSelectedProductId(listing?.product_id) }} >
													delete
												</button>
												{/* Listing Ended */}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				)}
			</div>
			{openModal.delete && <DeleteListingModal openModal={openModal.delete} setOpenModal={() => setOpenModal({ delete: false })} product_id={selectedProductId} />}
		</div>
	);
};

export default MyListing;
