import React, { useContext } from "react";
import { Formik, Field, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { ResetAccountData } from "../../redux/AccountSlice";
import { LoginInitialValues } from "../../utils/FormValues";
import { LoginValidationSchema } from "../../utils/FormValidationSchema";
import toast from "react-hot-toast";
import { UserContext } from "../../Context/userContext";
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { setIsAuth, login } = useContext(UserContext);
	const previousPageUrl = sessionStorage.getItem('previousPage');

	const { loading, success, error } = useSelector((state) => state.account);

	const handleSubmit = (values) => {
		login({ email: values.email, password: values.password });
	};

	if (success) {
		// navigate.
		if (previousPageUrl) {
			window.location.href = previousPageUrl;
			toast.success("Logged in successfully.");
			setIsAuth(true);
			dispatch(ResetAccountData());
		}
		// window.history.back();
	}
	if (error) {
		toast.error("Invalid email or password.");
		dispatch(ResetAccountData());
	}

	return (
		<div>
			<div id="Login" className="tabcontent">
				<div className="col-lg-12 no-padding tab-heading">
					<h1 className="form-head color-black900 ">Login</h1>
					<p className="form-para color-black800">Existing users, please login below</p>
					<Formik
						initialValues={LoginInitialValues}
						validationSchema={LoginValidationSchema}
						onSubmit={handleSubmit}
					>
						{({ errors, touched }) => (
							<Form>
								<div className="col-lg-12 no-padding">
									<label className="form-inline form-para color-black800" for="email">
										Username or Email
									</label>
									<Field className="form-input" type="text and email" name="email" required />
								</div>

								<div className="col-lg-12 no-padding">
									<label className="form-inline form-para color-black800" for="password">
										Password
									</label>
									<Field className="form-input" type="password" name="password" required />
								</div>

								<label className="form-para color-black800">
									<Field type="checkbox" name="remember" /> Remember Me{" "}
								</label>
								<br />
								<button className="form-bt2 color-black900" type="submit">
									Log in
									<span
										className={loading ? "spinner-border spinner-border-sm" : ""}
										style={{ marginLeft: "5px" }}
									></span>
								</button>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default LoginForm;
