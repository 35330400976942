import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../utils/ApiEndpoints";

const GetProfileThunk = createAsyncThunk("GetProfile/GetProfileThunk", async (_, { rejectWithValue }) => {
	try {
		const response = await axios.get(ApiEndpoints.GetProfile, {
			headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` }
		});
		return response.data;
	} catch (error) {
		console.log("error", error);
		return rejectWithValue(error.response.data);
	}
});

const GetProfileSlice = createSlice({
	name: "GetProfile",
	initialState: {
		userProfileData: {},
		loading: false,
		success: false,
		error: false,
		errorMessage: ""
	},
	reducers: {
		ResetProfileData: (state) => {
			state.userProfileData = {};
			state.error = false;
			state.success = false;
			state.loading = false;
		}
	},
	extraReducers: {
		[GetProfileThunk.pending]: (state, action) => {
			state.loading = true;
			state.error = false;
			state.success = false;
		},
		[GetProfileThunk.fulfilled]: (state, action) => {
			state.userProfileData = action.payload;
			state.loading = false;
			state.success = true;
		},
		[GetProfileThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload;
		}
	}
});

export default GetProfileSlice.reducer;

export const { ResetProfileData } = GetProfileSlice.actions;

export { GetProfileThunk };
