import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../utils/ApiEndpoints";

const AdvertisementThunk = createAsyncThunk(
	"Advertisement/AdvertisementThunk",
	async ({ fullName, email, phoneNumber, message }, { rejectWithValue }) => {
		// try {
		const response = await axios.post(ApiEndpoints.Advertisement, {
			name: fullName,
			email: email,
			phone: phoneNumber,
			description: message
		});
		return response.data;
		// } catch (error) {
		// 	console.log("error", error);
		// 	return rejectWithValue(error.response.data);
		// }
	}
);

const AdvertisementSlice = createSlice({
	name: "Advertisement",
	initialState: {
		AdvertisementData: {},
		loading: false,
		success: false,
		error: false,
		errorMessage: ""
	},
	reducers: {
		ResetAdvertisementData: (state) => {
			state.AdvertisementData = {};
			state.error = false;
			state.success = false;
			state.loading = false;
		}
	},
	extraReducers: {
		[AdvertisementThunk.pending]: (state, action) => {
			state.loading = true;
			state.error = false;
			state.success = false;
		},
		[AdvertisementThunk.fulfilled]: (state, action) => {
			state.AdvertisementData = action.payload;
			state.loading = false;
			state.success = true;
		},
		[AdvertisementThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload;
		}
	}
});

export default AdvertisementSlice.reducer;

export const { ResetAdvertisementData } = AdvertisementSlice.actions;

export { AdvertisementThunk };
