import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { RegisterThunk, ResetAccountData } from "../../redux/AccountSlice";
import { RegisterValidationSchema } from "../../utils/FormValidationSchema";
import { RegisterInitialValues } from "../../utils/FormValues";

const RegisterForm = ({ setOpenTab }) => {
	const dispatch = useDispatch();
	const { loading, success, error, errorMessage } = useSelector((state) => state.account);

	const handleRegistration = async ({ userName, email,phoneNumber, password }) => {
		const response = await axios.post("https://hook.eu1.make.com/tqafze4j5bps6839ckopn7flafst9whb", {
			name: userName,
			email: email,
			phoneNumber
		});
		dispatch(RegisterThunk({ userName, email, phoneNumber, password }));
	};

	if (success) {
		dispatch(ResetAccountData());
		toast.success("Registered successfully");
		setOpenTab("Login");
	}

	if (error) {
		dispatch(ResetAccountData());
		toast.error(errorMessage);
	}

	return (
		<div>
			<div id="Register" className="tabcontent">
				<div className="col-lg-12 no-padding">
					<h1 className="form-head color-black900">Register</h1>
					<p className="form-para color-black800">Please fill in the details below to register</p>

					<Formik
						initialValues={RegisterInitialValues}
						validationSchema={RegisterValidationSchema}
						enableReinitialize={true}
						onSubmit={handleRegistration}
					>
						{({ errors, touched }) => (
							<Form>
								<div className="col-lg-12 no-padding">
									<label className="form-inline form-para color-black800" for="userName">
										Username
									</label>
									<Field className="form-input" type="text" name="userName" required />
									<br />
								</div>
								<div className="col-lg-12 no-padding">
									<label className="form-inline form-para color-black800" for="Email">
										Email
									</label>
									<Field className="form-input" type="text" name="email" required />
									<br />
								</div>
								<div className="col-lg-12 no-padding">
									<label className="form-inline form-para color-black800" for="phoneNumber">
										Phone Number
									</label>
									<Field className="form-input" type="text" name="phoneNumber" required />
									<br />
								</div>
								<div className="col-lg-12 no-padding">
									<label className="form-inline form-para color-black800" for="password">
										Password
									</label>
									<Field className="form-input" type="password" name="password" required />
									<br />
								</div>
								<div className="col-lg-12 no-padding">
									<label className="form-para form-inline color-black800" for="confirmPassword">
										Confirm Password
									</label>
									<Field className="form-input" type="password" name="confirmPassword" required />
									<br />
								</div>

								<button className="form-bt2 color-black900" type="submit">
									Register
									<span
										className={loading ? "spinner-border spinner-border-sm" : ""}
										style={{ marginLeft: "5px" }}
									/>
								</button>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default RegisterForm;
