import React from "react";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import QuoteHeader from "../common/QuoteHeader";
import QuoteSection from "../common/QuoteSection";

const Quote = () => {
	return (
		<div>
			<Navbar activeQuote={true} />
			<QuoteHeader heading={"GET A "} description={"transport, finance, and/or insurance "} />

			<QuoteSection />
			<br />
			<br />
			<br />
			<br />
			<Footer />
		</div>
	);
};

export default Quote;
