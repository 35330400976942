import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetListingDetailThunk = createAsyncThunk(
	"ListingDetail/GetListingDetailThunk",
	async ({ product_id }, { rejectWithValue }) => {
		try {
			const response = await axios.get(`${ApiEndpoints.GetListingDetail}?product_id=${product_id}`);
			return response.data;
		} catch (error) {
			console.log("error", error);
			return rejectWithValue(error.response.data);
		}
	}
);

const GetListingSlice = createSlice({
	name: "ListingDetail",
	initialState: {
		listingDetailData: {},
		loading: false,
		success: false,
		error: false,
		errorMessage: ""
	},
	reducers: {
		ResetListingDetailData: (state) => {
			state.listingDetailData = {};
			state.error = false;
			state.success = false;
			state.loading = false;
		}
	},
	extraReducers: {
		[GetListingDetailThunk.pending]: (state, action) => {
			state.loading = true;
			state.error = false;
			state.success = false;
		},
		[GetListingDetailThunk.fulfilled]: (state, action) => {
			state.listingDetailData = action.payload;
			state.loading = false;
			state.success = true;
		},
		[GetListingDetailThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload;
		}
	}
});

export default GetListingSlice.reducer;

export const { ResetListingDetailData } = GetListingSlice.actions;

export { GetListingDetailThunk };
