import React, { useContext } from "react";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import { Field, Form, Formik } from "formik";
import QuoteHeader from "../common/QuoteHeader";
import { InsuranceQuoteValidationSchema } from "../../utils/FormValidationSchema";
import { useDispatch, useSelector } from "react-redux";
import { InsuranceQuoteThunk, ResetInsuranceQuote } from "../../redux/Quote/InsuranceQuoteSlice";
import toast from "react-hot-toast";
import { UserContext } from "../../Context/userContext";
import { Link, useNavigate } from "react-router-dom";
import { quotesBanner } from "../../assets/images";

const InsuranceQuote = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { loading, success, error, errorMessage } = useSelector((state) => state.quote.insuranceQuote);
	const { userInfo, isAuth, advertisementData } = useContext(UserContext);

	const InsuranceQuoteInitialValues = {
		name: userInfo?.username ? userInfo?.username : "",
		phone: "",
		email: userInfo?.email ? userInfo?.email : "",
		description: ""
	};

	const handleLogin = () => {
		sessionStorage.setItem('previousPage', window.location.href);
		navigate("/my-account");
	}

	const handleSubmit = ({ name, email, phone, description }) => {
		dispatch(InsuranceQuoteThunk({ name, email, phone, description }));
	};

	if (success) {
		toast.success("Insurance quote has been submitted successfully.");
		dispatch(ResetInsuranceQuote());
	}

	if (error) {
		toast.error(errorMessage);
		dispatch(ResetInsuranceQuote());
	}
	return (
		<div>
			<Navbar activeInsuranceQuote={true} />
			<QuoteHeader heading="INSURANCE" description="insurance" />
			<section class="finq1">
				<div class="finq1-ads">
					<div class="ads-container">
						{advertisementData?.length !== 0 && (
							advertisementData?.map((homePageAds, index) => (
								homePageAds.name === "Insurance Quote" &&
								homePageAds.Advertisements.map((ads) => {
									return (
										<a href={ads?.AdvertisementImages[0].ad_link} target="_blank">
											<div class="ads-box-2">
												<img class="w-100" src={ads?.AdvertisementImages[0].image} alt="" />
											</div>
										</a>
									)
								})
							))
						)}
					</div>
				</div>

				<div class="finq1-text-area">
					<div class="text-box mb-20">
						<div class="text-box-box">
							<h3 class="text-box-heading">
								Complete the form below and get a quick insurance quotation.
							</h3>
						</div>
					</div>
					<span style={{ textAlign: "start", justifyContent: "start", width: "100%" }}>
						"*" indicates required fields
					</span>
					<br />

					<Formik
						initialValues={InsuranceQuoteInitialValues}
						validationSchema={InsuranceQuoteValidationSchema}
						enableReinitialize
						onSubmit={handleSubmit}
					>
						{({ errors, touched }) => (
							<Form className="row">
								<div className=" mb-3 no-padding">
									<label className="color-black800 w-25 add-listing-label">Name *</label>
									<Field
										name="name"
										className="col-12 form-control"
										placeholder="Your Name"
										height={65}
									/>
								</div>
								<div className=" mb-3 no-padding">
									<label className="color-black800 w-25 add-listing-label">Phone *</label>
									<Field
										className="col-12 form-control"
										name="phone"
										placeholder="Mobile Number"
										height={65}
									/>
								</div>
								<div className=" mb-3 no-padding">
									<label className="color-black800 w-25 add-listing-label">Email *</label>
									<Field
										name="email"
										className="col-12 form-control"
										placeholder="Your best email"
										height={65}
									/>
								</div>
								<div className=" mb-3 no-padding">
									<label className="color-black800 w-25 add-listing-label">Description</label>
									<Field
										as="textarea"
										name="description"
										rows="6"
										className="col-12 form-control"
										placeholder="Additional information and description of vehicle"
										height={65}
									/>
								</div>

								<div className="mb-3 no-padding">
									<button
										className="cont1-button bg-yellow900 color-black900 border-yellow900 d-flex "
										style={{ justifyContent: "start", marginLeft: "0px" }}
										type="submit"
										disabled={loading}
									>
										SUBMIT
										<span
											className={loading ? "spinner-border spinner-border-sm" : ""}
											style={{ marginLeft: "5px" }}
										/>
									</button>
								</div>
							</Form>
						)}
					</Formik>

					<br />
					<br />
					{!isAuth && (
						<>
							<div class="text-box mb-20">
								<div class="text-box-box">
									<h3 class="text-box-heading">
										Register for free and get a quick quote for finance, transport, and insurance.
									</h3>
								</div>
							</div>
							<p class="info">If you already have a Yellowish account, please login.</p>
							<button
								onClick={handleLogin}
								className="cont1-button bg-yellow900 color-black900 border-yellow900 ml-0"
								type="submit"
							>
								CLICK HERE TO LOGIN OR REGISTER
							</button>
						</>
					)}
				</div>
			</section>

			<Footer />
		</div>
	);
};

export default InsuranceQuote;
