import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const RentalCategoryThunk = createAsyncThunk("RentalCategory/RentalCategoryThunk", async (_, { rejectWithValue }) => {
	try {
		const response = await axios.get(ApiEndpoints.RentalCategory);
		return response.data;
	} catch (error) {
		console.log("error", error);
		return rejectWithValue(error.response.data);
	}
});

const RentalCategorySlice = createSlice({
	name: "RentalCategory",
	initialState: {
		RentalCategoryData: [],
		loading: false,
		success: false,
		error: false,
		errorMessage: ""
	},
	reducers: {
		ResetRentalCategory: (state) => {
			state.RentalCategoryData = [];
			state.error = false;
			state.success = false;
			state.loading = false;
		}
	},
	extraReducers: {
		[RentalCategoryThunk.pending]: (state, action) => {
			state.loading = true;
			state.error = false;
			state.success = false;
		},
		[RentalCategoryThunk.fulfilled]: (state, action) => {
			state.RentalCategoryData = action.payload;
			state.loading = false;
			state.success = true;
		},
		[RentalCategoryThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload.message;
		}
	}
});

export default RentalCategorySlice.reducer;

export const { ResetRentalCategory } = RentalCategorySlice.actions;

export { RentalCategoryThunk };
