import React from "react";
import { Link } from "react-router-dom";

const QuoteSection = () => {
	return (
		<section className="section-5">
			<div className="container-fluid no-padding">
				<div className="row no-margin">
					<div className="col-lg-4 no-padding">
						<div className="layout quotation-box">
							<div className="layout-1 overlay quotation-bg-size"></div>
							<div className="quotation-index">
								<h1 className="heading color-white">
									<span className="heading-span">GET AN</span>
									<br />
									INSURANCE QUOTE
								</h1>
								<div className="align-all-center">
									<p className="description color-white">
										Request Link quotation to insure your commercial vehicles and equipment.
									</p>
								</div>
							</div>
							<div className="quotation-index">
								<Link
									to="/InsuranceQuote"
									className="finance-button bg-yellow900 color-black900 border-yellow900"
								>
									GET A QUOTE
								</Link>
							</div>
						</div>
					</div>
					<div className="col-lg-4 no-padding">
						<div className="layout-yellow quotation-box">
							<div className="layout-2 overlay quotation-bg-size"></div>
							<div className="quotation-index">
								<h1 className="heading color-black900">
									<span className="heading-span">GET A</span>
									<br />
									Transport QUOTE
								</h1>
								<div className="align-all-center">
									<p className="description color-black900">
										At Yellowish we have Link truckload of services, including transport solutions.
									</p>
								</div>
							</div>
							<div className="quotation-index">
								<Link
									to="/TransportQuote"
									className="finance-button1 bg-white color-black900 border-white"
								>
									GET A QUOTE
								</Link>
							</div>
						</div>
					</div>
					<div className="col-lg-4 no-padding">
						<div className="layout-light-black quotation-box">
							<div className="layout-3 overlay quotation-bg-size"></div>
							<div className="quotation-index">
								<h1 className="heading color-white">
									<span className="heading-span">GET A</span>
									<br />
									Finance QUOTE
								</h1>
								<div className="align-all-center">
									<p className="description color-white">
										Finance solutions for commercial vehicles and equipment.
									</p>
								</div>
							</div>
							<div className="quotation-index">
								<Link
									to="/FinanceQuote"
									className="finance-button bg-yellow900 color-black900 border-yellow900"
								>
									GET A QUOTE
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default QuoteSection;
