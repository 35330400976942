import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const LogoutThunk = createAsyncThunk("Logout/LogoutThunk", async (_, { rejectWithValue }) => {
	try {
		const response = await axios.post(
			ApiEndpoints.Logout,
			{
				refresh_token: localStorage.getItem("refresh_token")
			},
			{ headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` } }
		);
		localStorage.removeItem("api_token");
		localStorage.removeItem("refresh_token");
		return response.data;
	} catch (error) {
		console.log("error", error);
		return rejectWithValue(error.response.data);
	}
});

const LogoutSlice = createSlice({
	name: "Logout",
	initialState: {
		LogoutData: {},
		loading: false,
		success: false,
		error: false,
		errorMessage: ""
	},
	reducers: {
		ResetLogout: (state) => {
			state.LogoutData = {};
			state.error = false;
			state.success = false;
			state.loading = false;
		}
	},
	extraReducers: {
		[LogoutThunk.pending]: (state, action) => {
			state.loading = true;
			state.error = false;
			state.success = false;
		},
		[LogoutThunk.fulfilled]: (state, action) => {
			state.LogoutData = action.payload;
			state.loading = false;
			state.success = true;
		},
		[LogoutThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload;
		}
	}
});

export default LogoutSlice.reducer;

export const { ResetLogout } = LogoutSlice.actions;

export { LogoutThunk };
