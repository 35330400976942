import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetRentalProductDetailThunk = createAsyncThunk(
	"RentalProductDetail/GetRentalProductDetailThunk",
	async ({ product_id }, { rejectWithValue }) => {
		try {
			const response = await axios.get(`${ApiEndpoints.GetRentalProductDetail}?product_id=${product_id}`);
			return response.data;
		} catch (error) {
			console.log("error", error);
			return rejectWithValue(error.response.data);
		}
	}
);

const GetRentalProductSlice = createSlice({
	name: "RentalProductDetail",
	initialState: {
		RentalProductDetailData: {},
		loading: false,
		success: false,
		error: false,
		errorMessage: ""
	},
	reducers: {
		ResetRentalProductDetailData: (state) => {
			state.RentalProductDetailData = {};
			state.error = false;
			state.success = false;
			state.loading = false;
		}
	},
	extraReducers: {
		[GetRentalProductDetailThunk.pending]: (state, action) => {
			state.loading = true;
			state.error = false;
			state.success = false;
		},
		[GetRentalProductDetailThunk.fulfilled]: (state, action) => {
			state.RentalProductDetailData = action.payload;
			state.loading = false;
			state.success = true;
		},
		[GetRentalProductDetailThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload;
		}
	}
});

export default GetRentalProductSlice.reducer;

export const { ResetRentalProductDetailData } = GetRentalProductSlice.actions;

export { GetRentalProductDetailThunk };
