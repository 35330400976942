import React from "react";
import { Link } from "react-router-dom";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import PolicyHeader from "./PolicyHeader";

const PrivacyPolicy = () => {
	return (
		<div>
			<Navbar />
			<PolicyHeader heading="privacy policy" />
			<section className="container ">
				<div className="m-3 ">
					<div class="elementor-widget-text-editor">
						<ol>
							<li>
								<strong> INTRODUCTION</strong>
							</li>
						</ol>
						<p>
							Yellowish Group Pty Ltd (Registration Number 2017/131639/07), trading as Yellowish.io, is
							committed to and respects your privacy. This policy describes how we collect, use, disclose,
							store, and protect your personal information. We may amend this policy by posting the
							amended version here.
						</p>
						<ol start="2">
							<li>
								<strong> APPLICATION</strong>
							</li>
						</ol>
						<p>
							This policy applies to you when you visit and use the Yellowish.io website, application,
							service, instant messaging services, administrative tools, or order our goods or services as
							a customer, either as a natural person or a business (collectively “Services”), regardless
							of how you access or use them.
						</p>
						<p>
							If you use our website or Services, you are regarded as a customer and thus agree to any
							subsequent messages from our website or Services required to function and provide you with
							the services and functionality it renders. As a customer, you naturally opt-in for these
							messages on an opt-out basis.
						</p>
						<p>
							You are contracting with Yellowish (the data controller) and its service providers (the data
							processors – available on request), who are responsible for the collection, use, disclosure,
							retention, and protection of your personal information by the Protection of Personal
							Information Act 4 of 2013 (POPIA), as well as any applicable acts.
						</p>
						<ol start="3">
							<li>
								<strong> PERSONAL INFORMATION</strong>
							</li>
						</ol>
						<p>
							<strong>3.1 SCOPE</strong>
						</p>
						<p>
							Your personal information relates to an identified or identifiable natural person or
							business that we collect through our website and Services. An identifiable natural person or
							company can be identified, directly or indirectly, by reference to an identifier such as:
						</p>
						<ul>
							<li>a first name and last name</li>
							<li>identification number</li>
							<li>email address</li>
							<li>contact number</li>
							<li>device ID</li>
							<li>race</li>
							<li>age</li>
							<li>personal information of a competent person for those under 18 years of age</li>
							<li>sex</li>
							<li>unique identifier</li>
							<li>device type</li>
							<li>unique device token</li>
							<li>location data</li>
							<li>an online identifier</li>
							<li>company name</li>
							<li>company registration number</li>
							<li>computer</li>
							<li>
								connection information such as statistics on your page views, traffic to and from the
								website, referral URL, ad data, your IP address, your browsing history, web log
								information, the links you click, and other actions you take while using our Services
							</li>
							<li>
								or to one or more factors specific to that natural person or business’s physical,
								physiological, genetic, mental, economic, cultural, or social identity.
							</li>
						</ul>
						<p>
							<strong>3.2 YOUR OBLIGATIONS</strong>
						</p>
						<p>
							You may only send us your personal information or someone else’s personal information if you
							have their permission to do so.
						</p>
						<p>
							<strong>3.3</strong> <strong>NECESSARY INFORMATION</strong>
						</p>
						<p>
							To enter into our&nbsp;
							<a href="https://staging.yellowish.io/terms-of-service/">Terms of service</a>, some of your
							personal information is necessary. In contrast, other personal information is voluntary, but
							all may be necessary to use our Services, such as posting an ad, responding to an ad, and
							registering.
						</p>
						<ol start="4">
							<li>
								<strong> ACCEPTANCE AND CHANGES</strong>
							</li>
						</ol>
						<p>
							<strong>4.1. ACCEPTANCE</strong>
						</p>
						<p>
							You may not use our website or Services if you do not accept our&nbsp;
							<a href="https://staging.yellowish.io/terms-of-service/">Terms of service</a> or Privacy
							Policy.
						</p>
						<p>
							<strong>4.2. CHANGES</strong>
						</p>
						<p>
							We may change this policy at any time and will notify you of the changes on our website,
							through our Services, or by email. The revised policy applies to you if you continue using
							our website or our Services following the notification.
						</p>
						<ol start="5">
							<li>
								<strong> COLLECTION</strong>
							</li>
						</ol>
						<p>
							<strong>5.1. AUTOMATICALLY</strong>
						</p>
						<p>
							We automatically collect your Internet usage information when you visit our website,
							including your IP address, browser details, and usage information, which we may use to
							display our website or Services correctly, track your activities, or for other necessary
							purposes.
						</p>
						<p>
							<strong>5.2. ON REGISTRATION OR SUBMISSION</strong>
						</p>
						<p>
							We may ask you to provide us with certain identifying information when you register or
							otherwise submit information through our website or use our services for the first time.
						</p>
						<p>
							<strong>5.3. SUBMITTED INFORMATION ON A WEB FORM</strong>
						</p>
						<p>
							You will need to provide information while completing and submitting any form on our website
							or Services.
						</p>
						<p>
							<strong>5.4. RESPOND TO MARKETING ON EMAIL, SMS, AND SOCIAL MEDIA PLATFORMS</strong>
						</p>
						<p>
							We may collect and process your data when you interact with marketing activities like
							responding to an email, filling in a lead form on Facebook, or responding to an SMS.
						</p>
						<p>
							<strong>5.5. FEED/ IMPORTS ADVERTS TO OUR WEBSITE</strong>
						</p>
						<p>
							We may collect and process certain information from feeds, APIs, or other imports to enable
							a more automated way of advertising and managing adverts to the advertiser’s benefit.
						</p>
						<p>
							<strong>5.6. CALL A CALL TRACKING NUMBER</strong>
						</p>
						<p>
							We may collect, process, and record calls made to call tracking numbers displayed on our
							website or through our Services for quality, training, marketing, and security purposes.
						</p>
						<p>
							<strong>5.7 RESPOND TO AN ADVERT VIA WHATSAPP</strong>
						</p>
						<p>
							We will collect and process your mobile number associated with WhatsApp when you respond to
							an advert with WhatsApp enabled as a means of contact. We will also collect your contact
							number when you save our WhatsApp number on your phone and send us a message through this
							channel.
						</p>
						<p>
							<strong>5.8. UPDATE OR ADD INFORMATION TO YOUR ACCOUNT</strong>
						</p>
						<p>
							We will collect and process the newly submitted information related to your profile and
							account to keep our records up to date.
						</p>
						<p>
							<strong>5.9. COOKIES, WEB BEACONS, UNIQUE IDENTIFIERS, AND SIMILAR TECHNOLOGIES</strong>
						</p>
						<p>
							We may collect and process information about the pages you view, the links you click, and
							other actions you take while using our Website or Services. Manage your cookie preferences
							here:
						</p>
						<a
							role="button"
							tabindex="0"
							class="medium cli-plugin-button cli-plugin-main-button cli_settings_button"
							// style="color: rgb(51, 51, 51); background-color: rgb(222, 223, 224);"
						>
							Cookie Settings
						</a>
						<p>
							<strong>5.10. PARTICIPATE IN COMMUNITY DISCUSSIONS, CHATS, AND SURVEYS </strong>
						</p>
						<p>
							We may collect information when you discuss, chat or complete surveys about or through our
							website and services.
						</p>
						<p>
							<strong>5.11 DISPUTE RESOLUTION OR WHEN YOU OTHERWISE CORRESPOND WITH </strong>
						</p>
						<p>
							We may collect information when you contact us on any of our websites or through our
							Services and from any form or source of device or application.
						</p>
						<p>
							<strong>5.12. USING AN ORDER</strong>
						</p>
						<p>
							We ask you to provide us with personal information when you order free or premium usage of
							our website or Services.
						</p>
						<p>
							<strong>5.13. PROVIDED VOLUNTARILY</strong>
						</p>
						<p>We may ask you to provide us with certain optional information voluntarily.</p>
						<p>
							<strong>5.14. CONSENT TO COLLECTION</strong>
						</p>
						<p>
							We will get your support to collect your personal information by the POPI Act and other
							applicable laws when you provide us with it.
						</p>
						<p>
							<strong>5.15. PURPOSE FOR COLLECTION</strong>
						</p>
						<p>
							We may process information that you provide to us to provide you with valuable and relevant
							services and marketing and for the purposes that you indicated. Processing includes
							gathering your personal information, disclosing it, and combining it with other personal
							information.
						</p>
						<ol start="6">
							<li>
								<strong> USE</strong>
							</li>
						</ol>
						<p>
							<strong>6.1. SCOPE</strong>
						</p>
						<p>
							We respect your privacy and have implemented the POPI Act to the best of our understanding
							and knowledge. We will never share your personal and contact information with anyone without
							your consent or if ordered by law. Your personal information is used to provide and improve
							our Website and Services, provide you with a personalized experience, provide you customer
							service and help, provide you with customized advertising and marketing, contact you about
							your account and our Services, and to detect, prevent, mitigate, and investigate fraudulent
							or illegal activities.
						</p>
						<p>
							<strong>6.2. PROCESSING</strong>
						</p>
						<p>
							We may process your personal information to fulfill our obligations to you and our
							customers. Your information will be used to personalize recommendations and information we
							send you regarding news, events, promotions, special offers, marketing, and third-party
							content. We also process the personal information we collect from you for different business
							purposes that include improving our Website and Services, compiling statistics and reports,
							and enforcing our&nbsp;
							<a href="https://staging.yellowish.io/terms-of-service/">Terms of service</a>, this Privacy
							Policy, and other policies or any agreement or contract we have you.
						</p>
						<p>
							<strong>6.3. COOKIES</strong>
						</p>
						<p>
							We may place small text files on your device when you visit our website or use the Services
							that allow us to provide you with a personalized experience by associating your personal
							information with your device. They let us remember your preferences, enable third parties to
							provide services to you, and otherwise serve valuable purposes for you. Your internet
							browser generally accepts them automatically, but you can often change this setting or
							delete them manually. However, we will not be able to provide you with access to certain
							aspects of our Website and Services where cookies are necessary if you do so. We have no
							access to or control over any cookies that our business partners use on our website, and
							they have the privacy policies that govern them. Manage your cookie preferences here:
						</p>
						<Link
							to=""
							role="button"
							tabindex="0"
							class="medium cli-plugin-button cli-plugin-main-button cli_settings_button"
							style={{ color: "rgb(51, 51, 51)", backgroundColor: "rgb(222, 223, 224)" }}
						>
							Cookie Settings
						</Link>
						<p>
							<strong>6.4. MESSAGES</strong>
						</p>
						<p>
							We aim only to contact you or send you messages and marketing when we determine them to
							interest you. We may use your identifying information to send you administrative information
							and announcements about our Website, Services, updates, and your interests. From time to
							time, we may also send you promotional messages or marketing from third parties. Still, you
							may choose to opt out of them, or we will first send you one message asking you to opt in
							and will not continue to send you promotional messages or marketing unless you have done so.
							We don’t sell or share your personal information with third-party services for marketing
							purposes. We may contact you via email, telephone, SMS/text messages, postal mail, social
							media platforms, instant messaging systems, and push notifications (collectively “Marketing
							Channels”).
						</p>
						<p>
							<strong>6.5. TARGETED CONTENT</strong>
						</p>
						<p>
							We may use your personal information to provide you with targeted content on our website and
							through our Services and Marketing Channels in a completely automated process. We aim to
							deliver targeted marketing, service updates, and promotional offers about third-party
							services based on your communication subscription/preferences.
						</p>
						<p>
							<strong>6.6. MANAGE SUBSCRIPTION / PREFERENCES</strong>
						</p>
						<p>
							You have the right to withdraw your consent at any time. Visit our&nbsp;POPIA information
							page for a quick-and-easy guide on how to request this.
						</p>
						<ol start="7">
							<li>
								<strong> DISCLOSURE</strong>
							</li>
						</ol>
						<p>
							<strong>7.1. SHARING</strong>
						</p>
						<p>
							We do not sell, rent, or otherwise disclose your personal information to third parties for
							their marketing and advertising purposes without your consent. We may share your personal
							information with third parties to fulfill our obligations to you, including:
						</p>
						<ul>
							<li>other companies or divisions within our group.</li>
							<li>our contractors who help provide part of our goods or services; or</li>
							<li>third parties as required by applicable law.</li>
						</ul>
						<p>
							<strong>7.2. INTEGRITY</strong>
						</p>
						<p>
							We will require anyone with whom we share your personal information to honour this policy
							whenever possible in terms of applicable law.
						</p>
						<p>
							<strong>7.3. MANDATORY DISCLOSURE</strong>
						</p>
						<p>
							We may disclose personal information to third parties for legal reasons, such as to
							regulators as required by law or governmental audit, law enforcement as directed by a
							subpoena or court order, or third parties as required by applicable law.
						</p>
						<p>
							<strong>7.4. MARKETING PURPOSES</strong>
						</p>
						<p>
							We may disclose aggregate statistical information derived from your and other people’s
							personal information to our advertisers or business partners.
						</p>
						<p>
							<strong>7.5. PERSONNEL</strong>
						</p>
						<p>
							We may need to disclose personal information to our personnel to do their jobs but will not
							do so unnecessarily.
						</p>
						<p>
							<strong>7.6. CHANGE OF OWNERSHIP</strong>
						</p>
						<p>
							We may assign our rights to the personal information we process to our new owners if our
							ownership changes.
						</p>
						<p>
							<strong>7.6. DISCLOSURE</strong>
						</p>
						<p>
							We attempt to minimize the amount of personal information we disclose to what is directly
							relevant and necessary to accomplish the specified purpose.
						</p>
						<ol start="8">
							<li>
								<strong> STORAGE</strong>
							</li>
						</ol>
						<p>
							<strong>8.1. ACCURACY</strong>
						</p>
						<p>
							We will do our best to keep the personal information accurate, complete, and up-to-date. It
							is also your responsibility to keep us informed of any changes to your information,
							including contact information or advertised items or positions.
						</p>
						<p>
							<strong>8.2. PARTICIPATION</strong>
						</p>
						<p>
							We may ask you to update your personal information from time to time or give you a way to
							review it, but we will take steps to verify your identity to prevent unauthorized access.
						</p>
						<p>
							<strong>8.3. RETENTION</strong>
						</p>
						<p>
							We will only keep your personal information for as long as is necessary to fulfill our
							obligations to you unless you have permitted us to keep it longer or we are otherwise
							legally allowed to do so. We retain your personal information for as long as necessary to
							provide the Services you have requested or for other essential purposes such as complying
							with our legal obligations, resolving disputes, and enforcing our policies. Our specific
							retention times can vary significantly based on the context of our services and our legal
							obligations. After it is no longer necessary for us to retain your personal information, we
							will dispose of it securely.
						</p>
						<p>
							<strong>8.4. TRANSFER</strong>
						</p>
						<p>
							We may transfer your personal information outside of the country in which it was collected
							to a foreign country. You consent to us processing your personal information in a foreign
							country with less stringent data protection laws than the country where it was collected.
						</p>
						<ol start="9">
							<li>
								<strong> GENERAL</strong>
							</li>
						</ol>
						<p>
							<strong>9.1. UPDATING OR REMOVING</strong>
						</p>
						<p>
							You may update or remove your personal information through our website or application or
							contact us. Visit our&nbsp;POPIA information page&nbsp;for a quick-and-easy guide on how to
							request this.
						</p>
						<p>
							<strong>9.2. LIMITATION</strong>
						</p>
						<p>We are not responsible for anyone else’s privacy policies or practices.</p>
						<p>
							<strong>9.3. ENQUIRIES</strong>
						</p>
						<p>
							Please contact us&nbsp;if you have any questions about this privacy policy or how we handle
							your personal information.
						</p>
						<ol start="10">
							<li>
								<strong> COMPLAINTS PROCEDURE</strong>
							</li>
						</ol>
						<p>
							<strong>10.1. PROCEDURE</strong>
						</p>
						<p>
							We have set out some guidelines to ensure that we can effectively respond to complaints. It
							explains what we would require you to provide feedback and take appropriate
							action.&nbsp;View our complaints procedure.
						</p>
						<p>
							<strong>10.2. CONTACT US</strong>
						</p>
						<p>
							If you feel that any law related to your personal information has been incorrectly
							interpreted and implemented, please <a href="#remove-personal-info">contact us</a>{" "}
							immediately.
						</p>
						<p>
							<strong>10.3. RIGHTS NOT COMPROMISED</strong>
						</p>
						<p>Your right to file a complaint with the Information Regulator remains unaffected.</p>
						<p>
							<em>UPDATED MAY 2022</em>
						</p>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default PrivacyPolicy;
