import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiEndpoints } from "../../utils/ApiEndpoints";
import axios from "axios";

const TransportQuoteThunk = createAsyncThunk(
	"TransportQuote/TransportQuoteThunk",
	async (
		{ description, machine_running_status, delivery_point, additional_detail, name, email, pickup_point },
		{ rejectWithValue }
	) => {
		try {
			const response = await axios.post(
				ApiEndpoints.TransportQuote,
				{
					name,
					email,
					description,
					machine_running_status,
					delivery_point,
					additional_detail,
					pickup_point
				},
				{
					headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` }
				}
			);
			return response.data;
		} catch (error) {
			console.log("error", error);
			return rejectWithValue(error.response.data);
		}
	}
);

const TransportQuoteSlice = createSlice({
	name: "TransportQuote",
	initialState: {
		transportQuoteData: [],
		success: false,
		error: false,
		loading: false,
		errorMessage: ""
	},
	reducers: {
		ResetTransportQuote: (state) => {
			state.transportQuoteData = [];
			state.success = false;
			state.error = false;
			state.loading = false;
			state.errorMessage = "";
		}
	},
	extraReducers: {
		[TransportQuoteThunk.pending]: (state, action) => {
			state.loading = true;
			state.success = false;
			state.error = false;
		},
		[TransportQuoteThunk.fulfilled]: (state, action) => {
			state.loading = false;
			state.success = true;
			state.transportQuoteData = action.payload;
		},
		[TransportQuoteThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload.message;
		}
	}
});

export default TransportQuoteSlice.reducer;
export const { ResetTransportQuote } = TransportQuoteSlice.actions;
export { TransportQuoteThunk };
