import React, { useContext, useEffect, useState } from "react";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import ImageGallery from "react-image-gallery";
import Specifications from "./Specifications";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { GetListingDetailThunk } from "../../redux/Listing/GetListingDetailSlice";
import { UserContext } from "../../Context/userContext";
import { Field, Form, Formik } from "formik";
import { ListingEnquiryValidationSchema } from "../../utils/FormValidationSchema";
import { ListingEnquiryThunk, ResetListingEnquiryData } from "../../redux/Listing/ListingEnquirySlice";
import toast from "react-hot-toast";

const ListingDetail = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [images, setImages] = useState([]);
	const [openTab, setOpenTab] = useState("Details");

	const { userInfo, isAuth } = useContext(UserContext);

	const {
		loading: GetListingLoading,
		success: GetListingSuccess,
		error: GetListingError,
		listingDetailData
	} = useSelector((state) => state.listing.GetListingDetail);
	const {
		loading: ListingEnquiryLoading,
		success: ListingEnquirySuccess,
		error: ListingEnquiryError,
		errorMessage: ListingEnquiryErrorMessage
	} = useSelector((state) => state.listing.ListingEnquiry);

	const enquiryInitialValues = {
		name: userInfo?.username ? userInfo?.username : "",
		email: userInfo?.email ? userInfo?.email : "",
		phone: "",
		description: ""
	};

	useEffect(() => {
		dispatch(GetListingDetailThunk({ product_id: id }));
	}, [dispatch, id]);

	useEffect(() => {
		if (Object.keys(listingDetailData).length !== 0) {
			const listingImages = listingDetailData?.data?.ProductImages.map((productImage) => {
				return { original: productImage?.image, thumbnail: productImage?.image };
			});
			setImages(listingImages);
		}
	}, [GetListingSuccess, listingDetailData]);

	const handleLogin = () => {
		sessionStorage.setItem('previousPage', window.location.href);
		navigate("/my-account");
	}

	const handleTab = (evt, tab) => {
		var i, tabcontent, tablinks;
		tabcontent = document.getElementsByClassName("tabcontent");
		for (i = 0; i < tabcontent.length; i++) {
			tabcontent[i].style.display = "none";
		}
		tablinks = document.getElementsByClassName("tablinks");
		for (i = 0; i < tablinks.length; i++) {
			tablinks[i].className = tablinks[i].className.replace(" active-class-2", "");
		}
		// document.getElementById(tab).style.display = "block";
		evt.currentTarget.className += " active-class-2";
		// document.getElementById("defaultOpen").click();
		setOpenTab(tab);
	};

	const handleSubmit = ({ name, email, phone, description }) => {
		dispatch(ListingEnquiryThunk({ name, email, phone, description, product_id: id }));
	};

	if (ListingEnquirySuccess) {
		toast.success("Enquiry email has been sent to seller.");
		dispatch(ResetListingEnquiryData());
	}

	if (ListingEnquiryError) {
		toast.error(ListingEnquiryErrorMessage);
		dispatch(ResetListingEnquiryData());
	}

	return (
		<div>
			<Navbar />
			{Object.keys(listingDetailData).length !== 0 && (
				<section className="position-relative">
					<div className="bg-overlay overlay"></div>
					<div className="listing-detail-sec1">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 no-padding">
									<h1 className="listing-detail-heading color-yellow900">
										{listingDetailData?.data?.year} {listingDetailData?.data?.make}{" "}
										{listingDetailData?.data?.model} {listingDetailData?.data?.machine_type}{" "}
									</h1>
								</div>
								<div className="col-lg-12 no-padding">
									<div className="bottom-line bg-yellow900"></div>
									<p className="listing-detail-paragraph color-white">
										<span className="color-yellow900">Yellowish.io</span> is the quick and easy
										online marketplace for finding, buying, and selling used farm equipment,
										construction equipment, trucks, and trailers.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}

			<div className="container mt-5">
				{GetListingLoading ? (
					<div className="d-flex justify-content-center align-items-center" style={{ height: "90vh" }}>
						<Spinner />
					</div>
				) : GetListingError ? (
					<p className="m-5">No listing detail found</p>
				) : (
					<div className="auto-listings-single row">
						{Object.keys(listingDetailData).length !== 0 && (
							<>
								<div className="row">
									<div className="col-lg-8">
										{<ImageGallery showPlayButton={false} autoPlay={false} items={images} />}
									</div>
									<div className="col-lg-4">
										<div className="sidebar ">
											{isAuth ? (
												<div className="contact-form">
													<h3 className="mb-5">Quick Contact</h3>
													<div id="auto-listings-contact">
														<Formik
															initialValues={enquiryInitialValues}
															validationSchema={ListingEnquiryValidationSchema}
															onSubmit={handleSubmit}
															enableReinitialize={true}
														>
															{({ errors, touched }) => (
																<Form className="rwmb-form">
																	<div className="rwmb-meta-box">
																		<div className="rwmb-field rwmb-text-wrapper">
																			<div className="rwmb-input">
																				<Field placeholder="Name" type="text" name="name" className="rwmb-text" />
																			</div>
																		</div>
																		<p className="col-lg-12 input-info-text error-text-color mb-2 pl-0">
																			{errors.name && touched.name
																				? errors.name
																				: ""}
																		</p>
																		<div className="rwmb-field ">
																			<div className="rwmb-input">
																				<Field placeholder="Email" name="email" type="text" className="rwmb-text" />
																			</div>
																		</div>
																		<p className="col-lg-12 input-info-text error-text-color mb-2 pl-0">
																			{errors.email && touched.email
																				? errors.email
																				: ""}
																		</p>
																		<div className="rwmb-field rwmb-text-wrapper">
																			<div className="rwmb-input">
																				<Field placeholder="Phone" type="text" name="phone" className="rwmb-text" />
																			</div>
																		</div>
																		<p className="col-lg-12 input-info-text error-text-color mb-2 pl-0">
																			{errors.phone && touched.phone
																				? errors.phone
																				: ""}
																		</p>
																		<div className="rwmb-field rwmb-textarea-wrapper">
																			<div className="rwmb-input">
																				<Field as="textarea" placeholder="Enter your enquiry (up to 500 characters)" className="rwmb-text" name="description" />
																			</div>
																		</div>
																		<p className="col-lg-12 input-info-text error-text-color mb-4 pl-0">
																			{errors.description && touched.description
																				? errors.description
																				: ""}
																		</p>
																	</div>
																	<div className="rwmb-field rwmb-button-wrapper rwmb-form-submit">
																		<button className="form-bt2 color-black900" data-edit="" name="rwmb_submit" value="1" disabled={ListingEnquiryLoading}>
																			Send Enquiry
																			<span className={ListingEnquiryLoading ? "spinner-border spinner-border-sm" : ""} style={{ marginLeft: "5px" }} />
																		</button>
																	</div>
																</Form>
															)}
														</Formik>
													</div>
												</div>
											) : (
												<div className="m-1">
													<button onClick={handleLogin} className="form-bt2 color-black900 w-100">
														LOGIN/REGISTER TO ENQUIRE
													</button>
												</div>
											)}
										</div>
										{isAuth && (
											<div className="mt-1">
												{listingDetailData?.data?.seller_logo &&
													<img src={listingDetailData?.data?.seller_logo && listingDetailData?.data?.seller_logo} wid6th="140" heigth="140" alt="dealer logo" />
												}
											</div>
										)}
									</div>
								</div>

								<div className="row my-5">
									<h2 style={{ fontWeight: "600" }}>
										{listingDetailData?.data?.year} {listingDetailData?.data?.make}{" "}
										{listingDetailData?.data?.model} {listingDetailData?.data?.machine_type}{" "}
									</h2>
									<p style={{ color: "#777777" }}>
										{listingDetailData?.data?.year} {listingDetailData?.data?.make}{" "}
										{listingDetailData?.data?.model} {listingDetailData?.data?.machine_type}{" "}
									</p>
								</div>
								<div className="row">
									<div className="col-lg-8">
										<div className="auto-listings-tabs al-tabs-wrapper">
											<div className="tabs-main position-relative">
												<div className="simple-border-line">
													<button
														class="form-bt1 tablinks positive-relative active-class-2"
														onClick={(e) => handleTab(e, "Details")}
													>
														<p class="form-action">Details</p>
													</button>
												</div>
											</div>
										</div>
										{openTab === "Details" && (
											<Specifications data={listingDetailData?.data} setOpenTab={handleTab} />
										)}
									</div>
								</div>
							</>
						)}
					</div>
				)}
			</div>
			<Footer />
		</div>
	);
};

export default ListingDetail;
