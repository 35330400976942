import { combineReducers } from "@reduxjs/toolkit";
import DeleteListingSlice from "./DeleteListingSlice";
import GetListingDetailSlice from "./GetListingDetailSlice";
import GetListingSlice from "./GetListingSlice";
import ListingEnquirySlice from "./ListingEnquirySlice";
import SubmitListingSlice from "./SubmitListingSlice";

export const ListingReducers = combineReducers({
	AddListing: SubmitListingSlice,
	GetListing: GetListingSlice,
	GetListingDetail: GetListingDetailSlice,
	ListingEnquiry: ListingEnquirySlice,
	DeleteListing: DeleteListingSlice
});
