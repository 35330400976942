import * as Yup from "yup";

export const ContactUsValidationSchema = Yup.object().shape({
	fullName: Yup.string().required(),
	email: Yup.string().email().required(),
	phoneNumber: Yup.string().required(),
	message: Yup.string().required()
});

export const AdvertisementValidationSchema = Yup.object().shape({
	fullName: Yup.string().required(),
	email: Yup.string().email().required(),
	phoneNumber: Yup.string().required(),
	message: Yup.string().notRequired()
});

export const LoginValidationSchema = Yup.object().shape({
	email: Yup.string().required(),
	password: Yup.string().required()
});

export const RegisterValidationSchema = Yup.object().shape({
	userName: Yup.string().required(),
	email: Yup.string().email().required(),
	phoneNumber: Yup.string().required(),
	password: Yup.string().required(),
	confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Password must match")
});

export const ForgetValidationSchema = Yup.object().shape({
	email: Yup.string().email().required()
});

export const InsuranceQuoteValidationSchema = Yup.object().shape({
	name: Yup.string().required(),
	phone: Yup.string().required(),
	email: Yup.string().email().required(),
	description: Yup.string().notRequired()
});

export const FinanceQuoteValidationSchema = Yup.object().shape({
	name: Yup.string().required("Name is a required field"),
	email: Yup.string().email().required("Email is a required field"),
	manufacturer: Yup.string().required(),
	model: Yup.string().notRequired(),
	year: Yup.string().required(),
	value: Yup.string().required()
});

export const TransportQuoteValidationSchema = Yup.object().shape({
	name: Yup.string().required("Name is a required field"),
	email: Yup.string().email().required("Email is a required field"),
	deliveryPoint: Yup.string().required("Delivery Point is a required field"),
	pickupPoint: Yup.string().required("Pickup Point is a required field"),
	description: Yup.string().required("Description is a required field"),
	isMachineRunning: Yup.string().required("required field"),
	additionalDetail: Yup.string().optional()
});

export const AddListingValidationSchema = Yup.object().shape({
	title: Yup.string().optional(),
	price: Yup.string().optional(),
	year: Yup.string().optional(),
	make: Yup.string().required("Make is a required field"),
	model: Yup.string().required("Model is a required field"),

	machine_type: Yup.string().required("Machine Type is a required field."),
	hours: Yup.string().optional(),
	mileage: Yup.string().optional(),
	service_history: Yup.string().optional(),
	serial_number: Yup.string().optional(),
	fuel_type: Yup.string().optional(),
	engine_type: Yup.string().optional(),
	engine_max_power: Yup.string().optional(),
	engine_max_torque: Yup.string().optional(),
	listing_package: Yup.string().optional()
});

export const ListingEnquiryValidationSchema = Yup.object().shape({
	name: Yup.string().required("Name is a required field"),
	email: Yup.string().required("Email is a required field"),
	phone: Yup.string().optional(),
	description: Yup.string().optional()
});
