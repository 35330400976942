import React from 'react'
import { Modal, ModalBody, ModalHeader, } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { GetProfileThunk } from '../../../redux/GetProfileSlice';
import { DeleteListingThunk, ResetDeleteListingData } from '../../../redux/Listing/DeleteListingSlice';

const DeleteListingModal = ({ openModal, setOpenModal, product_id }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage } = useSelector((state) => state.listing.DeleteListing);
    const handleDeleteListing = () => {
        dispatch(DeleteListingThunk({ product_id }));
    }

    if (success) {
        toast.success('Listing has been deleted successfully.');
        dispatch(ResetDeleteListingData());
        dispatch(GetProfileThunk());
        setOpenModal();
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetDeleteListingData());
        dispatch(GetProfileThunk());
        setOpenModal();
    }

    return (
        <React.Fragment>
            <Modal size="md" centered show={openModal} onHide={() => setOpenModal()}>
                <ModalHeader className="border-0" closeButton >
                    <h4 className='m-2'>Delete Listing</h4>
                </ModalHeader>
                <ModalBody>
                    <div className="m-2">
                        <span>
                            Are you sure you want to delete listing?
                        </span>
                    </div>
                    <div className="m-2 mt-4 d-flex justify-content-between align-items-center">
                        <button
                            className="cont1-button bg-yellow900 color-black900 border-yellow900  ml-0 justify-content-start"
                            // style={{ justifyContent: "start" }}
                            type="submit"
                            onClick={handleDeleteListing}
                            disabled={loading}
                        >
                            {loading ? <Spinner animation="border" variant="dark" /> : "Delete Listing"}
                        </button>
                        <div className='mr-2 cursor-pointer' onClick={setOpenModal} style={{ fontSize: "20px", color: "#777780", fontFamily: "sans-serif" }}>
                            cancel
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default DeleteListingModal;