import React from "react";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import PolicyHeader from "./PolicyHeader";

const SecurityPolicy = () => {
	return (
		<div>
			<Navbar />
			<PolicyHeader heading="Security Policy" />

			<section className="container mb-5">
				<div className="m-3 mb-5">
					<div class="elementor-widget-text-editor">
						<ol>
							<li>
								<strong> INTRODUCTION</strong>
							</li>
						</ol>
						<p>
							Yellowish Group Pty Ltd, Registration Number 2017/ 161639/07 trading as Yellowish.io take
							security seriously but must strike a balance between increased levels of security and making
							transacting with us convenient for you. We have developed security strategies to protect
							both of us and our security policy is a plan of action for safeguarding our website and your
							personal information from harm, such as unauthorized access, destruction, or loss. It
							describes our responsibilities, our disclaimers, and your responsibilities, and has special
							provisions dealing with phishing and credit cards.
						</p>
						<ol start="2">
							<li>
								<strong> OUR RESPONSIBILITIES</strong>
							</li>
						</ol>
						<p>We will:</p>
						<ul>
							<li>
								host our website on a secure server that uses a firewall and other security measures to
								prevent unauthorized access.
							</li>
							<li>
								protect your information that we store in or pass through our systems using encryption
								or other appropriate information security measures.
							</li>
							<li>
								ensure that links from our systems to systems that third parties (such as our payment
								gateways) control are secure.
							</li>
							<li>backup up all data on our systems so that we can recover it in case of disaster.</li>
							<li>
								log all access to our systems to better identify and resolve unauthorized access issues;
								and
							</li>
							<li>
								use a payment gateway that is sufficiently secure (in terms of accepted technological
								standards at the time of the transaction and the type of the transaction) and takes
								reasonable steps to secure your payment information.
							</li>
						</ul>
						<ol start="3">
							<li>
								<strong> OUR DISCLAIMERS</strong>
							</li>
						</ol>
						<p>
							<strong>3.1 DISCLAIMER</strong>
						</p>
						<p>
							We will do our best to prevent our website and your information from being compromised and
							will help you resolve a security problem whenever we can. However, we are not responsible
							for compromises caused by:
						</p>
						<ul>
							<li>
								harmful code – entering our websites, such as viruses, bugs, Trojan horses, spyware, or
								adware;
							</li>
							<li>
								your fault – problems or loss caused by the information you provide to us (such as
								inaccurate personal information) or your computer is compromised (such as being accessed
								by an unauthorized person or otherwise hacked);
							</li>
							<li>factors beyond human control – such as fires, floods, or other natural disasters.</li>
						</ul>
						<p>
							<strong>3.2 THIRD PARTY SYSTEMS</strong>
						</p>
						<p>
							Third parties are responsible for the security of information collected by, stored on, or
							passing through their systems, even if we link to those systems.
						</p>
						<ol start="4">
							<li>
								<strong> YOUR RESPONSIBILITIES</strong>
							</li>
						</ol>
						<p>
							<strong>4.1 NO COMPROMISE</strong>
						</p>
						<p>You may not do (or let anyone else do) anything that might compromise our system.</p>
						<p>
							<strong>4.2. PROTECT YOUR CREDENTIALS</strong>
						</p>
						<p>
							If you created a username, password, or other credentials when you registered on or
							submitted information through our website for the first time, then you should take the
							following steps to secure those credentials:
						</p>
						<ul>
							<li>never share them with anyone.</li>
							<li>never send them via email; and</li>
							<li>make any passwords as strong as possible.</li>
						</ul>
						<p>
							<strong>4.3 RECOMMENDED STEPS</strong>
						</p>
						<p>You should take the following steps to secure your personal information:</p>
						<ul>
							<li>
								install security software on your device, including anti-virus, anti-spyware, and
								anti-spam software.
							</li>
							<li>regularly scan your device for viruses and other malicious software; and</li>
							<li>
								keep security software up to date to ensure you are always running the latest version
								with the latest definitions.
							</li>
						</ul>
						<p>
							<strong>4.4 ADDITIONAL STEPS</strong>
						</p>
						<p>You could take the following steps for your own security:</p>
						<ul>
							<li>
								check your Internet browser’s security settings for ways to make your browsing more
								secure;
							</li>
							<li>
								make sure that you have entered secure pages when filling in your personal information;
								and
							</li>
							<li>log out after you have transacted electronically.</li>
						</ul>
						<ol start="5">
							<li>
								<strong> PHISHING</strong>
							</li>
						</ol>
						<p>
							<strong>5.1. AWARENESS</strong>
						</p>
						<p>
							Be aware of ‘phishing’ attacks where criminals attempt to get your personal information by
							sending you an email, masquerading as an email from us, asking you to access your account or
							verify information through links in the email, or diverting you to a fake version of our
							website.
						</p>
						<p>
							<strong>5.2. LEGITIMATE URL</strong>
						</p>
						<p>
							You must only log into our website from a legitimate URL associated with us that you know
							and trust, such as one based on the name that we have communicated to you in writing. Please
							be wary of phishing websites that use illegitimate URLs designed to trick you into thinking
							they are our websites, such as by using common misspellings of our name, different domain
							suffixes, or other words associated with our business.
						</p>
						<p>
							<strong>5.3. CONFIRMATION THROUGH LINKS</strong>
						</p>
						<p>
							We will not generally ask you to confirm your credentials or other personal information by
							clicking on any links in an email other than in any email link we send you after
							registration or the first time you submit information through our website to verify your
							identity.
						</p>
						<p>
							<strong>5.4. REPORTING</strong>
						</p>
						<p>
							Please report any suspected phishing attacks to us immediately to prevent any harm to you or
							others.
						</p>
						<ol start="6">
							<li>
								<strong> CREDIT CARDS</strong>
							</li>
						</ol>
						<p>
							<strong>6.1. SAFE AND SECURE</strong>
						</p>
						<p>
							Transacting with us electronically (including transacting and using your credit card on our
							website) is safe and secure.
						</p>
						<p>
							<strong>6.2. PAYMENT PROCESSING</strong>
						</p>
						<p>
							Our payment gateway handles all credit card transactions on our behalf, uses the strictest
							form of encryption, and has its own security certificate and policy which you can view on
							its website.
						</p>
						<p>
							<strong>6.3. PAYMENT VERIFICATION</strong>
						</p>
						<p>
							A Certificate Authority (or CA) checks, verifies, and certifies our payment gateway’s
							company registration documents and domains to ensure that nobody can impersonate them to get
							your payment information.
						</p>
						<p>
							<strong>6.4. SECURE URL</strong>
						</p>
						<p>
							Once you begin the payment process, your browser will establish a secure Internet connection
							with our payment gateway. You can see this when the website URL changes from ‘HTTP’ to
							‘HTTPS’ and a small padlock symbol appears.
						</p>
						<ol start="7">
							<li>
								<strong> GENERAL</strong>
							</li>
						</ol>
						<p>
							<strong>7.1. CONTACT US</strong>
						</p>
						<p>
							Please report any suspicious or unauthorized activity relating to your use of our website to
							us directly, because it will help make our website as secure as we can.
						</p>
						<p>
							<strong>7.2. OUR RIGHT TO TAKE ACTION</strong>
						</p>
						<p>
							We may take whatever action we may deem necessary to preserve the security and reliable
							operation of our system.
						</p>
						<p>
							<em>UPDATED MAY 2022</em>
						</p>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default SecurityPolicy;
