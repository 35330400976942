import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiEndpoints } from "../../utils/ApiEndpoints";
import axios from "axios";

const FinanceQuoteThunk = createAsyncThunk(
	"FinanceQuote/FinanceQuoteThunk",
	async ({ manufacturer, model, year, value, name, email }, { rejectWithValue }) => {
		try {
			const response = await axios.post(
				ApiEndpoints.FinanceQuote,
				{
					name,
					email,
					manufacturer,
					model,
					year,
					value
				},
				{
					headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` }
				}
			);
			return response.data;
		} catch (error) {
			console.log("error", error);
			return rejectWithValue(error.response.data);
		}
	}
);

const FinanceQuoteSlice = createSlice({
	name: "FinanceQuote",
	initialState: {
		financeQuoteData: [],
		success: false,
		error: false,
		loading: false,
		errorMessage: ""
	},
	reducers: {
		ResetFinanceQuote: (state) => {
			state.financeQuoteData = [];
			state.success = false;
			state.error = false;
			state.loading = false;
			state.errorMessage = "";
		}
	},
	extraReducers: {
		[FinanceQuoteThunk.pending]: (state, action) => {
			state.loading = true;
			state.success = false;
			state.error = false;
		},
		[FinanceQuoteThunk.fulfilled]: (state, action) => {
			state.loading = false;
			state.success = true;
			state.financeQuoteData = action.payload;
		},
		[FinanceQuoteThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload;
		}
	}
});

export default FinanceQuoteSlice.reducer;
export const { ResetFinanceQuote } = FinanceQuoteSlice.actions;
export { FinanceQuoteThunk };
