import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../utils/ApiEndpoints";

const ContactUsThunk = createAsyncThunk(
	"ContactUs/ContactUsThunk",
	async ({ fullName, email, phoneNumber, message }, { rejectWithValue }) => {
		try {
			const response = await axios.post(ApiEndpoints.ContactUs, {
				name: fullName,
				email: email,
				phone: phoneNumber,
				description: message
			});
			return response.data.message;
		} catch (error) {
			console.log("error", error);
			return rejectWithValue(error.response.data);
		}
	}
);

const ContactUsSlice = createSlice({
	name: "ContactUs",
	initialState: {
		contactUsData: {},
		loading: false,
		success: false,
		error: false,
		errorMessage: ""
	},
	reducers: {
		ResetContactUs: (state) => {
			state.contactUsData = {};
			state.error = false;
			state.success = false;
			state.loading = false;
		}
	},
	extraReducers: {
		[ContactUsThunk.pending]: (state, action) => {
			state.loading = true;
			state.error = false;
			state.success = false;
		},
		[ContactUsThunk.fulfilled]: (state, action) => {
			state.contactUsData = action.payload;
			state.loading = false;
			state.success = true;
		},
		[ContactUsThunk.rejected]: (state, action) => {
			state.loading = false;
			state.error = true;
			state.errorMessage = action.payload.message;
		}
	}
});

export default ContactUsSlice.reducer;

export const { ResetContactUs } = ContactUsSlice.actions;

export { ContactUsThunk };
