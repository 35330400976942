export const ContactUsInitialValues = {
	fullName: "",
	email: "",
	phoneNumber: "",
	message: ""
};

export const AdvertisementInitialValues = {
	fullName: "",
	email: "",
	phoneNumber: "",
	message: ""
};

export const LoginInitialValues = {
	email: "",
	password: ""
};

export const RegisterInitialValues = {
	userName: "",
	email: "",
	phoneNumber: "",
	password: ""
};

export const ForgetInitialValues = {
	email: ""
};

export const AddListingInitialValues = {
	title: "",
	category: "",
	year: "",
	make: "",
	model: "",
	machine_type: "",
	hours: "",
	mileage: "",
	service_history: "",
	serial_number: "",
	fuel_type: "",
	engine_type: "",
	engine_max_power: "",
	engine_max_torque: "",
	city: "",
	postal_code: "",
	state: "",
	country: "",
	latitude: "",
	longitude: "",
	listing_package: ""
};
