import React from "react";

function ListingTable({
	handleListingDetail,
	displayListing,
	elements,
	listingCSS,
	imageCSS,
	summaryCSS,
	glanceCSS,
	titleCSS
}) {
	return (
		<div className="display-table">
			{displayListing?.map((listingDetail, index) => {
				return (
					<div className="listing-main listing-width" style={{ width: elements }} key={index}>
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="listing" style={{ display: listingCSS }}>
										<div className="listing-img position-relative" style={{ width: imageCSS }}>
											<div className="listing-container">
												<img
													className="listing-image"
													height="380"
													src={listingDetail?.ProductImages[0]?.image}
													alt="listing_image"
												/>
											</div>
											<div className="listing-new">
												<img className="star" src="/images/star.png" alt="star" />
												New Listing
											</div>
											<div className="listing-sale">
												<img
													className="listing-sale-speed"
													src="/images/speedometer-black.png"
													alt="star"
												/>
												For Sale
											</div>
										</div>
										<div
											className="summary-glance"
											style={{
												width: glanceCSS?.width,
												position: glanceCSS?.position,
												bottom: glanceCSS?.bottom,
												right: glanceCSS?.right,
												marginTop: glanceCSS?.marginTop
											}}
										>
											<div className="summary-border width-maxcontent">
												<img
													src="/images/speedometer.png"
													style={{
														width: "18px",
														height: "18px",
														marginRight: "10px"
													}}
													alt=""
												/>
												<span className="box-span">n/a</span>
											</div>
											<div className="summary-border width-100">
												<img
													src="/images/speedometer.png"
													style={{
														width: "18px",
														height: "18px",
														marginRight: "10px"
													}}
													alt=""
												/>
												<span className="box-span">n/a</span>
											</div>
										</div>
										<div
											className="summary"
											style={{
												width: summaryCSS?.width,
												marginLeft: summaryCSS?.marginLeft,
												padding: summaryCSS?.padding
											}}
										>
											<h3 className="summary-title" style={{ marginTop: titleCSS }}>
												<a href="#" className="anchor">
													{listingDetail.year} {listingDetail.make} {listingDetail.model}{" "}
													{listingDetail.machine_type}{" "}
												</a>
											</h3>
											<div className="contents pt-lg-5">
												<h3 className="contents-price">{listingDetail?.price}</h3>
											</div>
										</div>
										<div className="wrap">
											<button
												onClick={() => handleListingDetail(listingDetail?.product_id)}
												className="wrap-button"
											>
												More Details
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default ListingTable;
